

<template>
  <div class="box-page">
    <div class="box-input">
      <div class="box-input-left">
        <el-select clearable v-model="formInfo.Status" style="width: 145px;" @change="searchFn" placeholder="请选择审核状态">
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-input
          v-model="formInfo.name"
          style="width: 194px"
          placeholder="关键词"
        ></el-input>
      </div>

      <div class="box-input-btn">
        <el-button class="default" @click="searchFn">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="resetFn">重置</el-button>
      </div>
    </div>

    <div class="box-table">
      <el-table
        :data="tableList"
        :header-cell-style="{
          backgroundColor: '#F2F4F7',
          color: '#333',
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '12px',
        }"
        ref="refTable"
        style="width: 100%"
        :height="$store.state.product.screen ? '820px' : '470px'"
        v-loading="formInfo.loading"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="phone" label="联系方式"> </el-table-column>
        <el-table-column prop="amount" label="可提现收入"> </el-table-column>
        <el-table-column prop="allAmount" label="提现总金额"></el-table-column>
        <el-table-column
          prop="inSettlementAccount"
          label="待入账金额"
        ></el-table-column> 
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="scope">
            <div class="class-status" :class="[setStatus(scope.row.status)]">{{ computedValue(statusList, scope.row.status) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="审批备注" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createdTime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" header-align="center">
          <template slot-scope="scope">
            <div class="edit-wrap">
              <div class="edit-item">
                <el-button
                  type="text"
                  :disabled="scope.row.status != 0"
                  @click="editFn(scope.row)"
                  >审批
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination
        @current-change="paginationChange"
        :current-page="formInfo.pageIndex"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="formInfo.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>

import { agentquery, applystatusselect, paymentstatusselect, openagentrole } from '@/api/settings.js'
export default {
  data() {
    return { 
      formInfo: {
        name: '',
        status: '',
        pageIndex: 0,
        total: 0,
        loading: false,
      },
      statusList: [],
      merchatList: [],
      payStatusList: [],
      tableList: [],
    }
  },
  
	computed: {
			computedValue() {
				return function(list, val) {
					if(list.length) {
            let findData = list.find(res => res.value == val)
						return findData&&findData.label
					} else {
						return '--'
					}
				}
			},
			computedStatusColor() {
				return function( val) {
					let txt = '';
					switch(val) {
						case 0:
							txt = '#FAAD14'
						break;
						case 1:
							txt = '#30BF78'
						break;
						case 2:
							txt = '#F4664A'
						break;
						default:
							txt = '#3676F6'
						break;
					}
					return txt;
				}
			}
  },
  mounted() {
    this.getStatusFn();
    this.searchFn();
  },
  computed: {
		computedValue() {
				return function(list, val) {
					if(list.length) {
            let findData = list.find(res => res.value == val)
						return findData&&findData.label
					} else {
						return '--'
					}
				}
		},
    setStatus() {
      return function (type) {
        if (type == 0) {
          return 'color-default'
        } else if (type == 1) {
          return 'color-lv'
        } else {
          return 'color-no'
        }
      }
    }
  },
  methods: {
    searchFn() {
      this.formInfo.pageIndex = 1
      this.getListFn()

    },
    resetFn() {
      this.formInfo = {
        name: '',
        status: '',
        pageIndex: 0,
        total: 0,
        loading: false,
      }
      this.getListFn();
    },
    getListFn() {
      this.formInfo.loading = true;

      agentquery({
        status: this.formInfo.status,
        name: this.formInfo.name,
        'Page.Size': 10,
        'Page.Index': this.formInfo.pageIndex,
      }).then(res => {
        this.tableList = [...res.data.rows];
        this.formInfo.total = res.data.total
        this.formInfo.loading = false;
      })
    },
    paginationChange(val) {
      this.formInfo.pageIndex = val
      this.getListFn()
      this.$refs.refTable.bodyWrapper.scrollTop = 0
    },
    getStatusFn() {
        applystatusselect().then(res => {
          this.statusList = res.data;
        })
        // 审批状态
        paymentstatusselect().then(res => {
          console.log('paymentstatusselect', res)
            this.payStatusList = res.data
        })
    },
    editFn(row) {
      this.$confirm(`该操作为审核审批功能，是否确认继续？`, '提示', {
                confirmButtonText: '通过',
                cancelButtonText: '拒绝',
                type: 'warning'
      }).then((res) => {
          openagentrole({
            "orgAgentId": row.id,
            "status": res == 'confirm' ? 1 : -1,
          }).then(res => {
            this.searchFn()
            this.$message.success('操作成功')
            return
          })    
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-page {
  .box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep .el-input__inner {
      background: #f2f4f7;
      border: none;
    }

    ::v-deep .el-select,
    .el-input {
      margin-right: 8px;
    }

    .box-input-btn {
      text-align: right;

      .box-input-btn-cz {
        background: #eaf3ff;
        color: #3888e9;
        border: none;
      }

      .box-input-btn-add {
        background: #d8f5e4;
        color: #54d8a5;
        border: none;
      }
    }
  }
  

  .class-status {
    height: 29px;
    line-height: 29px;
    border-radius: 4px;
    color: #FFF;
    width: 60px;
  }

  .color-default {
    background: #3888E9;
  }

  .color-lv {
    background: #4BD97F;
  }

  .color-no {
    background: #FF5967;
  }

  .table-pagination {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
