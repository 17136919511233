import { merchatlogin, logout, getInfo } from '@/api/login'
import { getToken, setToken, removeToken, removeLocalStorage ,removeSessionStorage } from '@/utils/auth'
import { resetRouter } from '@/router'
import Cookies from 'js-cookie'


// 刷新会丢失？
const getDefaultState = () => {
  return {
    token: getToken(),
    orgToken:''
  }
}

const state = getDefaultState()

const mutations = { // store的set方法
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_ORGTOKEN: (state, token) => {
    state.orgToken = token
  },

  // SET_NAME: (state, name) => {
  //   state.name = name
  // },
  // SET_AVATAR: (state, avatar) => {
  //   state.avatar = avatar
  // },
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      merchatlogin(userInfo).then(res => {
        let data = res.data
        console.log(data);
        if (data) {
          commit('SET_TOKEN', data.accessToken)
          setToken(data.accessToken)
          //移除机构登录权限信息
          // removeLocalStorage('orgAuthInfo')
          removeSessionStorage('orgAuthInfo')
          commit('SET_ORGTOKEN', '')
          Cookies.set("expiresIn", data.expiresIn)
          // sessionStorage.setItem('name', data.realName)
          resolve(res)
        } else {
          resolve(res)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },



  // // get user info
  // getInfo({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     getInfo(state.token).then(response => {
  //       const { data } = response
  //       if (!data) {
  //         return reject('Verification failed, please Login again.')
  //       }

  //       const { name, avatar } = data

  //       commit('SET_NAME', name)
  //       commit('SET_AVATAR', avatar)
  //       resolve(data)
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // user logout
  // logout({ commit, state }) {
  //   return new Promise((resolve, reject) => {
  //     logout(state.token).then(() => {
  //       removeToken() // must remove  token  first
  //       resetRouter()
  //       commit('RESET_STATE')
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  logout({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      // resetRouter()
      commit('SET_TOKEN', '')
      commit('SET_ORGTOKEN', '')
      Cookies.remove('vue_kuaimavisa_token')
      sessionStorage.removeItem('userInfo')
      // removeLocalStorage('orgAuthInfo')
      removeSessionStorage('orgAuthInfo')
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

