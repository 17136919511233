<template>
  <div>
    <el-dialog :title="row && row.id>0 ? '编辑常见问题' : '添加常见问题'" :visible.sync="visible" width="45%" center @open="openedFn" :before-close="handleClose">
      <div>
        <el-form ref="formRef" :model="formInfo" :rules="formrules" label-width="80px">
          <el-form-item label="标题：" prop="title">
            <el-input v-model="formInfo.title" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="内容：" prop="content">
            <el-input type="textarea" v-model="formInfo.content" autocomplete="off" :autosize="{ minRows: 3, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="示例图：">
            <!-- <el-input type="textarea" v-model="formInfo.images" autocomplete="off" :autosize="{ minRows: 3, maxRows: 8}"></el-input> -->
            <el-upload class="avatar-uploader" accept=".mp3,.mp4,.mp5,.jpg, .png, .jpeg" action="#" list-type="picture-card" multiple drag :http-request="(file) => uploadImage(file)" :auto-upload="true" :file-list="fileList" :on-success="handleSuccess">
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{file, index}">
                <el-image :src="file.url" :preview-src-list="fileList" :initial-index="index" v-loading="file.status=='loading'" style="width: 100%"> </el-image>

                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview" @click="handlePreview(file)">
                    <i class="el-icon-zoom-in" />{{ index }}
                  </span>
                  <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                    <i class="el-icon-delete" />
                  </span>
                </span>
              </div>
            </el-upload>

          </el-form-item>
          <el-form-item label="按钮：" prop="buttons">
            <div style="color: #999">例如：[{"text": "我的订单","page": "/pages/order/index", "type":"info",  "size": "mini"}]</div>
            <p style="color: #999">type：info/primary/error/warning/success</p>
            <p style="color: #999">quare：square/circle  |  size：normal/large/mini</p>
            <el-input type="textarea" v-model="formInfo.buttons" autocomplete="off" :autosize="{ minRows: 3, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="排序：">
            <el-input-number v-model="formInfo.sort" @change="formInfo.sort=$event" :min="0" label="排序"></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleConfirm" :disabled="batchLoading" :loading="batchLoading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog width="30%" top="33vh" fullscreen append-to-body :visible.sync="dialogImageVisible" @close="dialogImageVisible = false">
      <div class="perview-height">
        <video v-if="dialogImageUrl && dialogImageType == 'video' " width="100%" controls="controls" :src="dialogImageUrl" />
        <!-- <el-image v-else width="100%" :src="dialogImageUrl" alt="" fit="contain" /> -->
      </div>
    </el-dialog>
    <el-image style="display: none;z-index: -999;" ref="imagePreviewRef" :src="dialogImageUrl" fit="contain" :preview-src-list="[dialogImageUrl]"></el-image>
  </div>
</template>

<script>
import { commonproblemaddorupdate } from '@/api/settings.js';
import alyoss from "@/utils/alyoss";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    var checkJSON = (rule, value, callback) => {
      if(value) {
        try {
            JSON.parse(value) // 如果不是json字符串就会抛异常
            callback();
        } catch(e) {
          callback(new Error('请输入正确的JSON格式'));
        }
      } else {
        callback();
      }
      
    };
    return {
      batchLoading: false,
      fileList: [],
      formInfo: {
        title: '',
        content: '',
        buttons: '',
        images: '',
        sort: ''
      },
      formrules: {
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' },
        ],
        buttons: [{ validator: checkJSON, trigger: 'blur' }]
      },
      // 预览图片
      dialogImageUrl: '',
      dialogImageType: '',
      dialogImageVisible: false,
    }
  },
  methods: {
    openedFn() {
      console.log('this.row', this.row);

      if (this.row.id > 0) {
        this.$nextTick(() => {
          this.formInfo = { ...this.row }
          try {
            if (this.formInfo.images) {
              this.fileList = this.formInfo.images.map(res => {
                return { url: res, status: 'success' }
              })
            }
          } catch (error) { }
        })
      } else {
        this.fileList = []
        this.formInfo = {
          title: '',
          content: '',
          buttons: '',
          images: '',
          sort: ''
        }
      }
    },
    handleClose() {
      this.formInfo = {}
      this.fileList = []
      this.$refs['formRef'].resetFields();
      this.$emit('close')
    },
    handleConfirm() {
      this.$refs['formRef'].validate((valid) => {
        if (valid) {
          if (this.fileList.findIndex(res => res.status != 'success') == -1) {
            this.saveFu()
          } else {
            this.$message.error('图片正在上传中，请稍等...')
          }
        } else {
          return false;
        }
      });

    },
    saveFu() {
      this.batchLoading = true
      let formData = JSON.parse(JSON.stringify(this.formInfo))
      formData.images = JSON.stringify(this.fileList.map(item => item.url))
      commonproblemaddorupdate(formData).then(res => {
        this.batchLoading = false
        if (res.code == 1) {
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('refresh')
        }
      }).catch(() => {
        this.batchLoading = false
      })
    },

    // 点击预览
    handlePreview(file) {
      this.dialogImageUrl = file.url
      if (file.url.indexOf('.mp4') > -1 || file.url.indexOf('.mp5') > -1 || file.url.indexOf('.mp3') > -1) {
        this.dialogImageType = 'video'
        this.dialogImageVisible = true
      } else {
        this.dialogImageType = 'image'
        console.log('this.$refs.imagePreviewRef', this.$refs.imagePreviewRef);

        this.$refs.imagePreviewRef.clickHandler()
      }

    },
    handleRemove(file) {
      let index = this.fileList.findIndex((item) => {
        return item.url == file.url
      })
      this.fileList.splice(index, 1)
    },
    checkFileFun(imgType) {
      let isError = false
      if (imgType !== 'video/mp4' && imgType !== 'video/mp5' && imgType !== 'video/mp3' && imgType !== 'image/png' && imgType !== 'image/jpg' && imgType !== 'image/jpeg') {
        this.$message({
          message: `仅支持MP4、MP5格式的视频，仅支持JPG、PNG格式的图片`,
          type: 'error',
          duration: 1500
        })
        isError = true
      }
      return isError
    },
    async uploadImage(file) {
      console.log('uploadImage: file, type, ', file)
      var isError = await this.checkFileFun(file.file.type)
      if (!isError) {
        // 判断地址是否为视频
        let fileitem = {
          url: URL.createObjectURL(file.file),
          uid: file.file.uid,
          status: 'loading'
        }
        this.fileList.push(fileitem)
        await alyoss(file.file, { zip: false }).then(alyossData => {
          let findIndex = this.fileList.findIndex(res => res.uid && res.uid == fileitem.uid)
          if (findIndex > -1) {
            this.fileList[findIndex].status = 'success'
            this.fileList[findIndex].url = alyossData
          }
        }).catch(() => {
          let findIndex = this.fileList.findIndex(res => res.uid && res.uid == fileitem.uid)
          this.fileList.splice(findIndex, 1)
        })
        this.$forceUpdate()
      }
    },
    handleSuccess(file) {
      // if (file && file.response) {
      //   let findIndex = this.fileList.findIndex(res => res.uid && res.uid == file.uid)
      //   if (findIndex > -1) {
      //     this.fileList[findIndex].status = 'success'
      //     this.fileList[findIndex].url = alyossData
      //   }
      // } 
      this.fileList = [...this.fileList]
      console.log('handleSuccess', file, this.fileList)

    }
  }
}
</script>

<style lang="scss" scoped>
// .from {
::v-deep .el-dialog {
  // background: #ffffff30;
}
.avatar-uploader {
  ::v-deep .el-upload-dragger {
    width: 100% !important;
    height: 100% !important;
  }
}

// }
</style>