import request from '@/utils/request';

  // 添加充值套餐
  export function add(params) {
    return request('/org_api/v1/recharge/add', {
      method: 'post',
      data: {...params}//get请求使用params,直接拼接在请求url上
    })
  }

  // 通知类型
  export function noticetypeselect(params) {
    return request('/app_api/v1/appinfo/noticetypeselect', {
      method: 'get',
      params: {...params}
    })
  }

    // 查询通知
    export function querynotices(params) {
        return request('/app_api/v1/user/querynotices', {
            method: 'get',
            params: {...params}
        })
    }

    // 已读邮件
    export function noticeread(params) {
        return request('/app_api/v1/appinfo/noticeread', {
            method: 'get',
            params: {...params}
        })
    }   

    // 删除全部已读邮件
    export function noticereaddel(params) {
        return request('/app_api/v1/appinfo/noticereaddel', {
            method: 'get',
            params: {...params}
        })
    }  
  
    // 是否有未读通知 
    export function hasNoticeUnRead(params) {
      return request('/app_api/v1/appinfo/hasnoticeunread', {
        method: 'get',
        params: {...params}//get请求使用params,直接拼接在请求url上
      })
    }
    
    // 获取通知详情，同时标记当前的通知已读
    export function readNotice(params) {
      return request('/app_api/v1/appinfo/usernoticedetail', {
        method: 'get',
        params: {...params}
      })
    }

    // 创建通知
    export function addNotice(params) {
      return request('/org_api/v1/orgfinance/add', {
        method: 'post',
        data: {...params}
      })
    }