<template>
    <div class="date-wrap" v-show="curValue">

        <div id="wfcalendar">
            <div class="calendar_header">
                <!-- <p style="width:33%;cursor: pointer;" > 《 </p> -->
                <div class="calendar_header_top">
                    <div class="calendar_header_top_sdit" @click.stop="monthtoleft">

                        <i class="el-icon-arrow-left"></i>
                    </div>
                    <span>{{ thisyear }}</span>
                    /
                    <p><span class="mount-title">{{ thismonth }}</span></p>
                    <div class="calendar_header_top_sdit" @click.stop="monthtoright">

                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            <ul class="wf-week">
                <li v-for="(item, index) in week" :key="index"><span>{{ item
                }}</span>
                </li>
            </ul>
            <ul class="wf-days">
                <div class="wf-days-num">
                    {{ thismonth }}
                </div>
                <li v-for="(item, index) in daylist" :key="index"
                    :class='{ check: item.checkShow == 1, checkShow: item.checkShow == 2, }' @click="setDateDay(item)">
                    <div class="check-item">
                        <p class="check-item-re"
                            :class="{ fontcolor: item.ismonth == 0 || item.checkShow == 2 || item.checkShow == 1 }">
                            <span :class="[item.isShow ? 'check-isShow' : '']">{{ item.day }}</span>
                            <span class="check-item-ab " v-if="item.ismonth != 0 && item.checkShow == 2">假</span>
                            <span class="check-item-ab" v-if="item.ismonth != 0 && item.checkShow == 1">公</span>
                        </p>
                    </div>

                </li>
            </ul>
            <div class="wf-bottom">
                <div class="wf-bottom-item">
                    <p style="color: #599FFF;">公</p>
                    <span>公休日</span>
                </div>
                <div class="wf-bottom-item">
                    <p style="color: #FF5A60;">假</p>
                    <span>假期日</span>
                </div>
            </div>

            <div class="wf-desc">
                建议按最长办理时间计划您的出行日期。由于近期领馆政策变动频繁， 实际出签时间可能延迟，请以领事馆或签证中心结果为准
            </div>
        </div>
    </div>
</template>

<script>
import { queryembassy } from '@/api/product.js';
const toDay = new Date();
export default {
    data() {
        return {
            year: '',
            month: '',
            day: '',
            weeks: '',
            daylist: [],
            week: ['日', '一', '二', '三', '四', '五', '六'],
            startYear: '',
            startMonth: '',
            startDay: '',
            curValue: false,
            saveObj: {},//保存已获取的国家使馆放假信息

        }
    },
    props: {
        value: Boolean,
        countryId: {
            type: [String, Number],
            default: ''
        },
        dateInfo: {
            default: '',
        },
        processingDay: {
            type: [String, Number],
            default: 0
        },
    },
    created() {
        if (this.value) {
            this.curValue = true;
        };
        this.init()
    },
    methods: {
        async init() {
            let myDate = toDay
            //初始化年月日星期
            this.year = this.thisyear = myDate.getFullYear()
            this.month = this.thismonth = (myDate.getMonth() + 1 < 10) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1
            this.day = (myDate.getDate() < 10) ? "0" + (myDate.getDate()) : myDate.getDate()
            this.weeks = this.weekday = myDate.getDay()

            // 本月的天数
            let thisDay = this.getMonthDays(this.year, this.month)

            //  实际天数
            let startDayAll = parseInt(this.day) + this.processingDay

            this.startYear = ''
            this.startMonth = ''
            this.startDay = ''

            // 超出本月
            if (startDayAll > thisDay) {
                this.startMonth = parseFloat(this.month) + 1
                this.startDay = startDayAll - thisDay;
                // this.month = this.thismonth = (this.startMonth < 10) ? "0" + (this.startMonth) : this.startMonth
                if (this.month == 12) {
                    this.startYear = this.year + 1
                } else {
                    this.startYear = this.year
                }
            } else {
                this.startYear = this.year
                this.startMonth = this.month
                this.startDay = this.day
            }
            //如果是第二次点击打开日历，需要回显之前选中的日期
            if (this.dateInfo && this.dateInfo.date) {
                let beChoseDay = new Date(this.dateInfo.date)
                this.thisyear = beChoseDay.getFullYear()
                this.thismonth = (beChoseDay.getMonth() + 1 < 10) ? "0" + (beChoseDay.getMonth() + 1) : beChoseDay.getMonth() + 1
            }
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth)
        },
        async getcalendar(thisyear, thismonth) {
            if (this.countryId && !this.saveObj[this.countryId]) {
                //获取国家使馆未来60天假期日情况
                let res = await queryembassy({
                    countryId: this.countryId
                })
                let arrs = res.data.map(item => {
                    item.date = item.date.split(' ')[0]
                    return item
                })
                this.saveObj[this.countryId] = arrs
            }
            let daysnumber = new Date(thisyear, thismonth, 0).getDate();//获取当月天数
            let beginweeks = (new Date(thisyear, parseInt(thismonth) - 1, 1).getDay());//获取当月1日是星期几，确定1日开始位置
            let list = []
            let mos = ''
            // 获取上个月的天数
            if (thismonth == 1) {
                mos = new Date(thisyear, 11, 0).getDate()
            } else {
                mos = new Date(thisyear, thismonth - 1, 0).getDate()
            }
            //补全当前月之前空缺位置
            if (beginweeks != -1) {

                for (var i = beginweeks; i > 0; i--) {
                    let arr = {
                        day: mos--,
                        ismonth: '0'
                    }
                    list.push(arr)
                }
            } else {
                list = []
            }
            var daylist = list.length > 0 ? list.reverse() : []

            for (var j = 1; j <= daysnumber; j++) {
                var weeks = this.weekday = new Date(thisyear, (parseInt(thismonth) - 1), j).getDay()

                let isShow = true;
                let data = j < 10 ? ("0" + j) : j
                let dateStr = thisyear + '-' + thismonth + '-' + data;
                let checkShow = 0;

                if (this.countryId) {
                    if (this.saveObj[this.countryId] && this.saveObj[this.countryId].length) {
                        this.saveObj[this.countryId].forEach(item => {
                            if (item.date == dateStr) {
                                checkShow = item.type;
                            }
                        })
                    }
                }

                if (this.dateInfo && this.dateInfo.date == dateStr) {
                    isShow = true;
                } else {
                    isShow = false;
                }


                let arr = {
                    day: j,
                    ismonth: '1',
                    weeks,
                    checkShow,
                    date: dateStr,
                    isShow
                }
                if (this.startYear > arr.date.slice(0, 4)) {
                    arr.ismonth = '0'
                } else {
                    if (this.startYear < arr.date.slice(0, 4)) {

                    } else {

                        if (this.startMonth > parseInt(arr.date.slice(5, 7))) {
                            arr.ismonth = '0'
                        } else {
                            if (this.startMonth == parseInt(arr.date.slice(5, 7))) {

                                if (this.startDay > arr.day) {
                                    arr.ismonth = '0'
                                }
                            }
                        }
                    }
                }
                daylist.push(arr)
            }
            let endweeks = new Date(thisyear, thismonth - 1, daysnumber).getDay();//获取当月最后一天是星期几

            if (daylist.length > 35) {
                var num = 7
            } else {
                var num = 14
            }
            // //补全每月结束之后空缺位置
            for (var m = 1; m < num - endweeks; m++) {
                let arr = {
                    day: m,
                    ismonth: '0'
                }
                daylist.push(arr)
            }
            return daylist;
        },
        async monthtoleft() {
            if (this.thismonth == '01' || this.thismonth == 1) {
                this.thisyear = this.thisyear - 1
                this.thismonth = 12;
                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth

            } else {
                this.thismonth = parseInt(this.thismonth - 1)
                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth

            }
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth);
        },
        async monthtoright() {
            if (this.thismonth == '12' || this.thismonth == 12) {
                this.thisyear = this.thisyear + 1
                this.thismonth = 1;
                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth
            } else {
                this.thismonth = parseInt(this.thismonth) + 1
                this.thismonth = (this.thismonth < 10) ? '0' + this.thismonth : this.thismonth
            }
            this.daylist = await this.getcalendar(this.thisyear, this.thismonth);
        },

        //传入年份和月份 获取该年对应月份的天数
        getMonthDays(year, month) {
            var thisDate = new Date(year, month, 0);  //当天数为0 js自动处理为上一月的最后一天
            return thisDate.getDate();
        },
        setWeek(week) {
            var str = '';
            if (week == 0 || week == '0') {
                str = '日'
                return str;
            }
            if (week == 1) {
                str = '一'
                return str;
            }
            if (week == 2) {
                str = '二'
                return str;
            }
            if (week == 3) {
                str = '三'
                return str;
            }
            if (week == 4) {
                str = '四'
                return str;
            }
            if (week == 5) {
                str = '五'
                return str;
            }
            if (week == 6) {
                str = '六'
                return str;
            }

        },
        setDateDay(item) {
            if (item.ismonth == 0) {
                return;
            }

            if (item.checkShow == 2) {
                return;
            }

            if (item.checkShow == 1) {
                return;
            }

            let str = this.thisyear + '年' + this.thismonth + '月' + item.day + '日   星期' + this.setWeek(item.weeks);
            let obj = {
                ...item,
                str: str
            }


            this.$emit('setDate', obj, 1)
        },
    },
    watch: {
        value(val) {
            this.curValue = val;
            if (val) {
                this.init()
            }
        },
        curValue(val) {
            this.$emit('input', val);
        },
    },
}
</script>

<style lang="scss" scoped>
.date-wrap {
    cursor: default;
    position: absolute;
    width: 414px;
    background-color: #fff;
    border-radius: 4px;
    z-index: 99;
    top: 100%;
    margin-top: 18px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 10px 30px 0 rgba(131, 146, 167, .2);
    box-shadow: 0 10px 30px 0 rgba(131, 146, 167, .2);
    border: 1px solid #e9eef2;
    line-height: 1;

    #wfcalendar {
        background: white;
        /* width: 624px; */
        border: 1px solid #eaebed;
        // max-height: 503px;
        box-sizing: border-box;
    }

    #wfcalendar hr {
        background: #eaebed;
        height: 1px;
        border: none;
        margin: 0;
        /* width: 644px; */
        margin-left: -10px;
    }

    #wfcalendar .wf-week {
        background: #F5F7F9;
    }

    #wfcalendar .wf-week li {
        width: 14%;
        height: 34px;
        line-height: 34px;
        text-align: center;
        display: inline-block;
        margin-bottom: 1px;
        font-weight: bold;

        &:last-child {
            color: #FE636D;
        }

        &:first-child {
            color: #FE636D;

        }
    }

    #wfcalendar .wf-week li .weektoday {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: #ffb400;
        line-height: 30px;
        border-radius: 15px;
        color: white;
    }

    .wf-days {
        position: relative;
        box-sizing: border-box;

        .wf-days-num {
            position: absolute;
            z-index: 0;
            font-size: 200px;
            color: #f3f6f9;
            top: 50px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            user-select: none;
            white-space: nowrap;
        }
    }

    .wf-desc {
        font-size: 10px;
        background: #FFF6DD;
        padding: 8px 20px;
        color: #FE636D;
        line-height: 15px;
    }

    .wf-bottom {
        height: 22px;
        background: #F5F7F9;
        display: flex;
        align-items: center;
        margin-left: 20px;

        .wf-bottom-item {
            display: flex;
            align-items: center;
            margin-right: 37px;

            span {
                font-size: 10px;
                margin-left: 6px;
            }

            p {
                width: 16px;
                height: 16px;
                line-height: 16px;
                background: #FFFFFF;
                border-radius: 50%;
                font-size: 10px;
                text-align: center;

            }
        }
    }

    #wfcalendar .wf-days li {
        display: inline-block;
        height: 48px;
        width: 14%;
        line-height: 48px;
        margin: 0px auto;
        border-radius: 4px;
        // background: #eef7fe;
        margin-right: 1px;
        margin-bottom: 1px;
        color: #333333;
        font-weight: bold;
        text-align: center;
    }

    #wfcalendar .wf-days li .fontcolor {
        color: #CCCCCC !important;
        // display: inline-block;
        // width: 100%;
        // height: 100%;
        font-weight: 400;
        cursor: no-drop;
        /* pointer-events:none; */
    }

    #wfcalendar .wf-days .checkShow {
        // background: #FF5A60;


        .check-item-ab {
            color: #FF5A60;
        }
    }

    #wfcalendar .wf-days .check {

        .check-item-ab {
            color: #599FFF;
        }
    }

    .check-item {
        display: flex;
        flex-direction: column;

        .check-item-re {
            position: relative;
            cursor: pointer;

            .check-isShow {
                display: inline-block;
                width: 24px;
                height: 24px;
                line-height: 24px;
                color: #FFF;
                background: #3888E9;
                box-shadow: 0px 3px 5px 1px rgba(56, 136, 233, 0.34);
                border-radius: 50%;
            }

            .check-item-ab {
                position: absolute;
                bottom: -20px;
                left: 22px;
                font-size: 12px;
            }
        }
    }

    .calendar_header_top {
        display: flex;
        align-items: center;

        .calendar_header_top_sdit {
            width: 50px;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
        }
    }

    #wfcalendar .calendar_header {
        height: 52px;
        width: 100%;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;

        .mount-title {
            font-size: 20px;
            font-weight: bold;
        }

        i {
            margin: 0 16px;
            color: #3888E9;
            cursor: pointer;
        }
    }

    #wfcalendar .calendar_header p {
        display: inline-block;
    }

    #wfcalendar .calendar_header p img {
        width: 26px;
        height: 26px;
    }

    #wfcalendar .calendar_header p span {
        display: inline-block;
        /* width: 100%; */
    }

    #wfcalendar .calendar_header p span:hover {
        color: #5cb3ff;
    }

    #wfcalendar .checkyears li {
        display: inline-block;
        width: 25%;
        text-align: center;
        line-height: 100px;
    }

    #wfcalendar .checkyears .check {
        color: #2176d9;
    }


}
</style>