import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";

import VueClipboard from "vue-clipboard2";
//icon图标
import "../src/fonts/iconfont";

import "element-ui/lib/theme-chalk/reset.css";
import "element-ui/lib/theme-chalk/index.css";

import "../src/assets/css/main.scss";
import "../src/assets/css/main.css";

//icon图标
import "../src/fonts/iconfont.css";

// 全局注册挂载事件: v-preventReClick 防止el-button重复点击
import Directives from "./directives";

// 登录/未登录路由
import "./permission";
import checkMobile from "@/utils/checkMobile.js";
import checkEmail from "@/utils/checkEmail.js";
import upload from "@/components/upload.vue";
import moment from "moment";
moment.locale("zh-cn"); //设置语言 或 moment.lang('zh-cn');
Vue.prototype.$moment = moment; //赋值使用

Vue.prototype.checkMobile = checkMobile; //验证电话号码
Vue.prototype.checkEmail = checkEmail; //验证电话号码

Vue.config.productionTip = false;

// set ElementUI lang to EN
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.use(Directives);
Vue.component("upload", upload); //上传组件

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
