<template>
    <div class="container">
      <div></div>
    </div>
  </template>
  
  <script>
  import {  } from '@/api/settings.js'
  export default {
      data() {
          return {
  
          }
      },
      mounted() {
        this.getOrgInfo();
      },
      methods: {
        getOrgInfo() {
          // getorganizationinfo().then(res => {
          //   this.orgInfo = res.data
          // })
        },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>