<template>
  <div>
    <!-- 配置资料 -- 新增自定义资料 -->
    <el-dialog
      :visible.sync="visible"
      width="864px"
      class="zl-add-class-dialog"
      :close-on-click-modal="false"
    >
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">{{formInfo.id? '编辑' : '新增' }}自定义资料</span>
          <div class="dialog-close" @click="handleClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div calss="dialog-zl-wrap">
          <el-form
            ref="ruleForm"
            :model="formInfo"
            :rules="rules"
            label-width="100px"
            class="packge-ruleForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="文档类型：" prop="type">
                  <el-select v-model="formInfo.type" placeholder="请选择">
                    <el-option
                      :label="item.label"
                      :value="item.value"
                      v-for="(item, index) in docTypeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="资料名称：" prop="name">
                  <el-input
                    v-model="formInfo.name"
                    placeholder="请输入资料名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="资料分类：" prop="categoryId">
                  <el-select v-model="formInfo.categoryId" placeholder="请选择">
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="(item, index) in docCategoryList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="备注描述：" prop="desc">
                  <el-input
                    v-model="formInfo.desc"
                    type="textarea"
                    placeholder="请输入内容"
                    size="small"
                    show-word-limit
                    :autosize="{ minRows: 6, maxRows: 12 }"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="资料图例：" prop="legend">
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    :http-request="uploadLegendFn"
                    :show-file-list="false"
                    accept=".jpg,.png,.jpeg"
                  >
                    <img
                      v-if="formInfo.legend"
                      :src="formInfo.legend"
                      class="avatar-img"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="排序：" prop="sort">
                  <el-input-number
                    v-model="formInfo.sort"
                    :min="0"
                    label="排序"
                    style="width: 150px"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="是否多张：" prop="isMultiple">
                  <el-switch
                    v-model="formInfo.isMultiple"
                    active-color="#3888E9"
                    inactive-color="#CCCCCC"
                  >
                  </el-switch>
                </el-form-item>
              </el-col>
              <el-col :span="18">
                <el-form-item label="文档：" prop="path">
                  <el-upload
                    class="upload-demo"
                    action=""
                    :http-request="uploadDocFn"
                    :file-list="
                      formInfo.path ? [{ url: formInfo.path, name: formInfo.path.substr(formInfo.path.indexOf('.') ) }] : []
                    "
                    accept=".doc,.docx,.xls,.xlsx,.pdf"
                    :limit="1"
                    :on-remove="handleDocRemove"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">
                      只能上传doc、docx、xls、xlsx、pdf文件
                    </div>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="dialog-zl-btn">
        <el-button class="zl-btn" type="danger" icon="el-icon-delete" v-if="formInfo.id" @click="handleDelDocFn">删除</el-button>
        <!-- <el-button class="close2 zl-btn" type="" @click="handleClose">取消</el-button> -->
        <el-button class="close2 zl-btn" @click="handleClose">取消</el-button>
        <el-button class="default zl-btn" :loading="submitLoading" :disabled="submitLoading" @click="handleSubmit">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { adddoc, deldoc, doccategoryselect, doctypeselect } from '@/api/personal.js';
import alyoss from "@/utils/alyoss";
export default {
    data() {
        return {
            formInfo: {
                name: '',
                type: '',
                categoryId: '',
                isMultiple: false,
                sort: 0  ,
                desc: '',
                legend: ''
            },
            rules: {
              categoryId: [
                { required: true, message: '请选择文档类型'}
              ],
              name: [
                { required: true, message: '请输入资料名称', trigger: 'blur' },
                { max: 30, message: '长度不得超过30个字符', trigger: 'blur' }
              ],
              type: [
                { required: true, message: '请选择资料分类'}
              ],
              desc: [
                { required: true, message: '请输入备注描述', trigger: 'blur' },
                { max: 500, message: '长度不得超过500个字符', trigger: 'blur' }
              ]
            },
            docCategoryList: [],
            docTypeList: [],
            docFileList: [],
            submitLoading: false
        }
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        row: {
          type: Object,
          default: () => {}
        }
    },
    watch: {
      row: {
        deep: true,
        immediate: true,
        handler(item) {
          if(item && item.id) {
            this.formInfo = JSON.parse(JSON.stringify(item))
          }
        }
      }
    },
    mounted() {
      this.getSelectFn();
    },
    methods: {
        handleClose() {
            this.$emit('close');
            this.$refs['ruleForm'].resetFields();
            this.formInfo = {
                name: '',
                type: '',
                categoryId: '',
                isMultiple: false,
                sort: 0  ,
                desc: '',
                legend: ''
            }
        },
        handleSubmit() {
          this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
              this.submitLoading = true;
                adddoc(this.formInfo).then(res => {
                  if(res.code == 1) {
                    this.$message.success('操作成功');
                    this.handleClose();
                    this.$emit('refresh')
                  }
                  this.submitLoading = false;
                }).catch(() => {
                  
                  this.submitLoading = false;
                })
            } else {
              console.log('error submit!!');
              return false;
            }
          });
          
        },
        handleDelDocFn() {
          this.$confirm('是否确认要删除该自定义资料?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            deldoc({
              docId: this.formInfo.id
            }).then(res => {
              if(res.code == 1) {
                this.$message.success('删除成功');
                this.handleClose();
                this.$emit('refresh');
              }
            })
            
          }).catch(() => {});
        },
        async uploadLegendFn(file) {
          console.log('uploadLegendFn', file);
          let imgType = file.file.type;
          if (imgType !== 'image/png' && imgType !== 'image/jpg' && imgType !== 'image/jpeg') {
            this.formInfo.legend = "";
            return this.$message({
              message: `仅支持JPG、PNG格式的图片`,
              type: 'error',
              duration: 1500
            })
          } else {
            this.formInfo.legend = await alyoss(file.file);
          }
        },
        async uploadDocFn(file) {
          let fileType = file.file.type;
          if (!(fileType.includes('doc') || fileType.includes('docx') || fileType.includes('xls') || fileType.includes('xlsx') || fileType.includes('pdf'))) {
            this.formInfo.path = "";
            return this.$message({
              message: `仅支持上传doc、docx、xls、xlsx、pdf文件`,
              type: 'error',
              duration: 1500
            })
          } else {
            this.formInfo.path = await alyoss(file.file);
          }
          
        },
        handleDocRemove() {
          this.formInfo.path = ''
        },
        getSelectFn() {
          doccategoryselect().then(res => {
            if(res.code == 1) {
              this.docCategoryList = res.data;
            }
          })
          
          doctypeselect().then(res => {
            if(res.code == 1) {
              this.docTypeList = res.data;
            }
          })
        }
    }
}
</script>

<style lang="scss" scoped>
.zl-add-class-dialog {
  .packge-ruleForm {
    margin-top: 20px;
    box-sizing: border-box;

    .el-form-item {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      .el-select {
        width: 100%;
      }
    }

    .packge-select {
      width: 135px;
      text-align: left;
    }

    .el-form-item__label {
      width: 50px !important;
      white-space: nowrap;
      height: 30px;
      text-align: left;
    }

    .el-form-item__content {
      margin: 0 auto;
    }
  }
  .dialog-member-wrap {
    .dialog-member-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -10px;

      .dialog-member-name {
        font-size: 18px;
        font-weight: bold;
      }
      .dialog-member-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dialog-close {
          margin-left: 100px;
        }
      }

      .dialog-close {
        width: 25px;
        height: 25px;
        background: #e0e0e0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .el-icon-close {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}

.avatar-img {
  width: 80px !important;
  border-radius: 10px;
  height: 80px !important;
}

.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 22px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 10px;
}

.avatar {
  /* width: 100px; */
  /* height: 100px; */
  width: 100%;
  height: 100%;
  flex: 1;
  display: block;
  position: absolute;
  z-index: 2;
}
</style>