<template>
  <div class="productPage">
    <el-tabs v-if="editInfo.isShow" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="产品信息" name="close">
        <span slot="label"><i class="el-icon-arrow-left"></i> 返回上一页</span>
      </el-tab-pane>

      <el-tab-pane label="产品信息" name="first">
        <ProductEditPage  :id="editInfo.productId" @close="handleCloseEdit" @refreshInfo="getEditProductFn"  @refreshList="refreshFn"></ProductEditPage>
      </el-tab-pane>
      <el-tab-pane  label="资料配置" name="second">
        <DataSettingsPage
          v-if="activeName == 'second'" 
          :setting="editInfo.setting"
          @close="handleCloseEdit"
        ></DataSettingsPage>
      </el-tab-pane>
    </el-tabs>
    <ProductListPage v-show="!editInfo.isShow" ref="productListRef" @openProduct="openProductFn" @openSettings="openSettingsFn"></ProductListPage>
  </div>
</template>

<script>
import {
  editProduct
} from "@/api/personal.js";

import ProductListPage from './components/productList.vue';
import ProductEditPage from './components/productEdit.vue';
import DataSettingsPage from './components/dataSettings.vue';

export default {
    components: {
        ProductListPage, ProductEditPage, DataSettingsPage
    },
    data() {
      return {
        activeName: 'first',
        
        editInfo: {
            isShow: false,
            tabsName: 'first',
            productId: '',
            setting: {}
        }
      };
    },
    methods: {
      handleClick(tab, event) {
        if(tab.name == 'close') {
            this.handleCloseEdit()
        } else {
            this.activeName = tab.name;
        }
      },
      handleCloseEdit() {
        this.editInfo.isShow = false;
      },
      openProductFn(item = {}) {
          this.activeName = 'first';
          this.editInfo.isShow = true;
          this.editInfo.setting = item;
          this.editInfo.productId = item.id || 0;
      },
      openSettingsFn(item) {
          this.activeName = 'second';
          this.editInfo.isShow = true;
          this.editInfo.setting = item;
          this.editInfo.productId = item.id || 0;
            
      },
      refreshFn() {
        this.handleCloseEdit();
        this.$refs.productListRef.searchFn(1);
      },
      getEditProductFn() {
        editProduct({
          id: this.editInfo.productId,
        }).then((res) => {
          let data = res.data;
          this.editInfo.setting = {
            ...data,
          };
        });
      },
    }
  };
</script>

<style lang="scss" scoped>
.productPage {
  width: 100%;
        height: 100%;
  ::v-deep {
    .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 40px);
        overflow: auto;
      }
    }
    .el-tab-pane {
      height: 100%;

      > div {
        height: calc(100% - 32px);
      }
    }
  }
}
</style>