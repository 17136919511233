<!-- 充值页面 -->
<template>
    <div class="recharge">
        <el-dialog title="" :close-on-click-modal="true" center :visible.sync="isVisible" width="874px"
            :before-close="handleClose" class="dialog-recharge-wrap">
            <div class="recharge-top">
                <span>余额</span>
                <p>￥{{ myPrice }}</p>

                <div class="recharge-close" @click="handleClose">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="recharge-content">
                <div class="recharge-title">
                    <div class="recharge-left">
                        <p>选择充值金额</p>
                        <span>（可用于平台保险、签证办理、通用与官网、小程序使用）</span>
                    </div>
                    <div class="recharge-right">充值说明
                        <el-tooltip class="item" effect="dark" content="Right Bottom 提示文字" placement="bottom-end" popper-class="tooltip-width">
                            <i style="margin-left: 5px;" class="el-icon-question"></i>

                            <div slot="content">
                                <div class="content-item">
                                    • 充值后余额仅能用于快马签证平台下的产品与服务，暂不支持兑换现金，不支持转账交易，不支持退款；快马签证平台包含官网与小程序，服务包含签证办理、保险服务等；
                                </div>
                                <div class="content-item">
                                    • 请按活动指定金额充值，非指定金额不享受优惠券赠送；每次充值都可获赠，优惠券有效期14天；
                                </div>
                                <div class="content-item">
                                    • 如在充值过程中遇到任何问题，请及时与客服联系
                                </div>
                                
                                <div class="content-item">
                                    • 快马签证保留对该活动的最终解释权
                                </div>
                                

                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="recharge-num">
                    <div class="recharge-num-item" @click="setActive(index, true)"
                        :class="[active == index ? 'recharge-num-item-active' : '']" v-for="(item, index) in priceList"
                        :key="index">
                        <div class="recharge-tag" :class="[item.tag == 1 ? 'recharge-tag-color1' : 'recharge-tag-color2']"
                            v-if="item.tag != 0">
                            {{ item.tag == 1 ? '超值推荐' : '限时优惠' }}
                        </div>

                        <div class="recharge-num-item-top">
                            <p>￥<span>{{ item.rechargeAmount }}</span></p>
                        </div>
                        <div class="recharge-num-item-bom">
                            <p>赠送{{ item.couponAmount }}元券</p>
                        </div>
                    </div>

                    <div class="recharge-num-item" @click="setActive(99, false)"
                        :class="[active == 99 ? 'recharge-num-item-active' : '']">
                        <div class="recharge-num-item-top">
                            <p v-if="active != 99">任意金额</p>

                            <input v-else class="recharge-num-item-input" type="text" ref="myInput"  @input="inputPriceSet"
                                v-model="priceSet">
                        </div>
                        <div class="recharge-num-item-bom recharge-num-item-bom-set">
                            <p>无赠送</p>
                        </div>
                    </div>

                </div>

                <div class="recharge-bom">
                    <div class="recharge-bom-left">
                        <!-- <img :src="orderInfo.payParameters" alt=""> -->
                        <canvas id="qrcode" width="132px" height="132px"></canvas>


                        <div class="recharge-bom-left-loading" v-if="loading">
                        </div>
                        <div class="recharge-bom-left-loading-p"  v-if="loading">

                            <i class="el-icon-loading" style="color: #ffffff;font-size: 30px;"></i>
                        </div>
                    </div>

                    <div class="recharge-right">
                        <p class="recharge-right-title">线上支付</p>
                        <div class="recharge-right-content">
                            <div class="recharge-right-item" @click="setPayActive(8)"
                                :class="[payActive == 8 ? 'recharge-right-item-active' : '']">
                                <img :src="require('@/assets/images/common/zf1.png')" alt="">
                                <span>支付宝支付</span>
                            </div>
                            <div class="recharge-right-item" @click="setPayActive(7)"
                                :class="[payActive == 7 ? 'recharge-right-item-active' : '']">
                                <img :src="require('@/assets/images/common/wx1.png')" alt="">
                                <span>微信支付</span>

                            </div>

                        </div>
                        <div class="recharge-right-price">
                            <span class="recharge-right-price-label">应付金额</span>
                            <p>￥<span>{{ allPrice }}</span></p>
                        </div>
                    </div>

                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getbalance, getrechargepackages, createrechargeorder, getrechargeorder } from '@/api/order.js'
import UQRCode from 'uqrcodejs';
export default {
    data() {
        return {
            isVisible: this.config,
            myPrice: 0,
            payActive: 8,
            priceSet: '',
            allPrice: 0,
            allItem: {},
            loading: false,
            qrData: '在线免费生成二维码工具',//数据
            canvas: null,
            orderInfo: {
                payParameters: ''
            },
            qrcodeObj: {}, // 二维码配置
            priceList: [
                {
                    price: 1000,
                    give: 40
                },
                {
                    price: 3000,
                    give: 40
                },
                {
                    price: 1000,
                    give: 40
                },
                {
                    price: 1000,
                    give: 40
                },
            ],
            active: 0,
            time: null,
        }
    },

    props: {
        config: {
            type: Boolean,
            default: false,
        }
    },

    created() {
        this.getrechargepackagesFn();


    },

    mounted() {


    },

    watch: {
        priceSet(val) {
            this.allPrice = val;

            if (this.active == 99 && !val) {

            } else {
                console.log(8888888)
                this.createrechargeorderFn(0, val)
                // this.throttle(, 1000)

                this.$forceUpdate();
            }
        }
    },

    methods: {
        handleClose() {
            // this.dialogVisible = false;   
            // 清空数据
            // this.ruleForm.checkItem = ''
            // 传递关闭事件给父组件 
            clearInterval(this.time)
            this.$emit('close')
        },
        setPayActive(payActive) {

            this.payActive = payActive

            if (this.active == 99) {
                console.log(111)
                this.createrechargeorderFn(0, this.priceSet);
            } else {
                console.log(222)

                this.createrechargeorderFn(this.priceList[this.active].id, this.priceList[this.active].price)
            }
        },

        throttle(func, delay) {
            let timer = null;
            return function () {
                const context = this;
                const args = arguments;
                if (!timer) {
                    timer = setTimeout(function () {
                        func.apply(context, args);
                        timer = null;
                    }, delay);
                }
            }
        },
        inputPriceSet(e) {
            console.log(e);
        },
        sureClick(imgStc) {
            // if (this.qrData.length <= 0) {
            //     return false
            // }
            // 获取uQRCode实例
            var qr = new UQRCode();
            // 设置二维码内容
            qr.data = imgStc;
            // 设置二维码大小，必须与canvas设置的宽高一致
            qr.size = 132;
            qr.margin = 10;
            // 调用制作二维码方法
            qr.make();
            // 获取canvas元素
            this.canvas = document.getElementById("qrcode");
            // 获取canvas上下文
            var canvasContext = this.canvas.getContext("2d");
            // 设置uQRCode实例的canvas上下文
            qr.canvasContext = canvasContext;
            // 调用绘制方法将二维码图案绘制到canvas上
            qr.drawCanvas();
        },
        downLoadImage() {
            var a = document.createElement("a");
            a.href = this.canvas.toDataURL();
            a.download = '六月初工具站-二维码生成';
            a.click();
        },
        getbalanceFn() {
            getbalance({

            }).then(res => {
                console.log(res);
                this.myPrice = res.data;
            })
        },
        getrechargepackagesFn() {
            getrechargepackages({

            }).then(res => {
                console.log(res);
                this.priceList = res.data;
                this.getbalanceFn();
                this.setActive(0, true)
            })
        },
        setActive(index, bol) {


            this.active = index


         

            if (bol) {
                this.priceSet = ''
                this.allItem = this.priceList[index]
                this.allPrice = this.priceList[index].price
                this.createrechargeorderFn(this.priceList[index].id, this.priceList[index].price);
            } else {
                this.allItem = {}
                this.allPrice = this.priceSet;
                this.$nextTick(()=>{
                    this.$refs.myInput.focus()
                })
            }

      
        },
        createrechargeorderFn(id, price) {

            this.loading = true;

            createrechargeorder({
                amount: price,
                packageId: id,
                unionPayType: this.payActive
            }).then(res => {
                console.log(res);

                this.orderInfo = res.data;
                this.sureClick(this.orderInfo.payParameters)

                console.log(res);

                if (this.time) { // 判断清除
                    clearInterval(this.time)
                }

                this.loading = false;


                this.time = setInterval(() => {
                    setTimeout(() => {
                        this.getrechargeorderFn(res.data.orderId)
                    }, 0)
                }, 1000)

            })
        },
        getrechargeorderFn(id) {

            getrechargeorder({
                orderId: id,
            }).then(res => {
                if (res.data.status == 1) {

                    this.$emit('initData')
                    this.$message({
                        message: '充值成功',
                        type: 'success'
                    });
                    clearInterval(this.time)
                }
            })
        }
    }
}
</script>



<style lang="scss">
.recharge {

    .el-dialog {
        border-radius: 8px 8px 0px 0px !important;
        overflow: hidden;
    }

    .el-dialog__header {
        display: none;
        height: 0;
    }

    .el-dialog__body {
        padding: 0 !important;

    }

}
.tooltip-width {
    max-width: 326px !important;
    padding: 12px !important;
    line-height: 17px !important;
    font-size: 12px !important;
}
</style>

<style lang="scss" scoped>
.recharge-top {
    width: 100%;
    height: 83px;
    background: linear-gradient(90deg, #0C0B0A 0%, #3A2522 100%);
    border-radius: 8px 8px 0px 0px;

    .recharge-close {
        flex: 1;
        font-size: 25px;
        margin-right: 37px;
        text-align: right;
        color: #FFF;
        cursor: pointer;
    }

    display: flex;
    align-items: center;

    span {
        font-size: 16px;
        color: #FFF;
        margin-top: 10px;
        margin-left: 24px;
    }

    p {
        font-size: 42px;
        color: #FFF;
        margin-left: 8px;
    }
}

.content-item {
    margin-bottom: 12px ;
}

.recharge-content {
    background: #FFF;
    padding: 24px;

    .recharge-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .recharge-left {
            display: flex;
            align-items: center;

            p {
                font-size: 24px;
                font-weight: bold;
            }

            span {
                font-size: 16px;
                color: #CCCCCC;
            }
        }
    }

    .recharge-bom {
        width: 826px;
        height: 193px;
        background: linear-gradient(90deg, #EAF3FF 0%, rgba(234, 243, 255, 0) 100%);
        border-radius: 8px 8px 8px 8px;
        margin-top: 32px;
        padding: 32px;
        box-sizing: border-box;
        display: flex;

        .recharge-bom-left {
            width: 132px;
            height: 132px;
            background: #FFF;
            position: relative;

            .recharge-bom-left-loading {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: #000000;
                opacity: .45;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
            }

            .recharge-bom-left-loading-p {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 3;
            }
        }

        .recharge-right {
            margin-left: 20px;

            .recharge-right-title {
                font-size: 20px;
                font-weight: bold;

            }

            .recharge-right-content {
                width: 178px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #E4E4E5;
                margin-top: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                box-sizing: border-box;

                .recharge-right-item {
                    display: flex;
                    align-items: center;

                    padding: 4px;
                    cursor: pointer;

                    img {
                        width: 14px;
                        height: 14px;
                    }

                    span {
                        font-size: 10px;
                    }

                    &.recharge-right-item-active {
                        background: #E8EFFD;
                    }
                }
            }

            .recharge-right-price {
                margin-top: 19px;
                display: flex;

                .recharge-right-price-label {
                    font-size: 12px;
                    font-weight: bold;
                    margin-top: 20px;
                    margin-right: 10px;
                }

                p {
                    font-size: 20px;
                    color: #FF3A3A;
                    font-weight: bold;

                    span {
                        font-size: 32px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .recharge-num {
        display: flex;
        margin-top: 20px;

        .recharge-num-item {
            width: 146px;
            height: 162px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #FFF;
            box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.16);
            margin-right: 24px;
            box-sizing: border-box;
            // overflow: hidden;
            cursor: pointer;
            position: relative;

            .recharge-tag {
                position: absolute;
                left: 0;
                top: -12px;
                width: 60px;
                height: 25px;

                border-radius: 8px 8px 8px 0;
                color: #FFF;
                font-size: 12px;
                text-align: center;
                line-height: 25px;
                z-index: 2;

                &.recharge-tag-color1 {
                    background: linear-gradient(90deg, #FF584A 100%, #FF2519 100%);
                }

                &.recharge-tag-color2 {
                    background: linear-gradient(90deg, #FFBF93 100%, #FF8033 100%);
                }
            }

            &.recharge-num-item-active {
                border: 1px solid #3888E9;

            }

            .recharge-num-item-top {
                height: 124px;
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 20px;
                    font-weight: bold;

                    span {
                        font-size: 36px;
                        font-weight: bold;
                        display: inline-block;
                    }
                }

                .recharge-num-item-input {
                    border-bottom: 1px solid #ddd !important;
                    width: 70px;
                    // padding-left: 20px;
                }

            }

            .recharge-num-item-bom {
                flex: 1;
                background: #EAF3FF;
                color: #3888E9;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                height: 35px;
                line-height: 35px;
                border-radius: 0 0 8px 8px;


                p {
                    color: #3888E9;
                    display: inline-block;
                    height: 100%;
                    font-weight: bold;
                }

                &.recharge-num-item-bom-set {
                    background: none;

                    p {
                        color: #CCCCCC;
                    }
                }
            }
        }
    }
}
</style>