<!-- 财务管理列表 -->
<template>
  <div class="finance-wrap">
    <div class="box-top">
      <div class="top-item">
        <p>点券余额</p>
        <p class="balance">{{ $store.state.orgBalance }}点券</p>
      </div>
      <div class="top-item">
        <p> <i class="iconfont icon-chongzhi" style="color: #1C9C1C;"></i>累计充值<b>{{ totalData.incomg }}</b>点券</p>
        <p style="line-height: 36px;"> <i class="iconfont icon-chongzhi-1" style="color: #E41313;"></i>累计花费<b>{{
          totalData.outcome }}</b>点券
        </p>
      </div>
      <div class="top-item" style="text-align: center;">
        <el-button type="primary" class="default" @click="showRecharge = true">充值点券</el-button>
      </div>
    </div>
    <div class="box-input">
      <div class="box-input-left">
        <el-date-picker v-model="timeValue" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期" @change="changeDate" value-format="yyyy-MM-dd">
        </el-date-picker>

        <el-select clearable v-model="financeForm.countryId" @change="searchFn" placeholder="签证地区" filterable>
          <el-option v-for="item in country" :key="item.id" :label="item.zhName" :value="item.id">
          </el-option>
        </el-select>

        <el-select clearable v-model="financeForm.visaTypeId" @change="searchFn" placeholder="签证类型">
          <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select clearable v-model="financeForm.userId" @change="searchFn" placeholder="操作员">
          <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select clearable v-model="financeForm.type" @change="searchFn" placeholder="分类">
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="box-input-btn">
        <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
        <el-button class="default" @click="getFinanceData(true)">导出Excel</el-button>
      </div>
    </div>


    <div class="box-table">
      <el-table :data="financeList"
        :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
        style="width: 100%" v-loading="loading" :height="$store.state.product.screen ? '820px' : '470px'"
        :cell-style="{ 'text-align': 'center' }" ref="refTable">
        <el-table-column prop="typeName" label="分类">
        </el-table-column>
        <el-table-column prop="createdTime" label="时间">
          <template slot-scope="scope">
            <div>
              <!-- {{ scope.row.createdTime ? scope.row.createdTime.slice(0, 10) : '' }} -->
              {{ scope.row.createdTime}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="操作员">
        </el-table-column>
        <el-table-column prop="countryName" label="国家/签证类型">
          <template slot-scope="scope">
            <div>
              {{ scope.row.countryName }}/{{ scope.row.visaTypeName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="applicantName" label="姓名/护照号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.applicantName }}/{{ scope.row.applicantPassport }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="amount" label="点券变动" width="70px">
          <template slot-scope="scope">
            <div>
              <span style="color: #1C9C1C;" v-if="scope.row.flag == 0">+{{ scope.row.amount }}</span>
              <span style="color: #E41313;" v-else>-{{ scope.row.amount }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="点券余额" width="70px">
        </el-table-column>
        <el-table-column prop="marks" label="备注">
        </el-table-column>
      </el-table>
    </div>

    <div class="table-pagination">

      <el-pagination @current-change="currentChange" :current-page="pageIndex" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="pageTotal">
      </el-pagination>
    </div>
    <ticketsRecharge :visible='showRecharge' @close="showRecharge = false"></ticketsRecharge>
  </div>
</template>

<script>
import { visatypelist, countrylist } from '@/api/personal.js'
import { queryfinance, orguserlist, orgfinancetypeselect, getorgtotaldata } from '@/api/finance.js'
import ticketsRecharge from '@/views/orderCenter/electron/components/recharge.vue';
import { export_json_to_excel } from '@/utils/Export2Excel';
import { EventBus } from '@/utils/eventBus.js';

export default {
  data() {
    return {
      visaType: [],
      country: [],
      financeList: [],
      countryData: [],
      financeForm: {
        visaTypeId: '',
        countryId: '',
        userId: '',
        type: '',
        startDate: '',
        endDate: ''
      },
      pageIndex: 1,
      pageTotal: 0,
      loading: false,
      showRecharge: false,
      timeValue: '',
      totalData: {},
      userList: [],
      typeList: []

    }
  },
  porps: {
  },
  components: {
    ticketsRecharge
  },
  created() {
    EventBus.$on('refreshOrgTotal', () => {
      this.getTotalData()
    });
  },
  mounted() {
    this.getFinanceData()
    this.getTotalData()
    this.getVisatypelist();
    this.getCountrylist();
    this.getOrgUserList()
    this.getOrgFinanceType()
  },
  methods: {
    //获取机构历史收支数据
    getTotalData() {
      getorgtotaldata().then(res => {
        if (res.code == 1) {
          this.totalData = res.data
        }
      })
    },
    searchFn() {
      this.pageIndex = 1
      this.getFinanceData()
    },
    getFinanceData(IsExport = false) {
      this.loading = true;
      this.financeForm['Page.Index'] = this.pageIndex
      this.financeForm['Page.Size'] = 10
      this.financeForm.IsExport = IsExport === true ? true : false
      queryfinance(this.financeForm).then(res => {
        this.loading = false;
        if (IsExport === true) {
          res.data.rows.forEach(item => {
            if (item.flag == 0) {
              item.amountStr = '+' + item.amount
            } else {
              item.amountStr = '-' + item.amount
            }
            item.countryExport = item.countryName + '/' + item.visaTypeName
            item.nameExport = item.applicantName + '/' + item.applicantPassport
          })
          const tHeader = ['分类', '时间', '操作员', '国家/签证类型', '姓名/护照号', '点券变动', '点券余额', '备注'] // 表格头部
          const filterVal = ["typeName", 'createdTime', 'userName', 'countryExport', 'nameExport', 'amountStr', 'balance', 'marks',] // 表格头部对应的数据字段名，用来过滤
          const filename = '点券消费记录'
          const data = this.formatJson(filterVal, res.data.rows)

          // 文件数据处理完成后，会自动下载
          export_json_to_excel({
            header: tHeader,
            data,
            filename
          })
        } else {
          this.financeList = res.data.rows
          this.pageTotal = res.data.total
        }
      })
    },
    formatJson(filterVal, tableData) {
      return tableData.map((v) => {
        return filterVal.map((j) => {
          return v[j]
        })
      })
    },
    getOrgUserList() {
      orguserlist().then(res => {
        if (res.code == 1) {
          this.userList = res.data
        }
      })
    },
    changeDate(val) {
      if (val) {
        this.financeForm.startDate = val[0]
        this.financeForm.endDate = val[1]
      } else {
        this.financeForm.startDate = ''
        this.financeForm.endDate = ''
      }
      this.searchFn()
    },
    getOrgFinanceType() {
      orgfinancetypeselect().then(res => {
        if (res.code == 1) {
          this.typeList = res.data
        }
      })
    },

    reset() {
      this.financeForm = {
        visaTypeId: '',
        countryId: '',
        userId: '',
        type: '',
        startDate: '',
        endDate: ''
      }
      this.searchFn();
    },

    // 签证大类
    getVisatypelist() {
      visatypelist({}).then(res => {
        this.visaType = res.data;
      })
    },
    getCountrylist() {
      countrylist({}).then(res => {
        this.country = res.data
      })
    },
    currentChange(val) {
      this.pageIndex = val;
      this.$refs.refTable.bodyWrapper.scrollTop = 0;
      this.getFinanceData();
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-wrap {
    // width: 953px;
    width: calc(100% - 30px);

  ::v-deep .el-select {
    margin-right: 5px;
    width: 125px;
  }

  ::v-deep .el-input__inner {
    background: #F2F4F7;
    border: none;
  }

  ::v-deep .el-table__row {
    .cell {
      font-size: 12px;
    }
  }

  ::v-deep .el-date-editor {
    margin-right: 5px;
    width: 240px;

    .el-range-input {
      background: transparent;
    }

    .el-range__close-icon {
      width: 10px;
    }
  }

  .box-table {
    height: calc(100% - 200px) !important;
  }

  .box-top {
    width: 593px;
    height: 81px;
    background: #FFFFFF;
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 10px;

    .top-item {
      width: 30%;

      p {
        color: #666;
        font-size: 12px;
      }

      .balance {
        color: #333333;
        font-size: 20px;
        font-weight: bold;
      }

      .iconfont {
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }

}

.box-input-left {
  display: flex;

}

.edit-wrap {

  .edit-item {
    // padding-left: 20px;


    span {
      cursor: pointer;
      display: inline-block;
      margin: 6px;

      &.lan {
        color: #3888E9;
      }

      &.copy {
        color: #9EA09E;
      }

      &.del {
        color: #E41313;
      }
    }
  }
}

.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  .box-input-btn {
    text-align: right;

    .box-input-btn-cz {
      background: #EAF3FF;
      color: #3888E9;
      border: none;
    }

    .box-input-btn-add {
      background: #D8F5E4;
      color: #54D8A5;
      border: none;
    }
  }
}
</style>