<template>
  <div id="app">
    <Header v-if="isShow && headerShow" ref="pageHeader"></Header>
    <!-- <keep-alive include="class" :max="10"> -->
    <keep-alive :include="cachedViews">
      <router-view v-if="isShow" :key="$route.fullPath" />
    </keep-alive>
    <Footer v-if="footerShow"></Footer>
  </div>
</template>
<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import { getLocalStorage,getSessionStorage } from '@/utils/auth'

export default {
  components: {
    Header,
    Footer
  },
  computed: {
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    }
  },


  provide() {
    return {
      pageReload: this.pageReloadFlag,
      reloadNotice: this.reloadNoticeDot
    }
  },
  data() {
    return {
      isShow: true,
      headerShow: true,
      footerShow: true,
      orgAuthInfo: {}
    }
  },
  created() {
    //在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))))
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state))
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val, oldVal) {

        // 订单中心列表页面缓存（跳转订单详情保留订单列表数据，跳转其他页面清空）
        this.keepAlivePage()

        // this.orgAuthInfo = getLocalStorage('orgAuthInfo')
        this.orgAuthInfo = getSessionStorage('orgAuthInfo')
        if (val.name == 'personal' || val.name == 'order' || val.name == 'settings' || val.name == 'orderDetail' || val.name == 'place' || val.name == 'orderInfoDetails' || val.name == 'orderDetail' || val.name == 'electron' || val.name == 'information' || val.name == 'informations' || val.name == 'orderCenter' || val.name == 'orderCenterElectron') {
          this.footerShow = false;
        } else {
          this.footerShow = true;
        }
        if (val.name == 'orgLogin') {
          this.footerShow = false;
          this.headerShow = false;
        }
        if (this.orgAuthInfo && this.orgAuthInfo.isOrg) {
          this.footerShow = false;
        }
      }
    },
  },
  methods: {
    keepAlivePage() {
      const { name } = this.$route 

      // 订单中心列表页面缓存（跳转订单详情保留订单列表数据，跳转其他页面清空）
      if (name && name == "orderCenter") {
          this.$store.dispatch('tagsView/addView', this.$route)
      } else if (name && !(name == "orderCenter" || name == "informations" || name == "orderCenterElectron")) {
        this.$store.dispatch('tagsView/delView', this.$router.options.routes.find(item => item.name == "orderCenter"))
      }     
    }, 
    pageReloadFlag() {
      // this.orgAuthInfo = getLocalStorage('orgAuthInfo')
      this.orgAuthInfo = getSessionStorage('orgAuthInfo')

      this.isShow = false;
      this.headerShow = false
      this.$nextTick(() => {
        this.isShow = true;
        this.headerShow = true
        if (this.orgAuthInfo && this.orgAuthInfo.isOrg) {
          this.footerShow = false;
          this.$router.push({ name: 'settings' })
        } else {
          this.$router.push({
            path: '/',
            query: {
              id: parseFloat(Math.random() * 1 + 1, 10)
            }
          })
        }
      })
    },
    reloadNoticeDot() {
      this.$refs.pageHeader.getNoticeStatus();
    }
  }
}

</script>

<style lang="scss" scoped>
// .home {
//   span {
//     color: red;
//   }
// }
</style>
