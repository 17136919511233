<!-- 产品管理列表 -->
<template>
    <div class="product-user">
        <div class="box-input">

            <div class="box-input-left">
                <el-select class="box-input-left-z" @clear="clearContinent" style="width: 120px" clearable filterable
                    v-model="productForm.continentId" placeholder="请选择洲区" @change="changeContinet">
                    <el-option v-for="item in countryData" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>

                <el-select clearable v-model="productForm.countryId" @change="getQuery(1)" placeholder="请选择国家" filterable>
                    <el-option v-for="item in (String(productForm.continentId) ? country : countryAll)" :key="item.id" :label="item.zhName" :value="item.id">
                    </el-option>
                </el-select>

                <el-select clearable v-model="productForm.visaTypeId" @change="getQuery(1)" placeholder="请选择签证大类" filterable>
                    <el-option v-for="item in visaType" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>

                <el-select v-model="productForm.isEnable" placeholder="是否上架" clearable filterable>
                    <el-option label="全部" value=""> </el-option>
                    <el-option label="已上架" value="true"> </el-option>
                    <el-option label="未上架" value="false"> </el-option>
                </el-select>

                <el-input style="width: 200px;" v-model="productForm.name" placeholder="产品名称" clearable></el-input>

            </div>

            <div class="box-input-btn">
                <el-button class="default" @click="searchFn(1)">搜索</el-button>
                <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
                <el-button class="box-input-btn-cz"  icon="el-icon-plus" @click="addProduct">添加</el-button>
                <el-button plain type="primary" icon="el-icon-download" :loading="loading" :disabled="loading" @click="clickExcelFn">导出</el-button>
            </div>
        </div>


        <div class="box-table">
            <el-table :data="productList"
                :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
                style="width: 100%" v-loading="loading" :height="$store.state.product.screen ? '820px' : '470px'"
                :cell-style="{ 'text-align': 'center' }" ref="refTable">

                <el-table-column prop="title" label="产品名称">
                </el-table-column>
                <el-table-column prop="areaName" label="国家-领区">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.countryName }} - {{ scope.row.areaName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="签证">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.typeName }} - {{ scope.row.subTypeName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="serviceType" label="标签"></el-table-column>
                <el-table-column prop="estimatedDays" label="预计办理"></el-table-column>
                <el-table-column prop="visaLegend" label="签证图例">
                    <template slot-scope="scope">
                        <div v-if="scope.row.visaLegend">
                            <el-image style="width: 88px; height: 55px;border-radius: 8px;" :src="scope.row.visaLegend"
                                :preview-src-list="[scope.row.visaLegend]">
                            </el-image>
                        </div>
                        <p v-else>未配置</p>
                    </template>
                </el-table-column>

                <el-table-column prop="price1" label="成本价" width="70px">
                </el-table-column>
                <el-table-column prop="price2" label="代理价" width="70px">
                </el-table-column>
                <el-table-column prop="price3" label="商户价" width="70px">
                </el-table-column>
                <el-table-column prop="price4" label="零售价" width="70px">
                </el-table-column>
                <el-table-column prop="sort" label="排序" width="120px">
                    <template slot-scope="scope">
                        <div> 
                            <el-input-number v-model="scope.row.sort" controls-position="right"  :min="0" style="transform: scale(.8);width: 120px;" @change="sortChange(scope.row)"></el-input-number>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="enable" label="是否上架" width="70px">
                    <template slot-scope="scope">
                        <div>
                            <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC"
                                @change="switchChange(scope.row)">
                            </el-switch>

                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center">
                    <template slot-scope="scope">
                        <div class="edit-wrap">
                            <div class="edit-item">
                                <span class="lan" @click="editProduct(scope.row)">编辑</span>
                                <span class="lan" @click="toDataSettings(scope.row)">资料</span>
                            </div>
                            <div class="edit-item">
                                <span class="copy" @click="setProductCopy(scope.row.id)">复制</span>
                                <span class="del" @click="delProduct(scope.row.id)">删除</span>
                            </div>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="table-pagination">

            <el-pagination :page-sizes="[10, 20, 50, 100, 200, 500]" :current-page="pageIndex" :page-size="pageSize"  @current-change="currentChange" @size-change="sizeChange"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { query, visatypelist, arealist, countrylist, deleteProduct, visaenable, getcountrys, productCopy } from '@/api/personal.js'
import { productvisasort } from '@/api/product.js'
import { export_json_to_excel } from '@/utils/Export2Excel';

export default {
    data() {
        return {
            continent: [],
            visaType: [],
            area: [],
            countryAll: [],
            country: [],
            productList: [],
            countryData: [],
            productForm: {
                continentId: '',
                visaTypeId: '',
                countryId: '',
                areaId: '',
                name: '',
                isEnable: 'true'
            },
            pageIndex: 1,
            pageSize: 10,
            pageTotal: 0,
            loading: false,

            editInfo: {
                isShow: false,
                tabsName: 'first',
                productId: '',
                setting: {}
            }

        }
    },
    porps: {
        init: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.continent = this.$store.state.product.countrys.continentCountry
        console.log(this.$store.state.product.screen);
        this.getQuery();
        this.getVisatypelist();

        // this.getArealist();
        this.getCountrylist();

        this.getcountrysFn();

        if (this.init) {
            this.continent = this.$store.state.countrys.continentCountry
            this.getQuery();
            this.getVisatypelist();

            // this.getArealist();
            // this.getCountrylist();
        }
    },
    methods: {
        getQuery(index) {
            this.pageIndex=index||this.pageIndex;
            this.loading = true;
            query({
                'Page.Size': this.pageSize,
                'Page.Index': this.pageIndex,
                Title: this.productForm.name,
                Continent: this.productForm.continentId,
                VisaTypeId: this.productForm.visaTypeId,
                CountryId: this.productForm.countryId,
                IsEnable: this.productForm.isEnable,
            }).then(res => {
                console.log(res);
                this.loading = false;
                this.productList = res.data.rows
                this.pageTotal = res.data.total
            })
        },

        setProductCopy(id) {

            this.$confirm(`是否要复制该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {


                productCopy({
                    id
                }).then(res => {
                    this.$message({
                        message: '复制成功！',
                        type: 'success'
                    });
                    this.getQuery();
                })


            }).catch(err => {

            })

            return;

        },

        reset() {
            this.productForm = {
                continentId: '',
                visaTypeId: '',
                countryId: '',
                areaId: '',
                isEnable: 'true'
            }
            this.getQuery();
        },

        searchFn(index) {
            this.pageIndex=index;
            this.getQuery();
        },
        clearContinent(e) {

            this.country = []
            this.productForm.countryId = ''
            console.log(e);
        },
        getcountrysFn() {
            getcountrys().then(res => {

                this.countryData = res.data.continentCountry
            })
        },
        addProduct() {
            this.$emit('openProduct');
        },
        editProduct(item) {
            this.$emit('openProduct', item);
        },

        toDataSettings(item) {
            this.$emit('openSettings', item);
        },

        delProduct(id) {
            this.$confirm(`是否要删除该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteProduct({
                    id: id,

                }).then(res => {
                    this.$message({
                        message: '删除成功！',
                        type: 'success'
                    });
                    this.getQuery();
                }).catch(res => {

                })

            }).catch(err => {

            })
        },

        changeContinet(e) {
            this.countryData.forEach(item => {
                if (item.value == e) {
                    this.country = item.datas
                    this.productForm.countryId = ''
                }
            })
            this.country = [...this.country]
            this.pageIndex=1;
            this.getQuery();
        },


        switchChange(item) {
            item.enable = !item.enable
            this.$confirm(`是否要${!item.enable ? '上架' : '下架'}该产品`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                visaenable({
                    id: item.id,
                    enable: !item.enable,

                }).then(res => {
                    this.getQuery();
                }).catch(res => {

                })

            }).catch(res => {

            })
        },
        sortChange(row) {
            productvisasort({ "id": row.id, "sort": row.sort }).then(res => {
                if(res.code == 1) { 
                    this.getQuery();
                }
            })
        },

        // 签证大类
        getVisatypelist() {
            visatypelist({

            }).then(res => {
                console.log(res);
                this.visaType = res.data;
            })
        },
        // 领区
        // getArealist () {
        //     arealist({
        //          
        //     }).then(res => {
        //         console.log(res);
        //         this.area = res.data;
        //     })
        // },

        getCountrylist() {
            countrylist({

            }).then(res => {
                console.log(res);

                this.countryAll = res.data
            })
        },
        currentChange(val) {
            this.pageIndex = val;
            this.$refs.refTable.bodyWrapper.scrollTop = 0;
            this.getQuery();
        },
        sizeChange(val) {
            this.pageSize = val;
            this.$refs.refTable.bodyWrapper.scrollTop = 0;
            this.getQuery();
        },
        clickExcelFn() {
            this.$confirm(`确定导出当前页订单吗`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.exportExcelFn()
            }).catch(res => {

            })
        },
        exportExcelFn(IsExport = false) {
            if(this.productList.length == 0) {
                return this.$message.error('暂无订单')
            }
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 1200);
            let productList = JSON.parse(JSON.stringify( this.productList))
            productList = productList.map(item => {
                    item.countryAreaName = item.countryName + '-' + item.areaName
                    item.typeSubTypeName = item.typeName + '/' + item.subTypeName
                    item.enableName = item.enable ? '已上架' : '未上架';
                    return item
            })
                const tHeader = ['产品名称', '国家-领区', '签证', '标签',  '预计办理', '签证图例', '成本价', '代理价', '商户价', '零售价', '是否上架'] // 表格头部
                const filterVal = ["title", 'countryAreaName', 'typeSubTypeName', 'serviceType', 'estimatedDays', 'visaLegend', 'price1', 'price2', 'price3', 'price4', 'enableName'] // 表格头部对应的数据字段名，用来过滤
                const filename = '产品管理列表数据'+ new Date().getTime() 
                const data = this.formatJson(filterVal, productList)

                // 文件数据处理完成后，会自动下载
                export_json_to_excel({
                    header: tHeader,
                    data,
                    filename
                })
        },
        formatJson(filterVal, tableData) {
            return tableData.map((v) => {
                return filterVal.map((j) => {
                return v[j]
                })
            })
        },

    }
}
</script>

<style lang="scss" scoped>
.product-user {
    padding: 16px;
    // min-width: 973px;
    // width: 100%;
    height: 100%;

    ::v-deep{
         .el-select {
            margin-right: 8px;
        }

        .el-input__inner {
            background: #F2F4F7;
            border: none;
        }

        .el-table__row {
            .cell {
                font-size: 12px;
            }
        }
    }
}
.box-input-left {
    display: flex;

}

.box-table {
    // flex-grow: 1;
    // height: 100%;
    // display: flex;
    // flex-direction: column;
    // flex: 1;
}

.edit-wrap {

    .edit-item {
        // padding-left: 20px;


        span {
            cursor: pointer;
            display: inline-block;
            margin: 6px;

            &.lan {
                color: #3888E9;
            }

            &.copy {
                color: #9EA09E;
            }

            &.del {
                color: #E41313;
            }
        }
    }
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .box-input-btn {
        text-align: right;

        .box-input-btn-cz {
            background: #EAF3FF;
            color: #3888E9;
            border: none;
        }

        .box-input-btn-add {
            background: #D8F5E4;
            color: #54D8A5;
            border: none;
        }
    }
}
</style>