<template>
    <div :class="[btnType ? 'upload-flex' : '', getSize(thisType), { 'doc-sty': isDoc }]"
        :style="customSty">
        <el-upload class="avatar-uploader" ref="uploadOss" :action="url" :multiple="multiple" :data="formData" :name="name"
            :drag="drag" :on-success="onSuccess" :on-error="onError" :auto-upload="autoUpload" :before-upload="beforeUpload"
            :limit="limit" :on-remove="onRemove" :on-exceed="onExceed" :on-change="getUploadFile" list-type="pictrue"
            :show-file-list="showFileList" :disabled="disabled"  :accept="fileType">
            <div class="el-upload__text" v-if="!imageUrl && !isDoc">
                <img class="el-upload-img" src="@/assets/images/common/upload.png" />
                <span class="el-upload-span">点击或者拖拽上传</span>
            </div>
            <el-image v-if="!isDoc" class="avatar"
                :style="{ position: isImgShow ? 'absolute' : 'none',...customSty}"
                fit="cover" :src="imageUrl" @load="imgLoad" @error="imgLoadError" @mouseleave="bottomShow = false"
                @mouseenter="bottomShow = true" :preview-src-list="isImgShow ? [imageUrl] : isImgShow"
                @click.stop="op"></el-image>

            <div v-else class="oa">
                <div class="other-doc">
                    <img :src="docLogo" alt="" class="doc-logo">
                    <p @click.stop="openDoc">{{ fileNameShow }}</p>
                </div>
                <div class="doc-modal" v-if="isDoc" @click.stop="openDoc">
                    <span @click.stop="delDoc" class="del-btn">
                        <i class="el-icon-delete"></i>
                    </span>
                </div>
            </div>

            <div v-if="!disabled && !isDoc" class="btn-show" :style="{ bottom: !bottomShow ? '-30px' : '0px' }"
                @mouseleave="bottomShow = false" @mouseenter="bottomShow = true">
                重新上传
            </div>
            <!-- </div> -->
            <div class="del-show" v-if="isDelete && !isDoc" @click.stop="delDoc"
                :style="{ top: !bottomShow ? '-30px' : '4px' }" @mouseleave="bottomShow = false"
                @mouseenter="bottomShow = true">
                <i class="el-icon-delete"></i><span>删除</span>
            </div>

        </el-upload>

    </div>
</template>
<script>
require('@/utils/oss/hmac');
require('@/utils/oss/sha1');
import { env } from "@/utils/oss/ossConfig";
import Base64 from "@/utils/oss/Base64";
import axios from 'axios'
import wordLogo from '@/assets/images/common/word.png'
import pdfLogo from '@/assets/images/common/pdf.png'
import xlsLogo from '@/assets/images/common/xls.png'

import Crypto from "@/utils/oss/crypto"
import { format } from '@/utils/oss/dateTime'
export default {
    name: 'uploadOss',
    data() {
        return {
            url: env.uploadImageUrl,
            formData: {
                key: '',
                policy: '',
                OSSAccessKeyId: env.OSSAccessKeyId,
                success_action_status: '200',
                signature: ''
            },
            bottomShow: false,
            isImgShow: true,
            name: 'file',
            fileList: [],
            imgLoadIng: true,
            imgLoadIngErr: false,
            showCropper: false,
            option: {
                fileinfo: '',
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 剪切后的图片质量（0.1-1）
                full: true, // 输出原图比例截图 props名full
                outputType: 'jpg', // 裁剪生成额图片的格式
                canMove: true,  // 能否拖动图片
                original: false,  // 上传图片是否显示原始宽高
                canMoveBox: true,  // 能否拖动截图框
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 200,
                autoCropHeight: 200,
                fixedBox: true // 截图框固定大小
            },
            previews: '',
            sufName: '',//上传文件的后缀名 xlsx ,xls,doc,pdf ,jpg ,png ,gif ,jpeg
            isDoc: false,//是否是文档（xlsx ,xls,doc,pdf）
            fileNameShow: '',
            wordLogo: wordLogo,
            pdfLogo: pdfLogo,
            xlsLogo: xlsLogo,
            docModalShow: false
        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        noAvatar: {
            type: Boolean,
            default: false
        },
        drag: {
            type: Boolean,
            default: false
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 5
        },
        showBtn: {
            type: Boolean,
            default: true
        },
        showAdd: {
            type: Boolean,
            default: true
        },
        imageUrl: [String, Array],
        showFileList: {
            type: Boolean,
            default: false
        },
        filePath: {
            type: String,
            default: ''
        },
        btnTitle: {
            type: String,
            default: '点击上传'
        },
        showImg: {
            type: Boolean,
            default: true
        },
        mediaType: {
            type: [String, Number],
            default: ''
        },
        disabled: Boolean,
        accept: {
            type: String,
            default: ''
        },
        radiusShow: {
            type: Boolean,
            default: false
        },
        notifyShow: {
            type: Boolean,
            default: true
        },
        btnType: {
            type: Boolean,
            default: false
        },
        widthImg: {
            type: Number,
            default: 200
        },
        heightImg: {
            type: Number,
            default: 140
        },
        // avater 头像   passport 或其他
        thisType: {
            type: String,
            default: 'other'
        },
        isDelete: {
            type: Boolean,
            default: false
        },
        dirType: {
            type: Boolean | String,
            default: '',
        },
        customSty:{
            type:Object,
            default:()=>{
                width : '105px';
                height : '105px'
            }
        }
    },
    computed: {
        fileType() {
            let vm = this;
            if (vm.accept == 'img') {
                return '.jpg,.png,.jpeg,.gif'
            } else if (vm.accept == 'video') {
                return '.rmvb,.mp4,.3gp,.mpeg,.wmv,.avi,.mov,.mpv'
            } else {
                return ''
            }
        },
        setImageUrl() {
            return function (url) {
                if (url.indexOf('?') > 0) {

                    return url.split('?')[0]
                } else {
                    return url
                }
            }
        },
        getSize() {
            return function (type) {

                if (type == 'avater') {
                    // obj = {
                    //     width: '121px',
                    //     height: '151px'
                    // }
                    return 'avater-sty'
                } else if (type == 'passport') {
                    return 'passport-sty'
                } else if (type == 'custom') {
                    return 'custom'
                } else {
                    return 'other-sty'
                }

            }
        },
        docLogo() {
            if (this.sufName == 'xls' || this.sufName == 'xlsx') {
                return xlsLogo
            } else if (this.sufName == 'pdf') {
                return pdfLogo
            } else {
                return wordLogo
            }
        }

    },
    created() {
        if (this.multiple && this.showAdd) {
            this.fileList = this.imageUrl;
        } else {
            this.fileList = [];
        }

        // if (this.thisType == 'custom') {
        //     this.customSty = {

        //     }
        // }
        if (this.imageUrl) {
            let nameArr = this.imageUrl.split("."),
                nameArr1 = this.imageUrl.split("/")
            this.sufName = nameArr[nameArr.length - 1]
            this.fileNameShow = nameArr1[nameArr1.length - 1]
            this.isDoc = !['jpg', 'png', 'jpeg', 'gif'].includes(this.sufName)
        }
        this.imgLoadIngErr = true;
    },
    watch:{
        imageUrl(newVal){
            if(newVal){
                let nameArr = newVal.split("."),
                    nameArr1 = newVal.split("/")
                this.sufName = nameArr[nameArr.length - 1]
                this.fileNameShow = nameArr1[nameArr1.length - 1]
                this.isDoc = !['jpg', 'png', 'jpeg', 'gif'].includes(this.sufName)
            } else {
                this.isDoc = false
            }

        }
    },
    methods: {
        op() {
            this.isImgShow = true;
        },
        onSuccess(res, file, fileList) {
            var vm = this;
            if (vm.notifyShow) {
                vm.$message({
                    message: '上传成功!',
                    type: 'success'
                });
            }
            vm.$emit('uploading', true);
            if (vm.multiple) {
                vm.fileList.push(env.uploadImageUrl + '/' + vm.formData.key);
                vm.$emit('input', vm.fileList);
            } else {
                console.log(env.uploadImageUrl, vm.formData.key);
                vm.$emit('input', env.uploadImageUrl + '/' + vm.formData.key)
                vm.$emit('success', env.uploadImageUrl + '/' + vm.formData.key, vm.mediaType)
            }
            vm.$refs.uploadOss.clearFiles();
            vm.$emit('change');
            this.$nextTick(()=>{
                this.isDoc = !['jpg', 'png', 'jpeg', 'gif'].includes(this.sufName)
            })
        },
        delDoc() {
           
            let vm = this;
            vm.$confirm(`确定删除该文件吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                vm.$emit('deleteDoc')
            }).catch(res => {

            })
        },
        onError() {
            var vm = this;

            vm.$message({
                message: '上传失败!',
                type: 'error'
            });
            vm.$emit('uploading', true);
            vm.$refs.uploadOss.clearFiles();
        },
        onRemove(file, fileList) {
            var vm = this;
            vm.$emit('uploading', true);
        },
        onExceed() {
            var vm = this;
            vm.$message({
                message: "只能上传" + vm.limit + "张,如需替换请先删除已上传图片",
                type: 'warning'
            });

        },
        beforeUpload(file) {
            var vm = this;
            let day = new Date();
            let nameArr = file.name.split(".");
            this.fileNameShow = file.name
            this.sufName = nameArr[nameArr.length - 1]
            let dirType = vm.dirType || ''; // 目录分类
            let fileName = this.filePath || day.getTime() + Math.floor(Math.random() * 150) + `.${this.sufName}`;
            vm.$emit('uploading', false);
            let dir = `visa/` + day.getFullYear() + '/' + ((day.getMonth() + 1) < 10 ? "0" : "") + (day
                .getMonth() + 1) + '/' + (day.getDate() < 10 ? "0" : "") + day.getDate();
            if (dirType !== '')
                dir += `/${dirType}`
            vm.formData.key = dir + '/' + fileName
            vm.formData.policy = vm.getPolicyBase64();
            vm.formData.signature = vm.getSignature(vm.formData.policy);

        },
        imgLoad() {
            var vm = this;
            vm.imgLoadIng = false;
            vm.imgLoadIngErr = false;
        },
        imgLoadError() {
            var vm = this;
            vm.imgLoadIng = false;
            vm.imgLoadIngErr = true;
        },
        getPolicyBase64() {
            let date = new Date();
            date.setHours(date.getHours() + 87600);
            let srcT = date.toISOString();
            const policyText = {
                "expiration": srcT,
                //设置该Policy的失效时间
                "conditions": [["content-length-range", 0, 20 * 1024 * 1024] // 设置上传文件的大小限制,5mb
                ]
            };
            const policyBase64 = Base64.encode(JSON.stringify(policyText));
            return policyBase64;
        },
        getSignature(policyBase64) {
            const accesskey = env.AccessKeySecret;
            const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, accesskey, {
                asBytes: true
            });
            const signature = Crypto.util.bytesToBase64(bytes);
            return signature;
        },
        getUploadFile(file, fileList) {
            var vm = this;
            if (vm.autoUpload) return;
            var files = file.raw;
            vm.option.fileinfo = file; // 保存下当前文件的一些基本信息
            let reader = new FileReader(); // 创建文件读取对象
            reader.onload = async e => {
                let data;
                if (typeof e.target.result === 'object') {
                    // 把Array Buffer转化为blob 如果是base64不需要
                    data = window.URL.createObjectURL(new Blob([e.target.result]));
                } else {
                    data = e.target.result;
                }
                vm.option.img = data; // 设置option的初始image
                vm.showCropper = true;
            };
            reader.readAsArrayBuffer(files);
            this.option.fixedNumber = [1, 1]; // 图片的裁剪宽高比在这里也可以进行设
        },
        // 实时预览函数
        realTime(data) {
            this.previews = data;
        },
        // 自定义上传请求
        upload(file) {
            var vm = this;
            vm.showCropper = false;
            vm.$refs.uploadOss.clearFiles();
            let formData = new FormData();
            // 获取签名
            vm.beforeUpload(file);
            for (var key in vm.formData) {
                formData.append(key, vm.formData[key])
            }
            formData.append('file', file);
            axios.create().post(vm.url, formData).then(res => {
                vm.$message({
                    message: '上传成功!',
                    type: 'success'
                });
                vm.$emit('input', env.uploadImageUrl + '/' + vm.formData.key);
                vm.$emit('change');
                vm.$emit('uploading', true);
                vm.imgLoadIngErr = false;
            })
        },
        closeDialoag() {
            var vm = this;
            vm.showCropper = false;
            vm.$refs.uploadOss.clearFiles();

        },
        btClick() {
            this.$emit('input', false)
        },
        openDoc() {
            window.open(this.imageUrl)
        }
    }
}
</script>

<style lang="scss">
.avater-sty {

    .el-upload {
        width: 121px;
        height: 151px;
        background: #F2F8FF;
    }

    .el-upload-dragger {
        width: 100%;
        height: 100%;
        background: #F2F8FF;
        position: relative;
    }

    .el-upload-img {
        width: 47px;
        height: 47px;
        margin-top: 37px;
    }

    .el-upload-span {
        display: block;
        font-size: 12px;
        color: #3888E9;
        margin-top: 12px;
    }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }
}

.btn-show {

    position: absolute;
    left: 0;
    bottom: -30px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    z-index: 3;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 30px;
    transition: bottom .5s;
    background-color: rgba(94, 155, 225, .9);
}

.del-show {

    position: absolute;
    right: 6px;
    top: -30px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    z-index: 4;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    width: 56px;
    height: 26px;
    border-radius: 4px;
    transition: top .5s;
    background-color: rgba(0, 0, 0, .6);

    i {
        color: #FFF;
    }
}

.custom {
    background: #F2F8FF;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar-uploader{
        width: 100%;
        height: 100%;
    }
    .el-upload-dragger {
        width: 100%;
        height: 100%;
        background: #F2F8FF;

    }
    .el-upload{
        width: 100%;
        height: 100%;
    }
    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 52px;
        height: 52px;
        margin-top: 25px;

    }

    .el-upload-span {
        display: block;
        font-size: 12px;
        color: #3888E9;
        margin-top: 16px;
    }
}

.other-sty {
    width: 279px;
    height: 164px;
    background: #F2F8FF;

    .el-upload-dragger {
        width: 279px;
        height: 164px;
        background: #F2F8FF;

    }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 52px;
        height: 52px;
        margin-top: 39px;

    }

    .el-upload-span {
        display: block;
        font-size: 14px;
        color: #3888E9;
        margin-top: 16px;
    }
}

.doc-sty {
    width: 100px;
    height: 140px;
    background: #fff;

    .el-upload-dragger {
        width: 100px;
        height: 140px;
        background: #fff;
        margin-top: 5px;
        overflow: visible;
        border: none;
    }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 52px;
        height: 52px;
        margin-top: 39px;

    }

    .el-upload-span {
        display: block;
        font-size: 14px;
        color: #3888E9;
        margin-top: 16px;
    }
}


.doc-modal {
    position: absolute;
    left: -17px;
    top: -4px;
    width: 135px;
    height: 162px;
    background: rgba(51, 51, 51, .25);
    border-radius: 8px;
    display: flex;
    justify-content: flex-end;
    transition:visibility 1s, opacity 1s;;
    visibility:hidden;
    opacity: 0;

    .del-btn {
        width: 21px;
        height: 21px;
        background: #FF5967;
        border-radius: 4px;
        margin: 5px 5px 0 0;
        cursor: pointer;

        i {
            color: #fff;
        }
    }
}
.oa{
    width: 100%;
    height: 100%;
}
.oa:hover >.doc-modal{
    visibility:visible;
    opacity: 1;
}





.passport-sty {
    width: 392px;
    height: 263px;
    background: #F2F8FF;

    .el-upload-dragger {
        width: 392px;
        height: 263px;
        background: #F2F8FF;

    }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 84px;
        height: 84px;
        margin-top: 60px;
    }

    .el-upload-span {
        display: block;
        font-size: 27px;
        color: #3888E9;
        margin-top: 25px;
    }
}
</style>
<style scoped lang="scss">
.avatar-img {
    width: 80px !important;
    border-radius: 50%;
    height: 80px !important;

}


.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 22px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
}

.avatar {
    /* width: 100px; */
    /* height: 100px; */
    width: 100%;
    height: 100%;
    flex: 1;
    display: block;
    position: absolute;
    z-index: 2;
}

.btn {
    display: flex;
    align-items: center;
}

.upload-flex {
    display: flex;
    align-items: center;
}

.noP {
    width: 200px;
    height: 140px;
}

.other-doc {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
        position: absolute;
        bottom: -22px;
        color: #409EFF;
        cursor: pointer;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 145px;
    }
    img{
        width: 100%;
        height: 100%;
    }
}</style>
    