<template>
    <div :class="[btnType ? 'upload-flex' : '']" class="upload-two">
        <el-upload class="avatar-uploader" v-if="uploadFlag" ref="uploadOss" :action="url" :multiple="multiple"
            :data="formData" :name="name" :drag="drag" :on-success="onSuccess" :on-error="onError" :auto-upload="autoUpload"
            :limit="limit" :on-remove="onRemove" :on-exceed="onExceed" :on-change="getUploadFile" list-type="pictrue"
            :show-file-list="showFileList" :disabled="disabled" :accept="fileType" :file-list="fileList"
            :before-upload="beforeUpload">

            <!-- <div  style="display: flex; "> -->
            <el-button class="upload-btn-upload" :class="btnText == '继续上传' ? 'close2' : 'default'">{{ btnText }}</el-button>
            <!--  -->
            <!--  -->
        </el-upload>

    </div>
</template>
<script>
require('@/utils/oss/hmac');
require('@/utils/oss/sha1');
import { env } from "@/utils/oss/ossConfig";
import Base64 from "@/utils/oss/Base64";
import axios from 'axios'

import Crypto from "@/utils/oss/crypto"
import { format } from '@/utils/oss/dateTime'
import * as imageConversion from 'image-conversion'
export default {
    name: 'uploadOss',
    data() {
        return {
            url: env.uploadImageUrl,
            formData: {
                key: '',
                policy: '',
                OSSAccessKeyId: env.OSSAccessKeyId,
                success_action_status: '200',
                signature: ''
            },
            uploadList: [],
            bottomShow: false,
            isImgShow: true,
            name: 'file',
            fileList: [],
            imgLoadIng: true,
            imgLoadIngErr: false,
            showCropper: false,
            option: {
                fileinfo: '',
                img: '', // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 剪切后的图片质量（0.1-1）
                full: true, // 输出原图比例截图 props名full
                outputType: 'jpg', // 裁剪生成额图片的格式
                canMove: true,  // 能否拖动图片
                original: false,  // 上传图片是否显示原始宽高
                canMoveBox: true,  // 能否拖动截图框
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 200,
                autoCropHeight: 200,
                fixedBox: true // 截图框固定大小
            },
            previews: '',
            uploadFlag: true,
            imgSize: 0,
            imgArr: []

        }
    },
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        noAvatar: {
            type: Boolean,
            default: false
        },
        drag: {
            type: Boolean,
            default: false
        },
        autoUpload: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 5
        },
        showBtn: {
            type: Boolean,
            default: true
        },
        showAdd: {
            type: Boolean,
            default: true
        },
        imageUrl: [String, Array],
        showFileList: {
            type: Boolean,
            default: false
        },
        filePath: {
            type: String,
            default: ''
        },
        btnTitle: {
            type: String,
            default: '点击上传'
        },
        showImg: {
            type: Boolean,
            default: true
        },
        mediaType: {
            type: [String, Number],
            default: ''
        },
        disabled: Boolean,
        accept: {
            type: String,
            default: ''
        },
        radiusShow: {
            type: Boolean,
            default: false
        },
        notifyShow: {
            type: Boolean,
            default: true
        },
        btnType: {
            type: Boolean,
            default: false
        },
        widthImg: {
            type: Number,
            default: 200
        },
        heightImg: {
            type: Number,
            default: 140
        },
        // other 按钮批量上传   passport 或其他
        thisType: {
            type: String,
            default: 'other'
        },
        isDelete: {
            type: Boolean,
            default: false
        },
        dirType: {
            type: Boolean|String,
            default: '',
        },
        btnText: {
            type: String,
            default: '批量上传护照'
        }
    },
    computed: {
        fileType() {
            let vm = this;
            if (vm.accept == 'img') {
                return '.jpg,.png,.jpeg,.gif'
            } else if (vm.accept == 'video') {
                return '.rmvb,.mp4,.3gp,.mpeg,.wmv,.avi,.mov,.mpv'
            } else {
                return ''
            }
        },
        setImageUrl() {
            return function (url) {
                if (url.indexOf('?') > 0) {

                    return url.split('?')[0]
                } else {
                    return url
                }
            }
        },
        // getSize() {
        //     return function (type) {

        //         if (type == 'avater') {
        //             // obj = {
        //             //     width: '121px',
        //             //     height: '151px'
        //             // }
        //             return 'avater-sty'
        //         } else if (type == 'passport') {
        //             return 'passport-sty'
        //         } else {
        //             return 'other-sty'
        //         }

        //     }
        // },

    },
    created() {
        // console.log(this.multiple, this.imageUrl);
        this.fileList = [];
        if (this.multiple && this.showAdd) {
            this.fileList = this.imageUrl;
        } else {
            this.fileList = [];
        }
        this.imgLoadIngErr = true;
    },
    methods: {
        op() {
            this.isImgShow = true;
        },
        onSuccess(res, file, fileList) {
            // this.imgArr.push(env.uploadImageUrl + '/' + this.formData.key);


            let index = fileList.findIndex(item => item.uid == file.uid);
            if(index > -1){
                this.$emit('input', this.imgArr[index])
            }
            
            // if(this.imgSize==fileList.length){
            //     this.imgSize=0;
            //     this.imgArr=[];
            // } 



            // console.log(1111);

            // console.log(res, file, 11111);
            // console.log(fileList, 2222)

            // // console.log(vm.beforeUpload(file))

            // var vm = this;
            // vm.fileList = fileList;

            // if (vm.notifyShow) {
            //     // vm.$message({
            //     //     message: '上传成功!',
            //     //     type: 'success'
            //     // });
            // }
            // vm.$emit('uploading', true);

            // if (vm.multiple) {
            //     console.log(6666)
            //     console.log(vm.formData.key, env.uploadImageUrl);

            //     console.log();
            //     vm.uploadList.push(env.uploadImageUrl + '/' + vm.formData.key)

            // } else {
            //     // console.log(env.uploadImageUrl, vm.formData.key);
            //     vm.$emit('input', env.uploadImageUrl + '/' + vm.formData.key)
            //     vm.$emit('success', env.uploadImageUrl + '/' + vm.formData.key, vm.mediaType)
            // }
            // console.log(this.uploadList, fileList)
            // if (this.uploadList.length == fileList.length) {
            //     console.log(111);
            //     vm.$emit('input', this.uploadList);
            //     this.uploadList = [];
            //     vm.fileList = []
            //     this.$refs.uploadOss.clearFiles();
            //     this.uploadFlag = false;
            //     this.$nextTick(() => (this.uploadFlag = true))
            // }
            // vm.$emit('change');
        },
        delDoc() {
            let vm = this;

            vm.$confirm(`确定删除该文件吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                vm.$emit('deleteDoc')
            }).catch(res => {

            })
        },
        onError() {
            var vm = this;

            vm.$message({
                message: '上传失败!',
                type: 'error'
            });
            vm.$emit('uploading', true);
            vm.$refs.uploadOss.clearFiles();
        },
        onRemove(file, fileList) {
            var vm = this;
            vm.$emit('uploading', true);
        },
        onExceed() {
            var vm = this;
            vm.$message({
                message: "只能上传" + vm.limit + "张,如需替换请先删除已上传图片",
                type: 'warning'
            });

        },
        beforeUpload(file) {
            var vm = this;
            // console.log(file, '看下file', URL.createObjectURL(file));
            // 如果是图片就进行压缩
            if (file.name.indexOf("pdf") == -1) return new Promise((resolve) => {
                // 压缩到100KB,这里的100就是要压缩的大小,可自定义
                imageConversion.compressAccurately(file, {
                    size: 100, //The compressed image size is 100kb
                    // accuracy: 0.9, //the accuracy of image compression size,range 0.8-0.99,default 0.95;
                    // type: "image/jpeg",
                    // width: 1200,
                    // orientation: 1
                }).then(res => {
                    let suffix = file.name.indexOf("pdf") !== -1 ? ".pdf" : ".jpg";
                    let day = new Date();
                    let suf = file.name.split(".");
                    let fileName = this.filePath || day.getTime() + Math.floor(Math.random() * 150) + suffix;
                    vm.$emit('uploading', false);
                    let dir = `visa/` + day.getFullYear() + '/' + ((day.getMonth() + 1) < 10 ? "0" : "") + (day
                        .getMonth() + 1) + '/' + (day.getDate() < 10 ? "0" : "") + day.getDate();


                    // if (dirType !== '')
                    //     dir += `/${dirType}`
                    vm.formData.key = dir + '/' + fileName
                    vm.formData.policy = vm.getPolicyBase64();
                    vm.formData.signature = vm.getSignature(vm.formData.policy);
                    this.imgArr.push(env.uploadImageUrl + '/' + this.formData.key);
                    console.log(res, '看下压缩之后的东西')
                    resolve(res);
                });
            })

            let suffix = file.name.indexOf("pdf") !== -1 ? ".pdf" : ".jpg";
            let day = new Date();
            let suf = file.name.split(".");
            let fileName = this.filePath || day.getTime() + Math.floor(Math.random() * 150) + suffix;
            vm.$emit('uploading', false);
            let dir = `visa/` + day.getFullYear() + '/' + ((day.getMonth() + 1) < 10 ? "0" : "") + (day
                .getMonth() + 1) + '/' + (day.getDate() < 10 ? "0" : "") + day.getDate();


            // if (dirType !== '')
            //     dir += `/${dirType}`
            vm.formData.key = dir + '/' + fileName
            vm.formData.policy = vm.getPolicyBase64();
            vm.formData.signature = vm.getSignature(vm.formData.policy);
            this.imgArr.push(env.uploadImageUrl + '/' + this.formData.key);


            // vm.uploadList.push()
            // vm.$emit('input', env.uploadImageUrl + '/' + vm.formData.key);
            // 清空上传文件列表，否则影响上传文件数限制判断
            // this.$refs.uploadOss.clearFiles();
        },
        imgLoad() {
            var vm = this;
            vm.imgLoadIng = false;
            vm.imgLoadIngErr = false;
        },
        imgLoadError() {
            var vm = this;
            vm.imgLoadIng = false;
            vm.imgLoadIngErr = true;
        },
        getPolicyBase64() {
            let date = new Date();
            date.setHours(date.getHours() + 87600);
            let srcT = date.toISOString();
            const policyText = {
                "expiration": srcT,
                //设置该Policy的失效时间
                "conditions": [["content-length-range", 0, 20 * 1024 * 1024] // 设置上传文件的大小限制,5mb
                ]
            };
            const policyBase64 = Base64.encode(JSON.stringify(policyText));
            return policyBase64;
        },
        getSignature(policyBase64) {
            const accesskey = env.AccessKeySecret;
            const bytes = Crypto.HMAC(Crypto.SHA1, policyBase64, accesskey, {
                asBytes: true
            });
            const signature = Crypto.util.bytesToBase64(bytes);
            return signature;
        },
        getUploadFile(file, fileList) {
            let vm = this;
            if(fileList.findIndex(res=> res.status != "success") > -1) {
                this.$emit('uploadLoading', true)
            }else {
                this.$emit('uploadLoading', false)
            }
            // this.beforeUpload(file);
        },
        // 实时预览函数
        realTime(data) {
            this.previews = data;
        },
        // 自定义上传请求
        upload(file, fileList) {
            var vm = this;
            vm.showCropper = false;
            vm.$refs.uploadOss.clearFiles();
            let formData = new FormData();
            // 获取签名
            vm.beforeUpload(file);
            for (var key in vm.formData) {
                formData.append(key, vm.formData[key])
            }
            formData.append('file', file);
            axios.create().post(vm.url, formData).then(res => {
                vm.$message({
                    message: '上传成功!',
                    type: 'success'
                });
                vm.$emit('input', env.uploadImageUrl + '/' + vm.formData.key);
                vm.$emit('change');
                vm.$emit('uploading', true);
                vm.imgLoadIngErr = false;
            }).catch(err => {
                console.log(err, '看下err');
            })
        },
        closeDialoag() {
            var vm = this;
            vm.showCropper = false;
            vm.$refs.uploadOss.clearFiles();

        },
        btClick() {
            this.$emit('input', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.upload-two {


    .el-upload {
        width: 162px;
        height: 48px;
        background: #F2F8FF;

        .el-upload-dragger {
            width: 162px;
            height: 48px;
            border: none;
            // background: #F2F8FF;
            position: relative;
        }
    }
}

.avater-sty {




    .el-upload-img {
        width: 47px;
        height: 47px;
        margin-top: 37px;
    }

    .el-upload-span {
        display: block;
        font-size: 12px;
        color: #3888E9;
        margin-top: 12px;
    }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }
}

.upload-btn-upload {
    width: 162px;
    height: 48px;
    background: #599fff;
    border-radius: 4px;
    border: 1px solid #5e9be1;
    color: #FFF;
}



.btn-show {

    position: absolute;
    left: 0;
    bottom: -30px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    z-index: 3;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: 30px;
    transition: bottom .5s;
    background-color: rgba(94, 155, 225, .9);
}

.upload-btn {
    width: 162px;
    height: 48px;
    background: #599fff;
    border-radius: 4px;
    border: 1px solid #5e9be1;
    color: #FFF;
    margin-top: 32px;
}

.del-show {

    position: absolute;
    right: 6px;
    top: -30px;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    z-index: 4;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    width: 56px;
    height: 26px;
    border-radius: 4px;
    transition: top .5s;
    background-color: rgba(0, 0, 0, .6);

    i {
        color: #FFF;
    }
}

.other-sty {
    width: 279px;
    height: 164px;
    background: #F2F8FF;

    // .el-upload-dragger {
    //     width: 279px;
    //     height: 164px;
    //     background: #F2F8FF;

    // }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 52px;
        height: 52px;
        margin-top: 39px;

    }

    .el-upload-span {
        display: block;
        font-size: 14px;
        color: #3888E9;
        margin-top: 16px;
    }
}




.passport-sty {
    // width: 392px;
    // height: 263px;
    background: #F2F8FF;

    // .el-upload-dragger {
    //     width: 392px;
    //     height: 263px;
    //     background: #F2F8FF;

    // }

    .el-upload__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        flex: 1;
        height: 100%;

        // img {
        //     width: 47px;
        //     height: 47px;
        // }
    }

    .el-upload-img {
        width: 84px;
        height: 84px;
        margin-top: 60px;
    }

    .el-upload-span {
        display: block;
        font-size: 27px;
        color: #3888E9;
        margin-top: 25px;
    }
}
</style>
<style scoped>
.avatar-img {
    width: 80px !important;
    border-radius: 50%;
    height: 80px !important;

}


.avatar-uploader .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 22px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 10px;
}

.avatar {
    /* width: 100px; */
    /* height: 100px; */
    width: 100%;
    height: 100%;
    flex: 1;
    display: block;
    position: absolute;
    z-index: 2;
}

.btn {
    display: flex;
    align-items: center;
}

.upload-flex {
    display: flex;
    align-items: center;
}

.noP {
    width: 200px;
    height: 140px;
}
</style>
    