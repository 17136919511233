export const Json = {
  stateList: [
    { label: "阿富汗", value: "AFG" },
    { label: "阿尔巴尼亚", value: "ALB" },
    { label: "阿尔及利亚", value: "DZA" },
    { label: "安道尔", value: "AND" },
    { label: "安哥拉", value: "AGO" },
    { label: "安提瓜和巴布达", value: "ATG" },
    { label: "阿根廷", value: "ARG" },
    { label: "亚美尼亚", value: "ARM" },
    { label: "澳大利亚", value: "AUS" },
    { label: "奥地利", value: "AUT" },
    { label: "阿塞拜疆", value: "AZE" },
    { label: "巴哈马", value: "BHS" },
    { label: "巴林", value: "BHR" },
    { label: "孟加拉", value: "BGD" },
    { label: "巴巴多斯", value: "BRB" },
    { label: "白俄罗斯", value: "BLR" },
    { label: "比利时", value: "BEL" },
    { label: "伯利兹", value: "BLZ" },
    { label: "贝宁", value: "BEN" },
    { label: "百慕大", value: "BMU" },
    { label: "不丹", value: "BTN" },
    { label: "玻利维亚", value: "BOL" },
    { label: "波黑", value: "BIH" },
    { label: "博茨瓦纳", value: "BWA" },
    { label: "巴西", value: "BRA" },
    { label: "英属印度洋领地", value: "IOT" },
    { label: "文莱", value: "BRN" },
    { label: "保加利亚", value: "BGR" },
    { label: "布基纳法索", value: "BFA" },
    { label: "布隆迪", value: "BDI" },
    { label: "柬埔寨", value: "KHM" },
    { label: "喀麦隆", value: "CMR" },
    { label: "加拿大", value: "CAN" },
    { label: "佛得角", value: "CPV" },
    { label: "中非", value: "CAF" },
    { label: "乍得", value: "TCD" },
    { label: "智利", value: "CHL" },
    { label: "中国", value: "CHN" },
    { label: "中国台湾", value: "TWN" },
    { label: "哥伦比亚", value: "COL" },
    { label: "科摩罗", value: "COM" },
    { label: "刚果（布）", value: "COG" },
    { label: "哥斯达黎加", value: "CRI" },
    { label: "科特迪瓦", value: "CIV" },
    { label: "克罗地亚", value: "HRV" },
    { label: "古巴", value: "CUB" },
    { label: "塞浦路斯", value: "CYP" },
    { label: "捷克", value: "CZE" },
    { label: "丹麦", value: "DNK" },
    { label: "吉布提", value: "DJI" },
    { label: "多米尼克", value: "DMA" },
    { label: "多米尼克", value: "DOM" },
    { label: "厄瓜多尔", value: "ECU" },
    { label: "埃及", value: "EGY" },
    { label: "萨尔瓦多", value: "SLV" },
    { label: "赤道几内亚", value: "GNQ" },
    { label: "厄立特里亚", value: "ERI" },
    { label: "爱沙尼亚", value: "EST" },
    { label: "埃塞俄比亚", value: "ETH" },
    { label: "斐济群岛", value: "FJI" },
    { label: "芬兰", value: "FIN" },
    { label: "法国", value: "FRA" },
    { label: "加蓬", value: "GAB" },
    { label: "冈比亚", value: "GMB" },
    { label: "格鲁吉亚", value: "GEO" },
    { label: "德国", value: "D" },
    { label: "加纳", value: "GHA" },
    { label: "直布罗陀", value: "GIB" },
    { label: "希腊", value: "GRC" },
    { label: "格陵兰", value: "GRL" },
    { label: "格林纳达", value: "GRD" },
    { label: "危地马拉", value: "GTM" },
    { label: "几内亚", value: "GIN" },
    { label: "几内亚比绍", value: "GNB" },
    { label: "圭亚那", value: "GUY" },
    { label: "海地", value: "HTI" },
    { label: "梵蒂冈", value: "VAT" },
    { label: "洪都拉斯", value: "HND" },
    { label: "匈牙利", value: "HUN" },
    { label: "冰岛", value: "ISL" },
    { label: "印度", value: "IND" },
    { label: "印尼", value: "IDN" },
    { label: "伊朗", value: "IRN" },
    { label: "伊拉克", value: "IRQ" },
    { label: "爱尔兰", value: "IRL" },
    { label: "以色列", value: "ISR" },
    { label: "意大利", value: "ITA" },
    { label: "牙买加", value: "JAM" },
    { label: "日本", value: "JPN" },
    { label: "约旦", value: "JOR" },
    { label: "哈萨克斯坦", value: "KAZ" },
    { label: "肯尼亚", value: "KEN" },
    { label: "基里巴斯", value: "KIR" },
    { label: "韩国；", value: "KOR" },
    { label: "朝鲜；", value: "PRK" },
    { label: "科索沃共和国", value: "RKS" },
    { label: "科威特", value: "KWT" },
    { label: "吉尔吉斯斯坦", value: "KGZ" },
    { label: "老挝", value: "LAO" },
    { label: "拉脱维亚", value: "LVA" },
    { label: "黎巴嫩", value: "LBN" },
    { label: "莱索托", value: "LSO" },
    { label: "利比里亚", value: "LBR" },
    { label: "利比亚", value: "LBY" },
    { label: "列支敦士登", value: "LIE" },
    { label: "立陶宛", value: "LTU" },
    { label: "卢森堡", value: "LUX" },
    { label: "马其顿", value: "MKD" },
    { label: "马达加斯加", value: "MDG" },
    { label: "马拉维", value: "MWI" },
    { label: "马来西亚", value: "MYS" },
    { label: "马尔代夫", value: "MDV" },
    { label: "马里", value: "MLI" },
    { label: "马耳他", value: "MLT" },
    { label: "马绍尔群岛", value: "MHL" },
    { label: "毛里塔尼亚", value: "MRT" },
    { label: "毛里求斯", value: "MUS" },
    { label: "墨西哥", value: "MEX" },
    { label: "密克罗尼西亚联邦", value: "FSM" },
    { label: "摩尔多瓦", value: "MDA" },
    { label: "摩纳哥", value: "MCO" },
    { label: "蒙古国；蒙古", value: "MNG" },
    { label: "黑山", value: "MNE" },
    { label: "蒙塞拉特岛", value: "MSR" },
    { label: "摩洛哥", value: "MAR" },
    { label: "莫桑比克", value: "MOZ" },
    { label: "缅甸", value: "MMR" },
    { label: "纳米比亚", value: "NAM" },
    { label: "瑙鲁", value: "NRU" },
    { label: "尼泊尔", value: "NPL" },
    { label: "荷兰", value: "NLD" },
    { label: "新西兰", value: "NZL" },
    { label: "尼加拉瓜", value: "NIC" },
    { label: "尼日尔", value: "NER" },
    { label: "尼日利亚", value: "NGA" },
    { label: "挪威", value: "NOR" },
    { label: "阿曼", value: "OMN" },
    { label: "巴基斯坦", value: "PAK" },
    { label: "帕劳", value: "PLW" },
    { label: "巴勒斯坦", value: "PSE" },
    { label: "巴拿马", value: "PAN" },
    { label: "巴布亚新几内亚", value: "PNG" },
    { label: "巴拉圭", value: "PRY" },
    { label: "秘鲁", value: "PER" },
    { label: "菲律宾", value: "PHL" },
    { label: "波兰", value: "POL" },
    { label: "葡萄牙", value: "PRT" },
    { label: "卡塔尔", value: "QAT" },
    { label: "罗马尼亚", value: "ROU" },
    { label: "俄罗斯", value: "RUS" },
    { label: "卢旺达", value: "RWA" },
    { label: "圣基茨和尼维斯", value: "KNA" },
    { label: "圣卢西亚", value: "LCA" },
    { label: "圣文森特和格林纳丁斯", value: "VCT" },
    { label: "圣马力诺", value: "SMR" },
    { label: "圣多美和普林西比", value: "STP" },
    { label: "沙特阿拉伯", value: "SAU" },
    { label: "苏格兰", value: "SC-" },
    { label: "塞内加尔", value: "SEN" },
    { label: "塞尔维亚", value: "SRB" },
    { label: "塞舌尔", value: "SYC" },
    { label: "塞拉利昂", value: "SLE" },
    { label: "新加坡", value: "SGP" },
    { label: "斯洛伐克", value: "SVK" },
    { label: "斯洛文尼亚", value: "SVN" },
    { label: "所罗门群岛", value: "SLB" },
    { label: "索马里", value: "SOM" },
    { label: "南非", value: "ZAF" },
    { label: "西班牙", value: "ESP" },
    { label: "斯里兰卡", value: "LKA" },
    { label: "苏丹", value: "SDN" },
    { label: "苏里南", value: "SUR" },
    { label: "斯威士兰", value: "SWZ" },
    { label: "瑞典", value: "SWE" },
    { label: "瑞士", value: "CHE" },
    { label: "叙利亚", value: "SYR" },
    { label: "塔吉克斯坦", value: "TJK" },
    { label: "坦桑尼亚", value: "TZA" },
    { label: "泰国", value: "THA" },
    { label: "东帝汶", value: "TLS" },
    { label: "多哥", value: "TGO" },
    { label: "汤加", value: "TON" },
    { label: "特立尼达和多巴哥", value: "TTO" },
    { label: "突尼斯", value: "TUN" },
    { label: "土耳其", value: "TUR" },
    { label: "土库曼斯坦", value: "TKM" },
    { label: "图瓦卢", value: "TUV" },
    { label: "乌干达", value: "UGA" },
    { label: "乌克兰", value: "UKR" },
    { label: "阿联酋", value: "ARE" },
    { label: "英联邦", value: "GBD" },
    { label: "英国", value: "GBR" },
    { label: "联合国组织", value: "UNO" },
    { label: "美国", value: "USA" },
    { label: "乌拉圭", value: "URY" },
    { label: "乌兹别克斯坦", value: "UZB" },
    { label: "瓦努阿图", value: "VUT" },
    { label: "委内瑞拉", value: "VEN" },
    { label: "萨摩亚", value: "WSM" },
    { label: "也门", value: "YEM" },
    { label: "赞比亚", value: "ZMB" },
    { label: "津巴布韦", value: "ZWE" }
  ]
}
