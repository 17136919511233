import request from '@/utils/request';

//查询当前机构点券余额
export function getorgbalance(params) {
  return request('/org_api/v1/orgfinance/getorgbalance', {
    method: 'get',
    params: { ...params }
  })
}
//查询当前机构点券流水
export function queryfinance(params) {
  return request('/org_api/v1/orgfinance/queryfinance', {
    method: 'get',
    params: { ...params }
  })
}

//查询机构用户列表
export function orguserlist(params) {
  return request('/org_api/v1/orgfinance/orguserlist', {
    method: 'get',
    params: { ...params }
  })
}

//查询机构流水分类类型
export function orgfinancetypeselect(params) {
  return request('/org_api/v1/orgfinance/orgfinancetypeselect', {
    method: 'get',
    params: { ...params }
  })
}

//查询当前机构点券历史收入支出数据
export function getorgtotaldata(params) {
  return request('/org_api/v1/orgfinance/getorgtotaldata', {
    method: 'get',
    params: { ...params }
  })
}