<template>
  <!-- 系统配置列表 -->
  <div class="page-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="常见问题" name="first">
        <CommonProblemPage v-if="activeName == 'first'"></CommonProblemPage>
      </el-tab-pane>
      <el-tab-pane label="教程配置" name="third">
        <TutorialConfigPage v-if="activeName == 'third'"></TutorialConfigPage>
      </el-tab-pane>
      <el-tab-pane label="平台对接配置" name="fourth">
        <PlatformDockPage v-if="activeName == 'fourth'"></PlatformDockPage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CommonProblemPage from "./components/CommonProblem/index.vue";
import TutorialConfigPage from "./components/TutorialConfig.vue";
import PlatformDockPage from "./components/PlatformDock.vue";

export default {
  components: { CommonProblemPage, TutorialConfigPage, PlatformDockPage,  },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    handleClick(tab, event) {
        this.activeName = tab.name
        console.log(tab.name, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep {
    .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 40px);
      }
    }
    .el-tab-pane {
      height: 100%;

      > div {
        height: calc(100% - 32px);
      }
    }
  }
}
</style>