<template>
  <div class="orglogin-box">
    <div style="position: absolute;top: 30px;left: 50px;"><img src="@/assets/images/organization/logo.png" alt="logo" style="width: 120px;height: 87px;"></div>
    <div class="orglogin-wrap">
      <div class="org-box">
        <div>
          <div class="top-title">智能处理资料，助力签证业务增长</div>
          <img
            :src="require('@/assets/images/organization/login.gif')"
            alt=""
            class="bg"
          />
        </div>
        <div class="login-form">
          <img
            :src="require('@/assets/images/organization/sass-logo.png')"
            alt=""
            class="sass"
          />
          <div class="form-title">
            <span>{{ setNewPassword ? "找回密码" : "密码登录" }}</span>
            <span
              v-show="setNewPassword"
              class="go-login"
              @click="setNewPassword = false"
            >
              <i class="el-icon-arrow-left"></i> 返回登录</span
            >
          </div>
          <div :class="['form-style', { 'new-style': setNewPassword }]">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
              v-show="!setNewPassword"
              @keyup.enter.native="onSubmit"
            >
              <el-form-item label="" prop="account">
                <el-input
                  prefix-icon="el-icon-user-solid"
                  v-model="loginForm.account"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  prefix-icon="el-icon-lock"
                  v-model="loginForm.password"
                  placeholder="请输入密码"
                  show-password
                ></el-input>
              </el-form-item>
            </el-form>
            <el-form
              ref="forgetForm"
              :model="forgetForm"
              :rules="forgetRules"
              v-if="setNewPassword"
            >
              <el-form-item label="" prop="account">
                <el-input
                  v-model="forgetForm.account"
                  placeholder="请填写手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="code">
                <el-input
                  v-model="forgetForm.code"
                  placeholder="手机验证码"
                ></el-input>
                <span
                  :class="['codebtn', { active: forgetForm.account }]"
                  v-show="showCodeBtn"
                  @click.stop="getCode"
                >
                  {{ codeText }}</span
                >
                <span class="codebtn" v-show="!showCodeBtn">{{ second }}</span>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  v-model="forgetForm.password"
                  placeholder="请设置新密码"
                  show-password
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div
            class="bottom-text"
            @click="setNewPassword = true"
            v-show="!setNewPassword"
          >
            找回密码
          </div>
          <div class="btn">
            <el-button
              class="btn1"
              @click="resetForm('loginForm')"
              v-show="!setNewPassword"
              >重置</el-button
            >
            <el-button type="primary" @click="onSubmit" v-show="!setNewPassword"
              >登录账户</el-button
            >
            <el-button
              type="primary"
              @click="onFogetSubmit"
              style="width: 486px; margin-left: 0"
              v-show="setNewPassword"
              >提交</el-button
            >
          </div>
          <div v-show="setNewPassword" class="tips">
            签证专员请联系您公司或者团队管理员重置密码
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <p>版权所有@2021-2023 尚优优 (深圳) 文体科技有限公司</p>
      <p @click="toFilings">粤ICP备2021165436号-9</p>
    </div>
  </div>
</template>

<script>
import { orglogin, sendsms } from "@/api/organization.js";
import { setToken, setLocalStorage, setSessionStorage } from "@/utils/auth";
import Cookies from "js-cookie";
export default {
  inject: ["pageReload"],
  name: "orglogin",
  data() {
    var validatorAccount = (rule, value, callback) => {
      let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/; //	手机
      if (!value) {
        return callback(new Error("请填写手机号"));
      }
      if (!reg.test(value)) {
        return callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        account: "", //18170843717
        password: "", //123456
        loginType: 0,
      },
      forgetForm: {
        account: "",
        password: "",
        code: "",
        loginType: 1,
      },
      rules: {
        account: [
          {
            validator: validatorAccount,
            required: true,
            trigger: ["click", "change", "blur"],
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      forgetRules: {
        account: [
          {
            validator: validatorAccount,
            required: true,
            trigger: ["click", "change", "blur"],
          },
        ],
        code: [
          { required: true, message: "请填写手机验证码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请设置新密码", trigger: "blur" },
        ],
      },
      setNewPassword: false, //找回密码
      showCodeBtn: true,
      second: 60,
      codeText: "获取验证码",
      timer: null,
      zoom: 1,
    };
  },
  created() {
    const devicePixelRatio = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    if (devicePixelRatio !== 1) {
      // 老ie可以提示
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        alert("浏览器版本过低，请更换！");
      } else {
        this.zoom = document.body.offsetWidth / 1920;
      }
    }
    document.body.style.setProperty("--zoom", this.zoom);
  },
  mounted() {
    document.getElementsByClassName("orglogin-wrap")[0].style.zoom = this.zoom;
  },
  methods: {
    //去备案界面
    toFilings(){
      window.open("https://beian.miit.gov.cn/#/Integrated/index")
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //登录
    onSubmit() {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          orglogin(this.loginForm).then((res) => {
            if (res.code == 1) {
              console.log(res);
              this.$store.commit("user/SET_ORGTOKEN", res.data.accessToken);
              this.$store.commit("user/SET_TOKEN", "");
              // this.$store.commit('SET_TOKEN', res.data.accessToken);
              // setLocalStorage('orgAuthInfo', { isOrg: true })
              setSessionStorage("orgAuthInfo", { isOrg: true });
              setToken(res.data.accessToken);
              Cookies.set("expiresIn", res.data.expiresIn);
              this.$message.success("登录成功！");
              this.pageReload();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //找回密码
    onFogetSubmit() {
      this.$refs["forgetForm"].validate((valid) => {
        if (valid) {
          orglogin(this.forgetForm).then((res) => {
            if (res.code == 1) {
              this.$message.success("密码重置成功！");
              this.setNewPassword = false;
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //获取验证码
    getCode() {
      if (!this.forgetForm.account) {
        this.$message.warning("请先填写手机号！");
        return;
      }
      sendsms({ account: this.forgetForm.account }).then((res) => {
        if (res.code == 1) {
          this.$message.success("发送成功！");
          this.showCodeBtn = false;
          this.timer = setInterval(() => {
            this.second--;
            if (this.second == 0) {
              this.showCodeBtn = true;
              this.second = 60;
              this.codeText = "再次获取";
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.orglogin-box{
  width: 100%;
  position: relative;
  background: #fff;
}
.bottom{
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  p{
    color:#cccccc
  }
  P:nth-child(2){
    cursor: pointer;
  }
  P:nth-child(2):hover{
    color: #3888e9;
  }
}
.orglogin-wrap {
  width: 100%;
  height: calc(100vh / var(--zoom));
  display: flex;
  justify-content: center;
  align-items: center;

  .org-box {
    display: flex;
    .top-title {
      font-weight: bold;
      color: #333333;
      font-size: 55px;
    }

    .bg {
      // width: 1200px;
      height: 580px;
      // margin-top: 50px;
      // margin-left: -200px;
    }

    .login-form {
      // width: 550px;
      min-height: 500px;
      max-height: 540px;
      background: #ffffff;
      box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.16);
      border-radius: 8px;
      margin-top: 70px;
      margin-left: 50px;
      padding: 32px;
      box-sizing: border-box;

      .sass {
        width: 253px;
        height: 41px;
      }

      .form-title {
        font-size: 24px;
        font-weight: bold;
        margin-top: 30px;

        .go-login {
          color: #3888e9;
          font-size: 20px;
          float: right;
          cursor: pointer;
        }
      }

      .form-style {
        margin-top: 30px;

        ::v-deep .el-input {
          width: 485px;
          height: 62px;
          background: #efefef;
          border-radius: 8px;
          font-size: 22px;

          .el-input__inner {
            height: 62px;
            line-height: 62px;
            background-color: transparent;
            padding-left: 38px;
          }
        }
      }

      .new-style {
        ::v-deep .el-input {
          .el-input__inner {
            padding-left: 18px;
          }
        }
      }

      .bottom-text {
        margin-top: 20px;
        color: #3888e9;
        font-size: 24px;
        cursor: pointer;
      }

      .btn {
        margin-top: 30px;
        text-align: center;
        font-size: 24px;

        ::v-deep .el-button {
          width: 180px;
          height: 50px;
          border-radius: 8px;
          font-size: 24px;
        }

        .btn1 {
          border: 2px solid #cccccc;
        }
      }

      .tips {
        font-weight: 400;
        color: #cccccc;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
      }
    }

    .codebtn {
      color: #cccccc;
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 22px;
    }

    .active {
      color: #3888e9;
      cursor: pointer;
    }
  }
}
</style>
