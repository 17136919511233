<template>
  <div class="box-container">
    <div class="box-input">
      <div class="box-input-left">
        <el-input v-model="formInfo.title" clearable style="width: 220px;" placeholder="请输入标题"></el-input>
      </div>

      <div class="box-input-btn">
        <el-button class="default" @click="searchFn">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="resetFn">重置</el-button>
        <el-button class="box-input-btn-cz" @click="editFn()">添加</el-button>
      </div>

    </div>

    <div class="box-table">
      <el-table :data="tableList" :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }" ref="refTable" style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'" v-loading="formInfo.loading" :cell-style="{ 'text-align': 'center' }">

        <el-table-column prop="title" label="标题">
        </el-table-column>
        <el-table-column prop="content" label="内容"  min-width="130px">
        </el-table-column>
        <el-table-column prop="images" label="示例图" align="left" min-width="130px">
          <template slot-scope="scope">
            <div v-if="scope.row.images && scope.row.images.length" style="display: inline-flex;">
              <el-image style="width: 30px; height: 30px;margin: 0 5px;" :src="item" :preview-src-list="scope.row.images" :initial-index="index" v-for="(item, index) in scope.row.images" :key="index"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="buttonList" label="按钮"  align="left"  min-width="130px">
          <template slot-scope="scope">
            <div v-if="scope.row.buttonList && scope.row.buttonList.length" style="display: inline-flex;transform: scale(.5);">
              <el-tooltip :content="item.page" v-for="(item, index) in scope.row.buttonList" :key="index">
                <el-button :type="item.type" >{{ item.text }}</el-button>   
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" width="60px">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="edit-wrap">
              <div class="edit-item">
                <span class="lan" @click="editFn(scope.row)">编辑</span>
                <span class="del" v-if="!scope.row.isDefault" @click="delFn(scope.row.id)">删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="box-table-pagination">
      <el-pagination :current-page="formInfo.pageIndex" :page-size="formInfo.pageSize" @current-change="currentSizeChange($event, formInfo.pageSize)" @size-change="currentSizeChange(formInfo.pageIndex, $event)" layout="total, prev, pager, next, jumper" :total="formInfo.total">
      </el-pagination>
    </div>

    <EditPage :visible="isEdit" :row="editRow" @refresh="getListFn" @close="isEdit = false"></EditPage>
  </div>
</template>

<script>
import { commonproblemquery, commonproblemdelete } from '@/api/settings.js';
import EditPage from "./components/Edit.vue";

export default {
  components: { EditPage },
  data() {
    return {
      formInfo: {
        title: '',
        pageIndex: 1,
        pageSize: 10,
        total: 0,

        loading: false,
      },
      isEdit: false,
      editRow: {},
      tableList: []
    }
  },
  mounted() {
    this.getListFn();
  },
  methods: {
    getListFn() {
      this.formInfo.loading = true;

      commonproblemquery({
        title: this.formInfo.title,
        'Page.Size': this.formInfo.pageSize,
        'Page.Index': this.formInfo.pageIndex,
      }).then(res => {
        this.tableList = res.data.rows.map(item => {
          try {
            if(item.images) {
              item.images = JSON.parse(item.images)
            } else {
              item.images = []
            }
          } catch (error) {
            item.images = []
          }
          try {
            if(item.buttons) {
              item.buttonList = JSON.parse(item.buttons)
            } else {
              item.buttonList = []
            }
          } catch (error) {
            item.buttonList = []
          }
          return item
        });
        this.formInfo.total = res.data.total
        this.formInfo.loading = false;
      })
    },
    searchFn() {
      this.formInfo.pageIndex = 1
      this.getListFn()
    },
    resetFn() {
      this.formInfo = {
        title: '',
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        loading: false,
      }
      this.getListFn();
    },

    currentSizeChange(current, size) {
      this.formInfo.pageIndex = current
      this.formInfo.pageSize = size
      this.getListFn()

      this.$refs.refTable.bodyWrapper.scrollTop = 0
    },
    editFn(row = {}) {
      this.editRow = row;
      this.isEdit = true;
    },

    delFn(id) {
      let that = this
      that.$confirm(`确定删除该数据吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        commonproblemdelete({ id }).then(res => { 
          console.log(res);
          
          if (res.code == 1) {
            that.$message.success('删除成功')
            that.searchFn() 
          }
        }).catch((res) => {
          console.log(res);
          that.$message.error('删除失败')
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-container {
  .box-input {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep .el-input__inner {
      background: #f2f4f7;
      border: none;
    }

    ::v-deep .el-select,
    .el-input {
      margin-right: 8px;
    }

    .box-input-btn {
      text-align: right;

      .box-input-btn-cz {
        background: #eaf3ff;
        color: #3888e9;
        border: none;
      }

      .box-input-btn-add {
        background: #d8f5e4;
        color: #54d8a5;
        border: none;
      }
    }
  }
  .box-table {
    .edit-wrap {
      .edit-item {
        // padding-left: 20px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          cursor: pointer;
          display: inline-block;
          margin: 0 2px;

          &.lan {
            color: #3888e9;
          }

          &.copy {
            color: #9ea09e;
          }

          &.del {
            color: #e41313;
          }
        }
      }
    }
  }

  .box-table-pagination {
    text-align: center;
    margin-top: 16px;
  }
}
</style>