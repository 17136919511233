<template>
  <div class="page system">
    <div class="system-wrap">
      <div class="notices-btn">
        <div class="notices-btn-flex" @click="oepnNoti(true)">
          <i class="iconfont icon-yidu-01"></i>
          <span>全部设为已读</span>
        </div>
        <div class="notices-btn-flex" @click="oepnNoti(false)">
          <i class="el-icon-delete"></i>
          <span>删除全部已读</span>
        </div>
      </div>

      <el-tabs v-model="activeType" @tab-click="handleClick">
        <el-tab-pane
          :label="item.label"
          :name="String(item.value)"
          v-for="(item, index) in typeList"
          :key="item.value"
        >
          <div class="notices-list">
            <div class="notices-item" v-for="(elem, idx) in notices" :key="idx" @click="showNoticeDialog(elem)">
              <div class="notices-icon">
                <i class="iconfont" :class="setStatus(elem.type)"></i>
                <div class="notices-ds" v-if="!elem.isRead"></div>
              </div>
              <div class="notices-right" :class="{ active: elem.isRead }">
                <div class="notices-right-top">
                  <span>{{ elem.typeName }}</span>
                  <span>{{ elem.createdTime }}</span>
                </div>
                <div class="notices-right-bom">
                  <span>【{{ elem.title }}】{{ elem.detail }}</span>
                </div>
              </div>
            </div>

            <div class="table-pagination">
              <el-pagination
                @current-change="addressCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                layout="total, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <el-dialog
      :title="notice.title"
      :visible.sync="showNotice">
      <span>{{ notice.detail }}</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  noticetypeselect,
  querynotices,
  noticeread,
  noticereaddel,
  readNotice,
  addNotice
} from "@/api/system";

export default {
  inject: ["reloadNotice"],
  data() {
    return {
      typeList: [],
      notices: [],
      activeType: '9999',
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      notice: {},
      showNotice: false,
    };
  },
  async mounted() {
    this.typeList = [{ label: '全部通知', value: '9999', disabled: false, data: null }, ...(await noticetypeselect({})).data];
    this.querynoticesFn();
  },
  computed: {
    setStatus() {
      return function (type) {
        if (type == 0) {
          return "icon-a-zu541";
        } else if (type == 1) {
          return "icon-a-zu540";
        } else {
          return "icon-youhuiquan";
        }
      };
    },
  },
  methods: {
    async showNoticeDialog(elem){
      this.notice = elem;
      this.showNotice = true; 
      if(!elem.isRead) {
        elem.isRead = true;
        await readNotice({userNoticeId: elem.id});
        await this.reloadNotice();
      }
    },
    handleClick(tab, event) {
      this.querynoticesFn();
      this.$forceUpdate();
    },
    addressCurrentChange(e) {
      this.pageIndex = e;
      this.querynoticesFn();
    },
    oepnNoti(bol) {
      let vm = this;

      if (bol) {
        vm.$confirm(`是否全部设为已读`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            noticeread({}).then((res) => {
              vm.$message({
                message: "设为已读成功！",
                type: "success",
              });
              this.querynoticesFn();
              this.reloadNotice();
            });
          })
          .catch((res) => {});
      } else {
        vm.$confirm(`是否删除全部已读`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            noticereaddel({}).then((res) => {
              vm.$message({
                message: "删除成功！",
                type: "success",
              });
              this.querynoticesFn();
            });
          })
          .catch((res) => {});
      }
    },

    querynoticesFn() {
      querynotices({
        "Page.Index": this.pageIndex,
        "Page.Size": this.pageSize,
        Type: this.activeType === '9999'? null: this.activeType,
      }).then((res) => {
        this.notices = res.data.row;
        this.total = res.data.totalCount;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tabs__item {
  color: red !important;
}

.system {
  min-height: 800px;

  .table-pagination {
    text-align: center;
    margin-top: 16px;
  }
  
@media (min-width:1280px) {
    .system-wrap  {
      width: 80vw !important;
    }
}
@media (max-width: 1280px) {
    .system-wrap  {
      width: 1200px  !important;
    }
}

.system-wrap {
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    margin-top: 20px;
    position: relative;
    .notices-btn {
      position: absolute;
      right: 40px;
      top: 20px;
      display: flex;
      align-items: center;
      z-index: 2;
      .notices-btn-flex {
        width: 116px;
        height: 29px;
        border-radius: 4px;
        border: 1px solid #cccccc;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 16px;
        font-size: 12px;
        color: #666666;
        cursor: pointer;

        i {
          margin-right: 9px;
        }
      }
    }
    .notices-list {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      padding-bottom: 40px;

      .notices-item {
        border-bottom: 1px solid #ececec;
        padding: 20px 0;
        display: flex;

        .notices-icon {
          width: 44px;
          height: 44px;
          border-radius: 8px;
          box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.11);
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .notices-ds {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #e41313;
            right: -5px;
            top: -5px;
          }

          .icon-a-zu541 {
            // background: ;
            color: #5d9be8;
          }

          .icon-a-zu540 {
            color: #f6a758;
          }

          .icon-youhuiquan {
            color: #fd6565;
          }
        }

        .notices-right {
          margin-left: 24px;
          color: #333;

          .notices-right-top {
            span {
              &:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
              }

              &:nth-child(2) {
                font-size: 20px;
                margin-left: 16px;
              }
            }

            
          }

          .notices-right-bom {
            margin-top: 10px;
            font-size: 18px;
          }

          &.active {
            color: #999;
          }
        }
      }
    }
  }
}
</style>
