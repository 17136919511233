import Vue from 'vue'


const MyDirectives = {}

export default MyDirectives.install = function (vue, options) {

  // 防止el-button重复点击
  Vue.directive('preventReClick', {
    bind(el, binding) {
      el.addEventListener('click', () => {
        if (!el.disabled) {
          el.disabled = true
          setTimeout(() => {
            el.disabled = false
          }, binding.value || 1500)
        }
      })
    }
  })

  Vue.directive('clickOutside', {
    bind(el, binding, vnode) {
      function documentHandler(e) {
        if (el.contains(e.target)) {
          return false;
        }
        if (binding.expression) {
          binding.value(e);
        }
      }
      el.__vueClickOutside__ = documentHandler;
      document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
      document.removeEventListener('click', el.__vueClickOutside__);
      delete el.__vueClickOutside__;
    }
  });

  Vue.directive('selectLoadMore', {
    // bind(el, binding) {
    //     // 获取滚动页面DOM
    //     let SCROLL_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
    //     let scrollPosition = 0
    //     SCROLL_DOM.addEventListener('scroll', function () {
    //         // 当前的滚动位置 减去  上一次的滚动位置
    //         // 如果为true则代表向上滚动，false代表向下滚动
    //         let flagToDirection = this.scrollTop - scrollPosition > 0
    //         // console.log(flagToDirection ? '滚动方向：下' : '滚动方向：上')

    //         // 记录当前的滚动位置
    //         scrollPosition = this.scrollTop

    //         // 记录滚动位置距离底部的位置,在滚动位置距离滚动页面底部一定高度时在触发，例如距页面底部只有100px时触发handleScroll事件
    //         const LIMIT_BOTTOM = 10
    //         let scrollBottom = this.scrollHeight - (this.scrollTop + this.clientHeight) < LIMIT_BOTTOM
    //         // 如果已达到指定位置则触发
    //         // 如果向下滚动 并且距离底部只有10px
    //         if (flagToDirection && scrollBottom) {
    //             // 将滚动行为告诉组件
    //             binding.value(flagToDirection)
    //         }
    //         // 如果是向上滚动  并且距离顶部只有100px
    //         if (!flagToDirection && this.scrollTop < LIMIT_BOTTOM) {
    //             binding.value(flagToDirection)
    //         }

    //          /*
    //           * 处理滚动行为
    //           * param: 滚动行为
    //           *  true 向下滚动
    //           *  false 向上滚动
    //           */
    //           // console.log(param)
    //           // 此处判断 !this.queryKeyword 是防止在搜索关键词的结果上追加数据
    //           // 在后台判断关键词长度，至少要输入2个关键字，才可搜索
    //           // 否则，还是会出现查询时间长的情况
    //     })
    // }
    bind (el, binding) {
      // 获取选择器下的滚动框DOM
      const SELECTWRAP_DOM = el.querySelector(
          `.el-select-dropdown__wrap`
      ); 
      // 如果找到了滚动框DOM，则添加滚动事件监听器
      if (SELECTWRAP_DOM) {
          SELECTWRAP_DOM.addEventListener("scroll", function () {
              // 如果滚动到底部，则调用传入的回调函数
              const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
              if (condition) {
                  binding.value();
              }
          });
      }
  }
    
  })

}