<template>
    <div>

        <el-dialog title="" :close-on-click-modal="false" center :visible.sync="isVisible" width="400px"
            :before-close="handleClose" class="dialog-login-wrap">
            <div class="login-wrap">

                <div class="close-out" @click="handleClose">
                    <i class="el-icon-close"></i>
                </div>

                <div class="login-logo">
                    <img :src="require('@/assets/images/common/loginLogo.png')" alt="">
                </div>

                <div v-if="!ewmShow">

                    <div class="login-title">登录账号</div>


                    <div class="login-text" :style="{ opacity: toShow ? 0 : 1 }">
                        验证码已通过短信的方式发送至{{ user.phone }}
                        <span class="login-back" @click="phoneBack">返回修改</span>
                    </div>


                    <div class="login-content" v-if="toShow">
                        <el-input class="login-input" v-model="user.phone" placeholder="请输入手机号"></el-input>
                    </div>
                    <el-input class="login-input" v-else placeholder="请输入验证码" v-model="user.cmd">
                        <div slot="append" class="login-cmd" style="cursor: pointer;color: #3888E9;" @click="getVerificationCode"
                            v-if="canSendCodeAgain">重新获取</div>
                        <div slot="append" class="login-cmd" v-else>{{ num }}s</div>
                    </el-input>

                    <el-button type="primary" class="login-btn" v-if="toShow" @click="getVerificationCode">发送验证码</el-button>
                    <el-button type="primary" class="login-btn" v-else @click="onLogin">登录</el-button>
                </div>


                <div v-else>
                    <div class="back-login" @click="phoneBack">
                        <i class="el-icon-arrow-left"></i>
                        {{ isRegister ? '返回登录' : '更换账户登录' }}
                    </div>
                    <div class="ewm-wrap">

                        <div class="ewm-box">
                            <img :src="ewmImg" alt="">

                        </div>
                        <!-- <p class="ewm-text">扫描二维码进入小程序注册账号</p> -->
                        <p class="ewm-text">{{ isRegister ? '扫描二维码进入小程序注册账号' : '首次登录需要扫描二维码绑定账户' }} </p>

                    </div>
                </div>

            </div>

            <div class="login-bom" v-if="!ewmShow">
                <p class="login-bom-1">登录即同意<span @click="openAgreement('userAgreement')">《用户协议》</span>和<span
                        @click="openAgreement('privacyPolicy')">《隐私政策》</span></p>
                <p class="login-bom-2" @click="zcFn">前往注册<i class="el-icon-arrow-right"></i></p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { login, sendsms, getorginfo, merchatlogin, wechatshareqrcode, querybingaccountstatus } from '@/api/login.js'
export default {
    inject: ['pageReload'],
    name: 'login',
    data() {
        return {
            user: {
                phone: '',
                pwd: '',
                cmd: ''
            },
            canSendCodeAgain: true,
            countDownTimer: null,
            num: 60,
            isCmd: true,

            isVisible: this.config,
            timetwo: null,
            toShow: true,
            ewmShow: false,
            orginfo: {},
            ewmImg: '',
            // isRegister: false,
        }
    },
    props: {
        config: {
            type: Boolean,
            default: false,
        },
        isRegister: {
            type: Boolean,
            default: false
        }
    },

    mounted() {
        this.getorginfoFn();
        console.log(this.isRegister, 'isRegister');

    },
    methods: {
        getorginfoFn() {
            getorginfo({
            }).then(res => {
                console.log(res);

                this.orginfo = res.data

                if (this.isRegister) {
                    this.zcFn()
                }
            })
        },
        handleClose() {
            // this.dialogVisible = false;   
            // 清空数据
            // this.ruleForm.checkItem = ''
            // 传递关闭事件给父组件 
            this.$emit('close')
        },
        zcFn() {
            this.ewmShow = true;
            this.isRegister = true

            console.log(11);
            wechatshareqrcode({
                appId: this.orginfo.wechatApp.appId,
                path: `pages/user/merchant/register`,
            }).then(res => {
                console.log(res);
                this.ewmImg = res.data;
                console.log(22);
            })
        },
        onLogin() {
            if(!this.user.phone || this.user.phone.match(/1\d{10}/g) === null) {
                this.$message({ message: '请输入正确的手机号', type: 'warning' });
                return;
            }

            if (!this.user.cmd) {
                this.$message({ message: '请输入验证码', type: 'warning' });
                return;
            }

            this.user.AppId = 'wxe9eafc3d6973fcdd';

            this.$store.dispatch("user/login", {
                account: this.user.phone,
                smsCode: this.user.cmd,
                AppId: 'wxe9eafc3d6973fcdd',
            }).then(res => {
                console.log(res);
                if (res.code == 0) {
                    let msg = JSON.parse(res.msg);
                    if (res.msg != 'success' && msg.msg!='该商户账号为首次登录,请使用微信扫码绑定') {
                        this.$message({
                            message: res.msg,
                            type: 'warning'
                        });
                        return;
                    }
                    this.ewmShow = true;
                    wechatshareqrcode({
                        appId: this.orginfo.wechatApp.appId,
                        path: `pages/user/merchant/bind_account?type=${msg.type}&accountId=${msg.accountId}&phone=${this.user.phone}`,
                        orgId: msg.accountId
                    }).then(res => {
                        console.log(res);
                        this.ewmImg = res.data;

                        this.timetwo = setInterval(() => {
                            querybingaccountstatus({
                                type: msg.type,
                                accountId: msg.accountId,
                            }).then(res => {

                                if (res.data == '绑定成功') {
                                    clearInterval(this.timetwo)
                                    this.onLogin();
                                }
                            })
                        }, 1000)


                    })
                } else if (res.code == 1) {
                    console.log(111);
                    // this.$store.commit('SET_TOKEN', res.data.accessToken);
                    // this.$router.push({ name: 'home' })
                    this.pageReload()
                    this.isVisible = false;
                }
            });
        },
        checkCmd() {
            this.user.pwd = '';
            this.user.cmd = '';
            this.isCmd = !this.isCmd;
        },

        phoneBack() {
            this.toShow = true;
            this.canSendCodeAgain = true
            this.ewmShow = false;
            this.num = 60;

            clearInterval(this.timetwo)
        },

        getVerificationCode() {
            if(!this.user.phone || this.user.phone.match(/1\d{10}/g) === null) {
                this.$message({ message: '请输入正确的手机号', type: 'warning' });
                return;
            }

            if (this.canSendCodeAgain) {
                clearInterval(this.countDownTimer)
                sendsms({
                    phone: this.user.phone
                }).then(res => {
                    console.log(res);
                })

                this.toShow = false;
                this.canSendCodeAgain = false;
                this.isRegister = false
                this.user.cmd = ''

                this.$message({
                    message: '发送成功',
                    type: 'success'
                });
                this.countDownTimer = setInterval(() => {
                    if (this.num == 1) {
                        clearInterval(this.countDownTimer)
                        this.canSendCodeAgain = true;
                        this.num = 60
                    }
                    this.num--;
                }, 1000)
            }
        },
        openAgreement(key) {
            // window.open(`/agreement`)
            if (key == 'userAgreement') {

                window.open('/agreement/userAgreement', '_blank');
            } else {
                window.open('/agreement/privacyPolicy', '_blank');
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.back-login {
    font-size: 20px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;

    i {
        font-weight: bold;
    }
}

.ewm-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;

    .ewm-box {
        width: 250px;
        height: 250px;
        border-radius: 8px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .ewm-text {
        font-size: 16px;
        margin-top: 28px;
        font-weight: bold;
    }
}

.login-wrap {
    position: relative;

    .close-out {
        position: absolute;
        right: 0;
        top: -20px;
        font-size: 20px;
        cursor: pointer;
    }

    .login-logo {
        width: 130px;
        height: 28px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .login-title {
        font-size: 24px;
        margin-top: 40px;
        font-weight: bold;

    }

    .login-text {
        margin-top: 20px;
        margin-bottom: 24px;
        // text-align: center;
        color: #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        .login-back {
            color: #3888E9;
            cursor: pointer;
        }
    }

    .login-btn {
        width: 100%;
        height: 53px;
        background: #3888E9;
        border-radius: 8px 8px 8px 8px;
        margin-top: 32px;
    }

    .login-content {}


}
</style>

<style lang="scss">
.login-input {
    .el-input__inner {
        height: 50px !important;
        background: #F6F6F6;
        border-radius: 8px;
        border: none;
    }

    .el-input-group__append {
        border: none;
        background: #F6F6F6;
    }
}

.dialog-login-wrap {



    .el-dialog {
        background: none;
    }

    overflow: hidden;


    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 400px;
        height: 540px;
        background: linear-gradient(180deg, #E8EFFF 0%, #FFFFFF 20%);
        border-radius: 8px 8px 8px 8px;
        box-sizing: border-box;
        position: relative;
        padding: 34px 20px;
        flex: 1;

        .login-bom {
            display: flex;
            position: absolute;
            bottom: 13px;
            width: 360px;
            display: flex;
            justify-content: space-between;

            .login-bom-1 {
                font-weight: bold;

                span {
                    color: #3888E9;
                    cursor: pointer;
                }
            }

            .login-bom-2 {
                color: #3888E9;
                font-weight: bold;
                cursor: pointer;

                .el-icon-arrow-right {
                    font-weight: bold;
                }
            }
        }
    }
}
</style>