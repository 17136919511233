<template>
  <div>
    <el-dialog title="批量下载" :close-on-click-modal="false" center :visible.sync="dowmShow" width="761px" class="dialog-wrap-dowm-nom">
      <div class="dialog-member-title">
        <span class="dialog-member-name">批量下载</span>
        <div class="dialog-close" @click="handleClose">
          <i class="el-icon-close"></i>
        </div>
      </div>

      <div class="dialog-nom-list" v-loading="uploadLoading || pageLoading">
        <div class="dialog-nom-list-left">
          <el-table :data="downList.data" ref="multipleTable" :header-cell-style="{
            backgroundColor: 'none',
            color: '#333',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '14px',
          }" height="470px" style="width: 390px" :cell-style="{ 'text-align': 'center' }" row-key="id" @selection-change="getUser">
            <el-table-column type="selection" width="55" :selectable="selectableFn"> </el-table-column>
            <el-table-column prop="applicantName" label="姓名">
            </el-table-column>
            <el-table-column prop="statusName" label="申请状态">
              <template slot-scope="scope">
                <div class="flex-app">
                  <div :style="setTopClass(scope.row.status)">
                    {{ scope.row.statusName }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="table-pagination">
            <el-pagination @current-change="downCurrentChange" :current-page="downList.pageIndex" @size-change="downSizeChange" :page-size="downList.pageSize" :page-sizes="[10, 20, 50, 100]" layout="total, sizes,prev, pager, next, jumper" :pager-count="5" small :total="downList.total">
            </el-pagination>
          </div>
        </div>
        <div class="dialog-nom-list-right">
          <div class="dialog-nom-list-right-wrap">
            <div class="dialog-nom-list-right-title">
              <p>选择下载内容</p>
              <p>
                共选择<span>{{ userListAllTable.length }}本</span>签证
              </p>
            </div>
            <div class="dialog-nom-list-right-yello">
              <i class="el-icon-warning"></i>
              <span style="margin-left: 5px">若选择的申请人无相关资料，下载内容为空</span>
            </div>
            <div class="dialog-nom-select-up">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                  city
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>

      <div class="packge-btn-add" style="margin-top: 15px">
        <el-button class="pageck-btn" @click="handleClose">取消</el-button>
        <el-button v-preventReClick :disabled="uploadLoading" :loading="uploadLoading" class="pageck-btn default" type="primary" @click="submitForm">确认</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="downTitle" :close-on-click-modal="false" center :visible.sync="visible" width="425px" class="dialog-wrap-dowm-nom">
      <div class="dialog-member-title">
        <div class="dialog-member-name">
          {{ downTitle }}
          <span style="font-size: 12px">
            已选择<span style="color: #0094e8">{{
              userListAllTable.length
            }}</span>本</span>
        </div>
        <div class="dialog-close" @click="handleClose">
          <i class="el-icon-close"></i>
        </div>
      </div>

      <div class="dialog-nom-list" v-loading="submitLoading || pageLoading">
        <div class="dialog-nom-list-left">
          <el-table :data="downList.data" ref="multipleTable" :header-cell-style="{
            backgroundColor: 'none',
            color: '#333',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '14px',
          }" height="470px" style="width: 390px" :cell-style="{ 'text-align': 'center' }" row-key="id" @selection-change="getUser">
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="applicantName" label="姓名">
            </el-table-column>
            <el-table-column prop="statusName" label="申请状态">
              <template slot-scope="scope">
                <div class="flex-app">
                  <div :style="setTopClass(scope.row.status)">
                    {{ scope.row.statusName }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div class="table-pagination">

                        <el-pagination @current-change="addressCurrentChange" :current-page="downList.pageIndex"
                            :page-size="downList.pageSize" layout="total, prev, pager, next, jumper" :pager-count="5" small
                            :total="downList.total">
                        </el-pagination>
                    </div> -->
        </div>
        <!-- <div class="dialog-nom-list-right">
                    <div class="dialog-nom-list-right-wrap">

                        <div class="dialog-nom-list-right-title">
                            <p>选择下载内容</p>
                            <p>共选择<span>15本</span>签证</p>
                        </div>
                        <div class="dialog-nom-list-right-yello">
                            <i class="el-icon-warning"></i>
                            <span style="margin-left: 5px;">若选择的申请人无相关资料，下载内容为空</span>
                        </div>
                        <div class="dialog-nom-select-up">
                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                @change="handleCheckAllChange">全选</el-checkbox>
                            <div style="margin: 15px 0;"></div>
                            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                            </el-checkbox-group>

                        </div>
                    </div>
                </div> -->
      </div>

      <div class="packge-btn-add" style="margin-top: 15px">
        <el-button class="pageck-btn" @click="handleClose">取消</el-button>
        <el-button v-preventReClick :disabled="submitLoading" :loading="submitLoading" class="pageck-btn default" type="primary" @click="confirmSubmit">确认</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { sendemailvisa } from "@/api/product.js";
import { queryorderapplicants, batchcancelvfsorder, downloadapplicantsdata } from "@/api/order.js";
const cityOptions = ["收据页", "签证文件", "拒签信"];

export default {
  props: {
    dowmShow: {
      type: Boolean,
      default: false
    },
    visible: {
      type: Boolean,
      default: false
    },
    downTitle: {
      type: String,
      default: '批量'
    },
    orderId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      pageLoading: false,
      uploadLoading: false,
      submitLoading: false,
      userListAllTable: [],
      applicantstatusTimer: '',


      emailInfo: {
        visible: false,
        email: null,
      },

      checkAll: true,
      isIndeterminate: false,
      cities: cityOptions,
      checkedCities: cityOptions,
      downList: {
        pageIndex: 1,
        pageSize: 10,
        total: 0,
        data: [],
        title: ''
      },
    }
  },
  computed: {
    setTopClass() {
      return function (identityType) {
        if ([0, 2, 14, 6, 11, 18].indexOf(identityType) >= 0) {
          return "color:#eebb17"; //黄色
        } else if ([8, 10, 12, 16].indexOf(identityType) >= 0) {
          return "color:#1c9c1c"; //绿色
        } else {
          return "color:#e41313"; //红色
        }
      };
    },
  },
  methods: {
    handleClose() {
      this.downList.pageIndex = 1;
      this.downList.pageSize = 10;
      this.downList.data = [];
      this.$emit('close')
    },
    getUser(e) {
      this.userListAllTable = e;
    },

    selectableFn(row) {
      if(row.status == 16) {
        return true
      } else {
        return false
      }
    },
    getRowKeys(val) {
      return val.id || "";
    },
    // 批量列表
    getQueryorderapplicants(downList) {
      this.downList = {...downList}
      this.pageLoading = true;
      let vm = this;
      queryorderapplicants({
        OrderId: downList.orderId,
        "Page.Size": downList.pageSize,
        "Page.Index": downList.pageIndex,
        orgQuery: true
      }).then((res) => {
        this.pageLoading = false;
        if (downList && this.downTitle == "批量提交") {
          this.downList.data = res.data.rows.filter((item) => {
            return (item.autoSubmitStatus == -1 || item.autoSubmitStatus == 0) && item.taskTable == 100;
          });
          this.downList.total = this.downList.data.length;
        } else if (downList && this.downTitle == "批量取消") {
          this.downList.data = res.data.rows.filter((item) => {
            return item.status == 10 || item.status == 20;
          });
          this.downList.total = this.downList.data.length;
        } else {
          this.downList.data = res.data.rows;
          this.downList.total = res.data.total;
        }
        this.downList.data = [...this.downList.data]
      }).catch(() => {
        this.pageLoading = false;
      });
    },
    // 点击确认
    confirmSubmit() {
      if (this.userListAllTable.length == 0) {
        this.$message({
          message: "未选择申请人！",
          type: "warning",
        });
        return;
      }

      if (this.downTitle == "批量取消") {
        this.batchCancel()
      } else if (this.downTitle == "发送邮件") {
        this.$prompt('输入邮箱', '发送邮件', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
          inputErrorMessage: '邮箱格式不正确'
        }).then(({ value }) => {
          this.submitSendEmail(value)
        }).catch(() => { });
      } else if (this.downTitle == "批量提交") {
        // 批量提交
        this.$emit('batchSubmit', this.userListAllTable)
      }
    },

    //批量取消
    batchCancel() {
      let arr = [];
      this.userListAllTable.forEach((item) => {
        arr.push(item.id);
      });
      this.submitLoading = true;
      batchcancelvfsorder({ orderApplicantIds: arr }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          this.$message({
            message: "批量取消成功！",
            type: "success",
          });
          this.applicantstatusTimer && clearInterval(this.applicantstatusTimer);

          this.handleClose();
          this.$emit('refresh');
        }
        this.submitLoading = false;
      }).catch(() => { 
        this.submitLoading = false;
      })
    },

    // 发送邮件
    submitSendEmail(email) {

      let arr = [];
      this.userListAllTable.forEach((item) => {
        arr.push(item.id);
      });
      this.submitLoading = true;
      sendemailvisa({ orderApplicantIds: arr, email: email }).then((res) => {
        console.log(res)
        if (res.code == 1) {
          this.$message({
            message: "发送邮件成功！",
            type: "success",
          });
          this.applicantstatusTimer && clearInterval(this.applicantstatusTimer);

          this.handleClose();
          this.$emit('refresh');
        }
        this.submitLoading = false;
      }).catch(() => { 
        this.submitLoading = false;
      })
    },


    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },

    downCurrentChange(e) {
      this.downList.pageIndex = e;

      this.getQueryorderapplicants(this.downList);
    },
    downSizeChange(e) {
      this.downList.pageSize = e;

      this.getQueryorderapplicants(this.downList);
    },
    submitForm() {
      // this.userListAllTable = e;
      if (this.checkedCities.length == 0) {
        this.$message({
          message: "未选择下载内容！",
          type: "warning",
        });
        return;
      }

      if (this.userListAllTable.length == 0) {
        this.$message({
          message: "未选择申请人！",
          type: "warning",
        });
        return;
      }

      let obj = {
        visaPage: false,
        rejectLetter: false,
        bill: false,
        orderApplicantIds: [],
      };

      this.checkedCities.forEach((item) => {
        if (item == "收据页") {
          obj.bill = true;
        }

        if (item == "签证文件") {
          obj.visaPage = true;
        }

        if (item == "拒签信") {
          obj.rejectLetter = true;
        }
      });

      this.userListAllTable.forEach((item) => {
        obj.orderApplicantIds.push(item.id);
      });
      this.uploadLoading = true
      downloadapplicantsdata({
        ...obj,
      }).then((res) => {
        this.uploadLoading = false
        if (res.data == null || res.data == "") {
          this.$message({
            message: "无可下载资料",
            type: "warning",
          });
        } else {
          window.open(res.data);
        }
      }).catch(() => {
        this.uploadLoading = false
      });
    },
  }
}
</script>
 
<style lang="scss">
.dialog-wrap-dowm-nom {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    // width: 761px;

    // height: 285px;
    background: linear-gradient(180deg, #e7ecfb 0%, #f6f8fe 24%, #ffffff 100%);
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
  }
}

.dialog-nom-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .el-table {
    background: none;

    .el-table__row {
      background: none;

      .el-table__cell {
        padding: 6px 0;
      }
    }

    .el-table__header-wrapper {
      height: 36px;
      border-radius: 4px;

      .cell {
        line-height: 36px;
        height: 36px;
        margin-top: -10px;
      }
    }
  }
  .dialog-nom-list-left {
    width: 390px;
  }

  .dialog-nom-list-right {
    .dialog-nom-list-right-wrap {
      width: 295px;

      .dialog-nom-list-right-title {
        box-sizing: border-box;
        background: #fff;
        border-radius: 4px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;

        p {
          font-size: 12px;

          span {
            color: #0094e8;
          }
        }
      }

      .dialog-nom-list-right-yello {
        background: #fdf6ec;
        //  height: 28px;
        padding: 6px 12px;
        color: #f9c26f;
        box-sizing: border-box;
        font-size: 12px;
        margin-top: 8px;
      }

      .dialog-nom-select-up {
        margin-top: 15px;
      }
    }
  }
}
</style>