<template>
    <div class='info-wrap'>
        <div class="info-header">
            <i class="el-icon-arrow-left" style="font-size: 16px;cursor: pointer;" @click="goBack"></i>
            <span class="header-title">{{ formData.id ? "编辑文章" : "添加文章" }}</span>
        </div>
        <el-form :inline="true" label-position="top" :rules="rules" :model="formData" class="info-form" ref="ruleForm">
            <el-form-item label="封面图">
                <upload v-model="formData.img" accept="img" :auto-upload="true" :image-url="formData.img" :drag="'drag'"
                    thisType="custom" @input="toImg($event, 'img')" :customSty="{ width: '375px', height: '224px' }">
                </upload>
            </el-form-item>
            <el-form-item label="标题" prop="title">
                <el-input v-model="formData.title" placeholder="请输入文章标题" style="width:512px"></el-input>
                <p class="isShow">是否展示</p>
                <el-switch v-model="formData.enable" active-color="#3888E9" inactive-color="#CCCCCC" :width="67">
                </el-switch>
                <div class="specs">
                    <p>图片尺寸规格</p>
                    <p>375x224px</p>
                </div>
            </el-form-item>
            <el-form-item label="作者" prop="author">
                <el-input v-model="formData.author" placeholder="请输入作者" style="width:376px;"></el-input>
            </el-form-item>
            <el-form-item label="地区" prop="address">
                <el-input v-model="formData.address" placeholder="请输入所在地区" style="width:376px;"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="sort">
                <el-input v-model="formData.sort" placeholder="请输入排序" style="width:376px;"></el-input>
            </el-form-item>
            <el-form-item label="软文类型">
                <el-select v-model="formData.type" placeholder="请选择软文类型">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="软文地址" v-if="formData.type == 1">
                <el-input v-model="formData.link" placeholder="请输入软文地址" style="width:376px;"></el-input>
            </el-form-item>
            <div class="edit" style="border: 1px solid #D2D2D2;" v-loading="loading" element-loading-text="正在上传中..."
                element-loading-background="rgba(0, 0, 0, 0.8)" v-if="formData.type == 0">
                <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"
                    :mode="mode" />
                <Editor style="height: 500px; overflow-y: hidden;" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                    @onCreated="onCreated" @onChange="getHtml" />
            </div>
        </el-form>
        <div class="info-box">
            <div class="footer">
                <el-button class="btn" :loading="btnLoading" style="background-color: #3888E9;color: #fff;"
                    @click="submit">保存</el-button>
                <el-button class="btn" @click="goBack">取消</el-button>
            </div>
        </div>
    </div>
</template> 

<script>
import { newstypeselect, addorupdateappnews } from "@/api/settings";
import upload from '@/components/upload.vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import alyoss from "@/utils/alyoss";
export default {
    name: 'editInfo',
    components: { upload, Editor, Toolbar },
    props: {
        infoFrom: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            formData: { img: "", title: "", author: "", address: "", sort: 0, type: 0, content: "", link: "", enable: false },
            rules: {
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ],
                author: [
                    { required: true, message: '请输入作者', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请输入地区', trigger: 'blur' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            },
            options: [],
            loading: false,
            btnLoading: false,
            editor: null,
            html: '',
            toolbarConfig: {
                "toolbarKeys": ["fontSize", "fontFamily", "bold", "underline", "through", "code", "sup", "sub", "clearStyle", "color",
                    "bgColor", "lineHeight", "bulletedList", "numberedList", "justifyLeft", "justifyRight", "justifyCenter", "justifyJustify", "emotion", "indent",
                    "delIndent", "todo", "uploadImage", "insertImage", "deleteImage", "uploadVideo", "insertVideo", "editVideoSize", "divider", "undo", "redo", "fullScreen"]
            },
            editorConfig: {
                placeholder: '请输入内容...',
                MENU_CONF: {
                    uploadImage: {
                        customUpload: async (file, insertFn) => {//注意this指向
                            let that = this;
                            try {
                                that.loading = true;
                                let alyossData = await alyoss(file);
                                let alt = file.name;
                                that.loading = false;
                                insertFn(alyossData, alt)
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    },
                    uploadVideo: {
                        customUpload: async (file, insertFn) => {
                            let that = this;
                            try {
                                that.loading = true;
                                let alyossData = await alyoss(file);
                                that.loading = false;
                                insertFn(alyossData)
                            } catch (error) {
                                console.log(error);
                            }
                        }
                    },
                    insertVideo: {
                        //检测插入视频的链接
                        checkVideo: (url) => {
                            const validExtensions = ['mp4', 'ogg', 'webm'];
                            const videoLinkParts = url.split('.');
                            const bool = validExtensions.includes(videoLinkParts[videoLinkParts.length - 1]);
                            if (bool) {
                                return true;
                            } else {
                                this.$message({
                                    message: '请上传正确的视频链接',
                                    type: 'warning'
                                });
                                return
                            }
                        }
                    }
                }
            },
            mode: 'default', // or 'simple'
        };
    },
    computed: {},
    watch: {},
    methods: {
        getSelect() {
            newstypeselect().then((res) => {
                if (res.code == 1) {
                    this.options = res.data;
                }
            })
        },
        toImg(e, type) {
            this.formData[type] = e
        },
        //编辑器内容变化的回调
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        getHtml(editor) {
            this.html = editor.getHtml()
        },
        goBack() {
            this.$emit('goBack')
        },
        submit() {
            if (!this.formData.img) return this.$message.warning('请上传封面图')
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    this.formData.id = this.formData.id || 0;
                    this.formData.content = this.html;
                    console.log(this.formData)
                    addorupdateappnews(this.formData).then(() => {
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        })
                        this.btnLoading = false;
                        this.$emit('goBack')
                    }).catch(() => {
                        this.btnLoading = false;
                    })
                }
            })
        }
    },
    created() {
        this.getSelect();
        this.formData = this.infoFrom || this.formData;
        this.formData.type = this.formData.type || 0;
        this.html = this.formData.content;

    },
    mounted() {
    },
    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang='scss' scoped>
.info-wrap {
    width: 100%;
    min-height: 1280px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    box-sizing: border-box;
    z-index: 1;
    padding: 25px 16px 30px 17px;
}

.info-header {
    .header-title {
        font-size: 20px;
        font-weight: bold;
        margin-left: 8px;
    }
}

.info-form {
    margin-top: 28px;

    ::v-deep .el-form-item {
        margin-right: 20px;
    }

    ::v-deep .el-form-item__label {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }

    .isShow {
        font-size: 16px;
        height: 22px;
        font-weight: bold;
        color: #333333;
        line-height: 22px;
        margin: 20px 0 12px 0;
    }

    ::v-deep .el-switch__core {
        height: 33px !important;
        border-radius: 33px !important;
    }

    ::v-deep .el-switch__core:after {
        width: 28px !important;
        height: 28px !important;
    }

    ::v-deep .el-switch.is-checked .el-switch__core::after {
        margin-left: -30px !important;
    }

    .specs {
        font-size: 14px;
        font-weight: bold;
        margin-top: 24px;

        p:nth-child(1) {
            height: 20px;
            color: #3888E9;
            line-height: 20px;
            margin-bottom: 12px;
        }

        p:nth-child(2) {
            height: 20px;
            color: #999999;
            line-height: 20px;
            margin-top: 10px;
        }
    }
}

.info-box {
    position: fixed;
    width: 100%;
    height: 70px;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    .footer {
        width: 1200px;
        height: 100%;
        background-color: #fff;
        display: flex;
        justify-content: right;
        align-items: center;
        .btn {
            width: 124px;
            height: 50px;
            font-size: 20px;
            margin-right: 40px;
        }
    }
}
</style>
<!-- 修改富文本工具点击时颜色 -->
<style>
.w-e-textarea-video-container {
    box-shadow: none !important;
}

.w-e-text-container .w-e-scroll .w-e-textarea-video-container {
    background: none !important;
    border: none !important;
}

.w-e-full-screen-container {
    z-index: 2000 !important;
    top: 76px !important;
}
</style>