<template>
  <div>
    <el-dialog
      :visible.sync="visible"
      width="864px"
      class="zl-add-class-dialog"
      :close-on-click-modal="false"
    >
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">导入资料</span>
          <div class="dialog-close" @click="handleClose">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div calss="dialog-zl-wrap">
          <el-form
            ref="ruleForm"
            :model="formInfo"
            :rules="rules"
            label-width="100px"
            class="packge-ruleForm"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品名称：" prop="type">
                  <el-select v-model="formInfo.type" placeholder="请选择">
                    <el-option
                      :label="item.label"
                      :value="item.value"
                      v-for="(item, index) in docTypeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="身份类型：" prop="type">
                  <el-select v-model="formInfo.type" placeholder="请选择">
                    <el-option
                      :label="item.label"
                      :value="item.value"
                      v-for="(item, index) in docTypeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="dialog-zl-btn">
        <el-button class="close2 zl-btn" @click="handleClose">取消</el-button>
        <el-button
          class="default zl-btn"
          :loading="submitLoading"
          :disabled="submitLoading"
          @click="handleSubmit"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            formInfo: {},
            docTypeList: [],
            submitLoading: false
        }
    },
    
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        row: {
          type: Object,
          default: () => {}
        }
    },
    methods: {
        
        handleClose() {
            this.$emit('close');
            // this.$refs['ruleForm'].resetFields();
            // this.formInfo = {
            //     name: '',
            //     type: '',
            //     categoryId: '',
            //     isMultiple: false,
            //     sort: 0  ,
            //     desc: '',
            //     legend: ''
            // }
        },
        handleSubmit() {
        //   this.$refs['ruleForm'].validate((valid) => {
        //     if (valid) {
        //       this.submitLoading = true;
        //         adddoc(this.formInfo).then(res => {
        //           if(res.code == 1) {
        //             this.$message.success('操作成功');
        //             this.handleClose();
        //             this.$emit('refresh')
        //           }
        //           this.submitLoading = false;
        //         }).catch(() => {
                  
        //           this.submitLoading = false;
        //         })
        //     } else {
        //       console.log('error submit!!');
        //       return false;
        //     }
        //   });
          
        },
    }
}
</script>


<style lang="scss" scoped>
.zl-add-class-dialog {
  .packge-ruleForm {
    margin-top: 20px;
    box-sizing: border-box;

    .el-form-item {
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      .el-select {
        width: 100%;
      }
    }

    .packge-select {
      width: 135px;
      text-align: left;
    }

    .el-form-item__label {
      width: 50px !important;
      white-space: nowrap;
      height: 30px;
      text-align: left;
    }

    .el-form-item__content {
      margin: 0 auto;
    }
  }
  .dialog-member-wrap {
    .dialog-member-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -10px;

      .dialog-member-name {
        font-size: 18px;
        font-weight: bold;
      }
      .dialog-member-right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .dialog-close {
          margin-left: 100px;
        }
      }

      .dialog-close {
        width: 25px;
        height: 25px;
        background: #e0e0e0;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .el-icon-close {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }
}
</style>