<!-- 提交大使馆 -->
<template>
  <div>
    <!-- 645px @opened="getInfo" -->
    <el-dialog
      title=""
      :close-on-click-modal="false"
      center
      :visible.sync="visible"
      :width="width"
      @open="getorgbalanceFun"
      :before-close="handleClose"
      class="common-dialog-class"
    >
      <div class="dialog-member-title">
        <div class="dialog-member-name">提交大使馆</div>
        <div class="dialog-close" @click="handleClose">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-contenter">
        <div class="card-desc">
          <div>
            当前账户剩余<span class="themeColor">{{ ticketsBalance }}</span
            >点券
          </div>
          <div class="recharge" @click="rechargeShow = true">前往充值</div>
        </div>
        <div class="card-box">
          <div
            class="card-item"
            v-if="info.isPackage"
            :class="{ active: type == 2 }"
            @click="chengeType(2)"
          >
            <div class="card-item-left">
              <img src="@/assets/images/order/packPrice.png" />
            </div>
            <div class="card-item-right">
              <div class="item-right-name">打包提交</div>
              <div class="item-right-desc">包含签证费用和相关手续费</div>
              <div class="item-right-ticket">
                <span>{{ info.packPrice }}</span
                >点券/每人
              </div>
            </div>
            <div v-if="type == 2" class="active-check">
              <i class="el-icon-check" />
            </div>
          </div>
          <div
            class="card-item"
            v-if="info.isTickets"
            :class="{ active: type == 1 }"
            @click="chengeType(1)"
          >
            <div class="card-item-left">
              <img src="@/assets/images/order/tickets.png" />
            </div>
            <div class="card-item-right">
              <div class="item-right-name">普通提交</div>
              <div class="item-right-desc">需要自行支付签证费用</div>
              <div class="item-right-ticket">
                <span>{{ info.tickets }}</span
                >点券
              </div>
            </div>
            <div v-if="type == 1" class="active-check">
              <i class="el-icon-check" />
            </div>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          class="submit-but"
          :disabled="false"
          @click="confirm"
          >立即提交（<span>{{ price }}</span
          >点券）</el-button
        >
        <el-button
          v-if="price > ticketsBalance"
          @click="rechargeShow = true"
          size="small"
          type="text"
          >前往充值<i class="el-icon-arrow-right el-icon--right"></i
        ></el-button>
      </div>
    </el-dialog>
    <recharge
      :visible="rechargeShow"
      :orderId="orderId"
      @close="rechargeShow = false"
      @success="getorgbalanceFun"
    ></recharge>
  </div>
</template>

<script>
import { getsystemvisa, getorgbalance } from "@/api/orderCenter";
import { getLocalStorage, setLocalStorage } from "@/utils/auth";
import recharge from "./recharge";
export default {
  name: "SubmitApplication",
  components: {
    recharge,
  },
  data() {
    return {
      type: 2,
      info: {
        countryId: "",
        name: "德国预约",
        isPackage: false, //是否支持打包提交
        isTickets: false, //是否支持点券提交
        packPrice: 750.0, //打包价
        tickets: 750.0, //普通支付,消耗点券
        typeDesc: "德国预约",
      },
      rechargeShow: false,
      ticketsBalance: "", //点券余额
      width: "645px", //宽度
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String | Number,
      default: "",
    },
    isBatch: {
      type: Boolean,
      default: false,
    },
    length: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    price() {
      let price = 0;
      if (this.isBatch) {
        price =
          this.type == 2
            ? this.length * this.info.packPrice
            : this.length * this.info.tickets;
      } else {
        price = this.type == 2 ? this.info.packPrice : this.info.tickets;
      }
      return price;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getInfo();
    });
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    getInfo() {
      getsystemvisa({ orderId: this.orderId }).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.width =
            this.info.isPackage && this.info.isTickets ? "645px" : "497px";
          this.type =
            (this.info.isPackage && this.info.isTickets) || this.info.isPackage
              ? 2
              : 1;
          // 默认选中上一次提交方式  1：普通支付 2：打包支付
          if (
            getLocalStorage("submit_method") &&
            getLocalStorage("submit_method").countryId == this.info.countryId
          ) {
            this.type = getLocalStorage("submit_method").type;
          }
        }
      });
    },
    // 获取点券余额
    getorgbalanceFun() {
      getorgbalance().then((res) => {
        if (res.code == 1) {
          // SET_TICKETINGVALUE

          this.ticketsBalance = res.data;
          this.$store.commit("SET_TICKETSBALANCE", res.data);
        }
      });
    },
    chengeType(val) {
      //1：普通支付 2：打包支付
      this.type = val;
      setLocalStorage("submit_method", {
        countryId: this.info.countryId,
        type: this.type,
      });
    },
    confirm() {
      if (this.type) {
        this.isBatch
          ? this.$emit("confirmBatch", this.type)
          : this.$emit("confirm", this.type);
        this.handleClose();
      } else {
        this.$message.error("请选择提交方式");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$themeColor: #3888e9;
::v-deep .common-dialog-class {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    // width: 761px;

    // height: 285px;
    background: linear-gradient(180deg, #e7ecfb 0%, #f6f8fe 24%, #ffffff 100%);
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.common-dialog-foot-class {
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .pageck-btn {
    width: 145px;
    margin: 0 16px;
  }
}
.common-dialog-class {
  color: #333333;
  .dialog-contenter {
    margin-top: 12px;
    .themeColor {
      color: $themeColor;
    }
    .card-desc {
      display: inline-flex;
      .recharge {
        margin-left: 25px;
        color: $themeColor;
        cursor: pointer;
      }
    }
    .card-box {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      .card-item + .card-item {
        margin-left: 20px;
      }
      .card-item {
        width: 285px;
        height: 130px;
        background: #ffffff;
        box-shadow: 0px 3px 10px 1px rgba(95, 159, 237, 0.3);
        border-radius: 8px 8px 8px 8px;
        border: 2px solid transparent;
        transition: transform 0.3s;

        display: flex;
        align-items: center;
        //space-around;
        justify-content: space-around;
        position: relative;
        color: $themeColor;
        .card-item-left {
          img {
            width: 90px;
            height: 90px;
          }
        }
        .card-item-right {
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: calc(100% - 40px);
          .item-right-name {
            font-size: 20px;
          }
          .item-right-desc {
            font-size: 12px;
          }
          .item-right-ticket {
            font-size: 14px;
            span {
              color: #f8912c;
            }
          }
        }
      }
      .active {
        border: 2px solid $themeColor;
        overflow: hidden;
        .active-check {
          position: absolute;
          bottom: 0;
          right: 0;

          .el-icon-check {
            font-size: 24px;
            // margin-top: 12px;
            transform: rotate(0deg);
            color: #fff;
          }
        }
        .active-check::before {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          border-top: 45px solid transparent;
          border-right: 45px solid $themeColor;
        }
        .active-check::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          border-top: 45px solid transparent;
          border-right: 45px solid white;
          margin-right: -45px;
          margin-bottom: -45px;
        }
      }
    }
  }
  .dialog-footer {
    width: 100%;
    margin-top: 25px;
    justify-content: center;
    display: inline-flex;
    .submit-but {
      width: 174px;
      height: 46px;
      border-radius: 4px 4px 4px 4px;
    }
    .el-button--primary {
      background-color: $themeColor;
    }
    .el-button--primary.is-disabled {
      background: #cccccc !important;
      border-color: #cccccc !important;
    }
  }
}
</style>
