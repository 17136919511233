<!--  -->
<template>
  <div class="wrap" v-loading="loading">
    <el-card class="box-card" v-for="(item, index) in countryList" :key="item.id">
      <div class="top">
        <img class="bgc" :src="item.nationalFlagImg" />
        <p>{{ item.productName + item.type }}</p>
      </div>
      <div class="center">
        <p v-if="item.systemVisaId == 2" @click="openDialog(item)">支付方式设置</p>
        <p v-if="false">行程设置</p>
        <p v-if="false">基础设置</p>
        <p v-if="item.tutorialUrl" @click="openUrl(item.tutorialUrl)">
          使用说明
        </p>
        <p v-if="false">高危人群告知</p>
        <p v-if="false">送签信息</p>
      </div>
      <div class="bottom">
        <div class="icon-box">
          <div class="icon" @click="isCollected(item, index)">
            <img :src="item.isCollected
              ? require('../../../assets/images/visa/collect-on.png')
              : require('../../../assets/images/visa/collect-off.png')
              " alt="收藏" />
          </div>
        </div>
        <p class="btn" @click="toOrderCenter(item.countryId)">订单列表</p>
        <p class="btn">
          <el-button :loading="item.loading" @click="toOrder(item, index)" style="
              width: 110px;
              height: 38px;
              font-size: 17px;
              border-radius: 4px;
              background-color: #3888e9;
              color: #ffffff;
            ">立即办理</el-button>
        </p>
      </div>
    </el-card>
    <el-dialog :visible.sync="dialogTableVisible" width="60%" class="common-dialog-class">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">银行卡管理</span>
          <div class="dialog-close" @click="dialogTableVisible = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <div>
            <el-button size="small" @click="addBankCard"
              style="background-color: #3888e9;color: #fff;border-radius: 4px;margin-bottom: 16px;">新增银行卡</el-button>
            <el-table :data="bankCardList" :header-cell-style="{
              backgroundColor: '#F2F4F7',
              color: '#333',
              fontWeight: 'bold',
              textAlign: 'center',
              fontSize: '12px',
            }" style="width: 100%" :cell-style="{ 'text-align': 'center' }">
              <el-table-column property="type" label="种类"></el-table-column>
              <el-table-column property="cardNumber" label="卡号"></el-table-column>
              <el-table-column label="持卡人姓名">
                <template slot-scope="scope">
                  <div>{{ scope.row.surName + "" + scope.row.name }}</div>
                </template>
              </el-table-column>
              <el-table-column property="validity" label="有效期">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.validity|format}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column property="cvv" label="CVN/CVC2"></el-table-column>
              <el-table-column property="remarks" label="备注"></el-table-column>
              <el-table-column label="操作" header-align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                  <el-button type="text" size="small" @click="handleDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="addDialog" width="40%" class="common-dialog-class">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">{{ formData.id ? '编辑银行卡' : '添加银行卡' }}</span>
          <div class="dialog-close" @click="addDialog = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <div>
            <el-form label-width="auto" :inline="true" :rules="rules" :model="formData" class="info-form"
              ref="ruleForm">
              <el-form-item label="卡类型" class="radio-item">
                <el-radio-group v-model="formData.type" size="small" class="radio-group" text-color="#3888e9"
                  fill="#3888e9">
                  <el-radio class="radio-group-item" label="002" border><img style="width: 28px;height: 28px;"
                      src="https://file.sport-syy.com/visa/2024/01/09/1704787427007.png" /></el-radio>
                  <el-radio class="radio-group-item" label="001" border><img style="width: 28px;height: 28px;"
                      src="https://file.sport-syy.com/visa/2024/01/09/1704787451136.png" /></el-radio>
                  <el-radio class="radio-group-item" label="007" border><img style="width: 28px;height: 28px;"
                      src="https://file.sport-syy.com/visa/2024/01/09/1704787465874.png" /></el-radio>
                  <el-radio class="radio-group-item" label="003" border><img style="width: 28px;height: 28px;"
                      src="https://file.sport-syy.com/visa/2024/01/09/1704787478079.png" /></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否默认支付卡" >
                <el-switch active-color="#3888e9" v-model="formData.isDefault"></el-switch>
              </el-form-item>
              <el-form-item label="卡号" prop="cardNumber" style="width:100%">
                <el-input v-model="formData.cardNumber" placeholder="请输入卡号" clearable style="width:600px"></el-input>
              </el-form-item>
              <el-form-item label="持卡人姓" prop="surName">
                <el-input v-model="formData.surName" placeholder="请输入姓" @blur="toUpperCase('surName')"
                  clearable></el-input>
              </el-form-item>
              <el-form-item label="持卡人名" prop="name">
                <el-input v-model="formData.name" placeholder="请输入名" @blur="toUpperCase('name')" clearable></el-input>
              </el-form-item>
              <el-form-item label="有效期" prop="validity">
                <el-date-picker v-model="formData.validity" type="month" placeholder="请选择年和月">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="CVV/CVC2" prop="cvv">
                <el-input-number :controls="false" v-model="formData.cvv" placeholder="请输入"></el-input-number>
              </el-form-item>
              <el-form-item label="国家" prop="country">
                <el-select v-model="formData.country" filterable placeholder="请选择">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="城市" prop="city">
                <el-input v-model="formData.city" placeholder="请输入城市" clearable></el-input>
              </el-form-item>
              <el-form-item label="地址" prop="address" style="width:100%">
                <el-input v-model="formData.address" placeholder="请输入地址" clearable style="width:600px"></el-input>
              </el-form-item>
              <el-form-item label="备注" prop="remarks" style="width:100%">
                <el-input v-model="formData.remarks" placeholder="请输入备注" clearable style="width:600px"></el-input>
              </el-form-item>
              <el-form-item style="width:100%;text-align: right;">
                <el-button size="medium" @click="addDialog = false">取消</el-button>
                <el-button size="medium" @click="submit('ruleForm')" :loading="formLoading"
                  style="background-color: #3888e9;color: #fff;border-radius: 4px">确认</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getordertools, toolcollect, orgorder, querybankcards, addbankcard, updatebankcard, delbankcard } from "@/api/settings";
import { Json } from "@/assets/js/visa.js";
export default {
  name: "visa",
  components: {},
  data() {
    var checkCardNumber = (rule, value, callback) => {
      if (!/^\d+$/.test(value)) {
        return callback(new Error("卡号必须为数字"));
      } else {
        callback();
      }
      if (value.length < 1 || value.length > 20) {
        return callback(new Error("请输入1-20位数字"));
      } else {
        callback();
      }
    };
    var checkSurName = (rule, value, callback) => {
      if (!/^[a-zA-Z]{1,40}$/.test(value)) {
        return callback(new Error("请输入1-40个字符,必须为纯字母格式"));
      } else {
        callback();
      }
    };
    var checkCvv = (rule, value, callback) => {
      let str = value.toString();
      if (!/^\d+$/.test(str) || str.length > 4) {
        return callback(new Error("支持最大4位数字"));
      } else {
        callback();
      }
    }
    var checkCity = (rule, value, callback) => {
      const pattern = /^[a-zA-Z0-9\s]+$/;
      if (!pattern.test(value)) {
        return callback(new Error("仅支持英文大小写、数字、空格"));
      } else {
        callback();
      }
    }
    var checkAddress = (rule, value, callback) => {
      const pattern = /^[a-zA-Z0-9@\(\){}\-_\\,./"']*\s*[a-zA-Z0-9@\(\){}\-_\\,./"']*\s*$/;
      if (!pattern.test(value) || value.length > 200) {
        return callback(new Error(`仅支持英文大小写、数字、空格，符号@ () - _ {}  ' " , . / 且长度在1-200个字符`));
      } else {
        callback();
      }
    }
    return {
      //国家签证列表
      countryList: [],
      addH: false,
      loading: false,
      dialogTableVisible: false,
      bankCardList: [],
      addDialog: false,
      formData: {
        type: "002",
        validity: '',
        country: "CHN"
      },
      rules: {
        cardNumber: [
          { required: true, message: '请输入卡号', trigger: 'blur' },
          { validator: checkCardNumber, trigger: ['blur', 'change'] }
        ],
        surName: [
          { required: true, message: '请输入姓', trigger: 'blur' },
          { validator: checkSurName, trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: '请输入名', trigger: 'blur' },
          { validator: checkSurName, trigger: ['blur', 'change'] }
        ],
        validity: [
          { required: true, message: '请选择年和月', trigger: 'blur' },
        ],
        cvv: [
          { required: true, message: '请输入CVV/CVC2', trigger: 'blur' },
          { validator: checkCvv, trigger: 'blur' }
        ],
        country: [
          { required: true, message: '请选择国家', trigger: 'blur' },
        ],
        city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
          { validator: checkCity, trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
          { validator: checkAddress, trigger: ['blur', 'change'] }
        ],
        remarks: [
          { max: 100, message: '请输入1-100个字符', trigger: ['blur', 'change'] }
        ],
      },
      options: [],
      systemVisaId: 0,//识别哪个国家的支付
      formLoading: false
    };
  },
  created() {
    this.getordertool();
  },
  mounted() {
    this.getbankcards();
    this.options = Json.stateList;
  },
  filters:{
    format(value){
      return value.split(' ')[0];
    }
  },
  //方法集合
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formLoading = true;
          if (this.formData.id) {
            updatebankcard({ ...this.formData }).then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: '更新成功',
                  type: 'success'
                })
                this.formLoading = false;
                this.addDialog = false;
                this.getbankcards();
              }
            }).catch((err) => {
              console.log(err)
              this.$message({
                message: '更新失败',
                type: 'error'
              })
              this.formLoading = false;
            })
          } else {
            addbankcard({ ...this.formData }).then(res => {
              if (res.code == 1) {
                this.$message({
                  message: '新增成功',
                  type: 'success'
                })
                this.formLoading = false;
                this.addDialog = false;
                this.getbankcards();
              }
            }).catch((err) => {
              console.log(err)
              this.$message({
                message: '新增失败',
                type: 'error'
              })
              this.formLoading = false;
            })
          }
        }
      })
    },
    toUpperCase(type) {
      this.formData[type] = this.formData[type].toUpperCase();
    },
    openDialog(item) {
      this.systemVisaId = item.systemVisaId;
      this.dialogTableVisible = true;
    },
    addBankCard() {
      this.formData.systemVisaId = this.systemVisaId;
      this.addDialog = true;
    },
    handleDelete(item) {
      this.$confirm(`是否删除此张银行卡`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delbankcard({ id: item.id}).then(() => {
          this.$message({
            message: `删除成功`,
            type: 'success'
          });
          this.getbankcards();
        })
      })
    },
    handleEdit(item) {
      item.systemVisaId = this.systemVisaId;
      this.formData = JSON.parse(JSON.stringify(item));
      this.addDialog = true;
    },
    getbankcards() {
      querybankcards({ systemvisaId: 2 }).then(res => {
        this.bankCardList = res.data.rows;
      })
    },
    //立即办理
    toOrder(item, i, userId) {
      this.countryList[i].loading = true;
      orgorder({ systemVisaId: item.systemVisaId })
        .then((res) => {
          let item = res.data;
          this.countryList[i].loading = false;
          //马来西亚插件
          if (item.systemVisaId === 1) {
            this.$router.push({
              name: "malaysia",
              query: {
                id: item.id,
              },
            });
            return;
          }
          //其他国家的插件
          if (item && item.autocommit) {
            this.$router.push({
              name: "orderCenterElectron",
              params: {
                id: `${item.applicantName?item.applicantName:'name'}-${item.identityTypeName?item.identityTypeName:'type'}-${item.id}-${item.userId}-${item.systemVisaId}`,
              },
            });
          } else {
            this.$router.push({
              name: "informations",
              params: { id: `${item.id}` },
            });
          }
        })
        .catch((err) => {
          this.countryList[i].loading = false;
          console.log(err);
        });
    },
    //获取机构数据
    getordertool() {
      this.loading = true;
      getordertools().then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            item.loading = false;
          });
          this.countryList = res.data;
        }
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    //是否收藏
    isCollected(item, i) {
      toolcollect({
        id: item.id,
        isCollected: !item.isCollected,
      }).then((res) => {
        this.countryList[i].isCollected = !this.countryList[i].isCollected;
        this.getordertool();
      });
    },
    toOrderCenter(id) {
      this.$router.push({
        name: "orderCenter",
        params: { id },
      });
    },
    openUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  // height: 100%;

  .box-card {
    width: 287px;
    height: 213px;
    padding: 12px 12px 0 12px;
    margin-bottom: 20px;
    margin-right: 16px;

    ::v-deep .el-card__body {
      padding: 0;
    }

    .top {
      height: 34px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      justify-content: left;
      margin-bottom: 20px;

      .bgc {
        width: 55px;
        height: 36px;
      }

      p {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-left: 12px;
      }
    }

    .center {
      height: 92px;
      display: flex;
      justify-content: left;
      flex-wrap: wrap;

      p {
        height: 22px;
        padding: 4px 12px;
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        line-height: 22px;
        margin-bottom: 12px;
        margin-right: 10px;
        cursor: pointer;

        &:hover {
          color: #3888e9;
          border: 1px solid #3888e9;
        }
      }
    }

    .bottom {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;

      .icon-box {
        width: 42px;
        height: 40px;

        .icon {
          width: 39px;
          height: 36px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;

          img {
            width: 18px;
            height: 18px;
          }

          &:hover {
            border: none;
            box-shadow: 0px 0px 6px 1px rgba(56, 136, 233, 0.55);
          }
        }
      }

      .btn {
        width: 108px;
        height: 36px;
        font-size: 17px;
        border-radius: 4px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;

        &:nth-child(2) {
          color: #3888e9;
          border: 1px solid #3888e9;
        }

        &:nth-child(3) {
          opacity: 1;
        }

        &:nth-child(2):hover {
          background-color: #3888e9;
          color: #ffffff;
        }

        &:nth-child(3):hover {
          opacity: 0.8;
        }

        ::v-deep .el-icon-loading {
          margin-left: -10px;
        }
      }
    }
  }
}

.common-dialog-class {
  .dialog-form-wrap {
    .info-form {
      el-form-item{
        width: 50%;
      }
      .radio-item {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        .radio-group {
          display: flex;
          flex-wrap: nowrap;

          .radio-group-item {
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: nowrap;
            padding: 20px 15px 20px 15px !important;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
@/assets/js/visa.js