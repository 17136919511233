import Cookies from 'js-cookie'

const state = {
    countrys: {
        continentCountry: [],
        hotCountry: [],
    },
    screen: false,

}
  
  const mutations = {
    SET_COUNTRYS (state, data) {
        state.countrys = data; 
    },
    SET_SCREEN (state, data) {
        state.screen = data;
    },
  }
  
  const actions = {
    setCountrys({ commit }, { countrys }) {

        Cookies.set('countrys', countrys)
        commit('SET_COUNTRYS', countrys)
    },

    setScreen({ commit }, { screen }) {
        Cookies.set('screen', screen)
        commit('SET_SCREEN', screen)
    },
  }
  
  export default {
    state,
    mutations,
    actions
  }
  