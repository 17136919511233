<template>
  <!-- 商户管理列表 -->
  <div class="merchant">
   
    <div class="box-input">
      <div class="box-input-left">

        <el-select clearable v-model="formInfo.Status" style="width: 145px;" @change="searchFn" placeholder="请选择审核状态">
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>


        <el-select clearable v-model="formInfo.Souce" style="width: 145px;" @change="searchFn" placeholder="请选择来源">
          <el-option v-for="item in souce" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>


        <el-input v-model="formInfo.CompanyName" style="width: 194px;" placeholder="请输入旅行商户名称"></el-input>
        <el-input v-model="formInfo.ApplicantPhone" style="width: 194px;" placeholder="请输入申请人联系方式"></el-input>
      </div>

      <div class="box-input-btn">
        <el-button class="default" @click="searchFn">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="resetFn">重置</el-button>
      </div>

    </div>

    <div class="box-table">
      <el-table :data="list"
        :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
        ref="refTable" style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'"
        v-loading="formInfo.loading" :cell-style="{ 'text-align': 'center' }">


        <el-table-column prop="companyName" label="旅行社商铺名称">
        </el-table-column>
        <el-table-column prop="applicantName" label="申请人">
        </el-table-column>
        <el-table-column prop="applicantPhone" label="申请人联系方式">
        </el-table-column>
        <el-table-column prop="couponList" label="省市区">
          <template slot-scope="scope">
            {{ scope.row.province }}/{{ scope.row.city }}/{{ scope.row.district }}
          </template>
        </el-table-column>
        <el-table-column prop="addressDetail" label="详细地址">
        </el-table-column>
        <el-table-column prop="souceName" label="来源">
        </el-table-column>
        <el-table-column prop="conpanyCard" label="营业执照">
          <template slot-scope="scope">
            <el-image style="width: 80px;height: 50px;" v-if="scope.row.conpanyCard" :src="scope.row.conpanyCard">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="businessCard" label="名片">
          <template slot-scope="scope">
            <el-image style="width: 80px;height: 50px;" v-if="scope.row.businessCard" :src="scope.row.businessCard">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="logo" label="logo">
          <template slot-scope="scope">
            <el-image style="width: 50px;height: 50px;" v-if="scope.row.logo" :src="scope.row.logo">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="statusName" label="状态">
          <template slot-scope="scope">
            <div class="class-status" :class="[setStatus(scope.row.status)]">{{ scope.row.statusName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="center">
          <template slot-scope="scope">
            <div class="edit-wrap">
              <div class="edit-item">
                <span class="lan" @click="editMerchant(scope.row)">编辑</span>
                <span class="lan" @click="bindService(scope.row)">绑定客服</span>
                <span class="del" v-if="!scope.row.isDefault" @click="delMerchant(scope.row.id)">删除</span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination @current-change="memberCurrentChange" :current-page="formInfo.pageIndex" :page-size="10"
        layout="total, prev, pager, next, jumper" :total="formInfo.total">
      </el-pagination>
    </div>
    <!-- 编辑商户管理 -->
    <el-dialog :visible.sync="dialogMember" width="585px" class="common-dialog-class" :close-on-click-modal="false"
      @open="openDialog">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name"> 商户管理</span>
          <div class="dialog-close" @click="resetForm('merchantForm')">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <el-form :model="merchantForm" ref="merchantForm" label-width="100px" label-position="left">
            <div class="dialog-form-item">
              <div class="title">
                <span class="icon-box">
                  <i class="iconfont icon-dianpu"></i>
                </span>
                旅行社信息
              </div>
              <el-form-item label="旅行社名称:" prop="companyName">
                <el-input v-model="merchantForm.companyName" type="text" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="申请人:" prop="applicantName" class="apply-info">
                <el-input v-model="merchantForm.applicantName" type="text" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="联系方式:" prop="applicantPhone" class="apply-info" style="margin-left: 20px;">
                <el-input v-model="merchantForm.applicantPhone" type="text" placeholder="请填写"></el-input>
              </el-form-item>
            </div>

            <div class="dialog-form-item">
              <div class="title">
                <span class="icon-box">
                  <i class="iconfont icon-a-dizhi1"></i>
                </span>
                地址信息
              </div>
              <el-form-item label="所在地区:" prop="rechargeAmount">
                <div class="address-top">
                  <el-select v-model="merchantForm.province" placeholder="省" @change="checkProvinceId">
                    <el-option v-for="item in provinces" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>

                  <el-select v-model="merchantForm.city" placeholder="市" @change="checkCitysId">
                    <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>

                  <el-select v-model="merchantForm.district" placeholder="县">
                    <el-option v-for="item in districts" :key="item.id" :label="item.name" :value="item.name">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="详细地址:" prop="addressDetail">
                <el-input v-model="merchantForm.addressDetail" type="textarea" :rows="2" resize="none"
                  placeholder="请填写详细地址"></el-input>
              </el-form-item>
            </div>
            <div class="dialog-form-item">
              <div class="title">
                <span class="icon-box">
                  <i class="iconfont icon-PCfawen-copy"></i>
                </span>
                资质信息
              </div>
              <div class="upload-box">
                <div class="up-item">
                  <span class="up-item-title">LOGO</span>
                  <upload @uploading="uploadHandle" accept="img" :auto-upload="true" :image-url="merchantForm.logo"
                    :drag="'drag'" thisType="custom" @input="toImg($event, 'logo')"
                    :customSty="{ width: '120px', height: '120px' }">
                  </upload>
                </div>
                <div class="up-item">
                  <span class="up-item-title">营业执照</span>
                  <upload @uploading="uploadHandle" accept="img" :auto-upload="true" :image-url="merchantForm.conpanyCard"
                    :drag="'drag'" thisType="custom" @input="toImg($event, 'conpanyCard')"
                    :customSty="{ width: '120px', height: '120px' }">
                  </upload>
                </div>
                <div class="up-item">
                  <span class="up-item-title">名片</span>
                  <upload @uploading="uploadHandle" accept="img" :auto-upload="true"
                    :image-url="merchantForm.businessCard" :drag="'drag'" thisType="custom"
                    @input="toImg($event, 'businessCard')" :customSty="{ width: '120px', height: '120px' }">
                  </upload>
                </div>
              </div>
              <el-form-item label="注册来源:" prop="source">
                <el-select v-model="merchantForm.souce">
                  <el-option v-for="item in souce" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>

          <div class="common-dialog-foot-class" style="margin-top: 0">
            <el-button class="common-btn" @click="resetForm('merchantForm')">取消</el-button>
            <el-button class="common-btn" type="primary" @click="submitForm">确认</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 绑定客服 -->
    <el-dialog :visible.sync="bindDialog" width="1000px" class="common-dialog-class" :close-on-click-modal="false"
      @open="getServiceData" @close="serviceName = '';checkService=''">
      <div class="dialog-member-wrap">
        <div class="dialog-member-title">
          <span class="dialog-member-name">绑定客服</span>
          <div class="dialog-close" @click="bindDialog = false">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <div class="dialog-form-wrap">
          <div class="service-input">
            客服名称:
            <el-input v-model="serviceName" style="width: 230px;" placeholder="请输入客服名称" clearable></el-input>
            <el-button class="default" @click="getServiceData">查询</el-button>
          </div>
          <div class="box-table1">
            <el-table :data="serviceList"
              :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
              ref="refServiceTable" height="400px" :cell-style="{ 'text-align': 'center' }" highlight-current-row
              @row-click="clickTable" :cell-class-name="cellClassName">
              <el-table-column width="25px" align="center">
                <template #default="scope">
                  <el-radio v-model="checkService" :label="scope.row.id">
                    <span></span>
                  </el-radio>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="名称">
              </el-table-column>
              <el-table-column prop="phone" label="联系方式">
              </el-table-column>
              <el-table-column prop="wechatImg" label="企业微信二维码">
                <template slot-scope="scope">
                  <el-image style="width: 50px;height: 50px;" v-if="scope.row.wechatImg" :src="scope.row.wechatImg"
                    :preview-src-list="[scope.row.wechatImg]">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </template>
              </el-table-column>
              <el-table-column prop="email" label="邮箱" width="170">
              </el-table-column>
              <el-table-column prop="area" label="服务区域">
              </el-table-column>
              <el-table-column prop="introduction" label="简介" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
          <div class="table-pagination" v-show="serviceTotal">
            <el-pagination @current-change="serviceCurrentChange" :current-page="servicePageIndex" :page-size="10"
              layout="total, prev, pager, next, jumper" :total="serviceTotal">
            </el-pagination>
          </div>
          <div class="common-dialog-foot-class" style="margin-top: 20px">
            <el-button class="common-btn" @click="bindDialog = false">取消</el-button>
            <el-button class="common-btn" type="primary" @click="submitBindForm">保存</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { merchant, mercahtsouceselect, applystatusselect, modifymerchatinfo, deletedmerchat, querycustomerservice, bindcustomservice } from '@/api/settings.js'
import { getdistricts, getcitys, getprovinces } from '@/api/personal.js'
import upload from '@/components/upload.vue'

export default {
  data() {
    return {
      activeName: 'second',
      list: [],
      formInfo: {
        Souce: '',
        CompanyName: '',
        pageIndex: 0,
        ApplicantPhone: '',
        total: 0,
        loading: false,
        Status: '',
      },
      souce: [],
      statusList: [],
      dialogMember: false,
      merchantForm: {
        companyName: "",
        applicantName: "",
        applicantPhone: '',
        province: '',
        city: '',
        district: '',
        logo: '',
        conpanyCard: '',
        businessCard: '',
        souce: '',
      },
      districts: [],
      citys: [],
      provinces: [],
      uploading: true,
      sourceSelect: [],
      bindDialog: false,
      serviceName: '',
      serviceList: [],
      servicePageIndex: 1,
      serviceTotal: 0,
      checkService: ''
    }
  },
  components: {
    upload
  },
  mounted() {
    this.mercahtsouceselectFn();
    this.applystatusselectFn();
    this.merchantFn();
  },
  computed: {
    setStatus() {
      return function (type) {
        if (type == 0) {
          return 'color-default'
        } else if (type == 1) {
          return 'color-lv'
        } else {
          return 'color-no'
        }
      }
    }
  },
  methods: {
    cellClassName({ row, column, rowIndex, columnIndex }) {
      if (row.id == this.checkService) {
        return "highlight"
      }
    },
    clickTable(row, index, e) {
      this.checkService = row.id
    },
    //绑定客服打开弹窗
    getServiceData() {
      querycustomerservice({ name: this.serviceName, 'page.Size': 10, 'page.Index': this.servicePageIndex }).then(res => {
        this.serviceList = res.data.rows
        this.serviceTotal = res.data.total
      })
    },
    submitBindForm() {
      if(!this.checkService){
        return this.$message.error('没有选择客服!')
      }
      bindcustomservice({customServiceId:this.checkService,id:this.merchantForm.id}).then(res=>{
        this.$message.success('绑定成功!')
        this.bindDialog = false
        this.merchantFn()
      })
    },
    openDialog() {
      if (!this.provinces.length) {
        getprovinces({}).then(res => {
          this.provinces = res.data
        })
      }
    },
    searchFn() {
      this.formInfo.pageIndex = 1
      this.merchantFn()
    },
    merchantFn() {
      this.formInfo.loading = true;

      merchant({
        ApplicantPhone: this.formInfo.ApplicantPhone,
        CompanyName: this.formInfo.CompanyName,
        Souce: this.formInfo.Souce,
        Status: this.formInfo.Status,
        'Page.Size': 10,
        'Page.Index': this.formInfo.pageIndex,
      }).then(res => {
        console.log(res)
        this.list = res.data.rows;
        this.formInfo.total = res.data.total
        this.formInfo.loading = false;
      })
    },
    resetFn() {
      this.formInfo = {
        Souce: '',
        CompanyName: '',
        pageIndex: 1,
        ApplicantPhone: '',
        total: 0,
        loading: false,
        Status: '',
      }
      this.merchantFn();
    },
    // 来源
    mercahtsouceselectFn() {
      mercahtsouceselect({
      }).then(res => {
        this.souce = res.data;
      })
    },
    // 状态
    applystatusselectFn() {
      applystatusselect({
      }).then(res => {
        this.statusList = res.data;
      })
    },
    memberCurrentChange(val) {
      this.formInfo.pageIndex = val
      this.merchantFn()
      this.$refs.refTable.bodyWrapper.scrollTop = 0
    },
    serviceCurrentChange(val) {
      this.servicePageIndex = val
      this.getServiceData()
      this.$refs.refServiceTable.bodyWrapper.scrollTop = 0
    },
    editMerchant(item) {
      this.merchantForm = JSON.parse(JSON.stringify(item))
      console.log(this.merchantForm)
      this.dialogMember = true;
    },
    bindService(item) {
      this.bindDialog = true
      this.merchantForm = JSON.parse(JSON.stringify(item))
    },
    delMerchant(id) {
      this.$confirm(`是否要要删除该商户`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletedmerchat({ id }).then(res => {
            if (res.code == 1) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.merchantFn()
            } else {
              this.$message.error(res.msg);
            }
          })
        })
        .catch((res) => { });
    },
    resetForm(formName) {
      this.dialogMember = false;
      if (formName) {
        this.$refs[formName].resetFields();
      }
    },
    checkProvinceId(e) {
      this.merchantForm.city = '';
      this.merchantForm.district = '';
      let provinceId = ''
      this.provinces.forEach(v => {
        if (v.name == e) {
          provinceId = v.id
        }
      })
      this.getcitysFn(provinceId)
    },
    checkCitysId(e) {
      this.merchantForm.district = '';
      let cityId = ''
      this.citys.forEach(v => {
        if (v.name == e) {
          cityId = v.id
        }
      })
      this.getdistrictsFn(cityId);
    },
    getcitysFn(e) {
      getcitys({ ProvicesId: e }).then(res => {
        this.citys = res.data;
      })
    },

    getdistrictsFn(e) {
      getdistricts({ CityId: e }).then(res => {
        this.districts = res.data
      })
    },

    toImg(e, type) {
      if (type == 'logo') {
        this.merchantForm.logo = e
      } else if (type == 'conpanyCard') {
        this.merchantForm.conpanyCard = e
      } else {
        this.merchantForm.businessCard = e
      }
    },

    uploadHandle(val) {
      var vm = this;
      console.log(val);
      vm.uploading = val;
    },
    submitForm() {
      modifymerchatinfo(this.merchantForm).then(res => {
        if (res.msg == 'success') {
          this.dialogMember = false;
          this.$message.success('编辑成功！')
          this.merchantFn()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.merchant {
  ::v-deep .cell {
    font-size: 12px;
  }
}


.box-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;

  ::v-deep .el-input__inner {
    background: #F2F4F7;
    border: none;
  }

  ::v-deep .el-select,
  .el-input {
    margin-right: 8px;
  }

  .box-input-btn {
    text-align: right;

    .box-input-btn-cz {
      background: #EAF3FF;
      color: #3888E9;
      border: none;
    }

    .box-input-btn-add {
      background: #D8F5E4;
      color: #54D8A5;
      border: none;
    }
  }
}

.edit-wrap {

  .edit-item {

    span {
      padding-right: 10px;
      cursor: pointer;
      display: inline-block;

      &.lan {
        color: #3888E9;
      }

      &.copy {
        color: #9EA09E;
      }

      &.del {
        color: #E41313;
      }
    }
  }
}

.class-status {
  height: 29px;
  line-height: 29px;
  border-radius: 4px;
  color: #FFF;
  width: 60px;
}

.color-default {
  background: #3888E9;
}

.color-lv {
  background: #4BD97F;
}

.color-no {
  background: #FF5967;
}


.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.dialog-form-item {
  background: #fff;
  border-radius: 8px;
  margin: 15px 0;
  padding: 15px;


  .title {
    height: 20px;
    font-size: 16px;
    color: #333;
    display: flex;
    margin-bottom: 10px;

    .icon-box {
      width: 21px;
      height: 21px;
      background: #EAF3FF;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      i {
        font-size: 12px;
        color: #3888E9;
      }
    }
  }

  .apply-info {
    width: 48%;
    display: inline-block;
  }

  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}

.address-top {
  display: flex;

  ::v-deep .el-select {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.upload-box {
  display: flex;
  margin-bottom: 20px;

  .up-item-title {
    margin-right: 5px;
    font-size: 12px;
  }

  .up-item {
    display: flex;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.service-input {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin: 10px 0;

  ::v-deep .el-input {
    margin: 0 8px;
  }
}
::v-deep .highlight {
    .cell {
      color: #3888E9;
    }
  }
</style>