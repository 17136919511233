<template>
  <div style="display: inline-block;">
    <el-select
      v-model="modelValue"
      :style="string"
      :placeholder="placeholder"
      clearable
      filterable
      v-selectLoadMore="selectLoadMoreFn"
      remote
      :remote-method="remoteMethodFn"
      @change="changeValueFn"
      @visible-change="visibleChangeFn"
    >
      <el-option
        v-for="item in data.list"
        :key="item[option.key]"
        :label="item[option.label]"
        :value="item[option.value]"
      >
      </el-option>
      <div class="flex-row-center select-nodata">
        <span v-if="!data.loading && lastPage">--没有更多数据--</span>
        <span v-else><i class="el-icon-loading" ></i>加载中</span>
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
    data() {
        return  {
            modelValue: '',
            remoteValue: '' , //搜索框值
        }
    },
    computed: {
        lastPage() {
            return function() {
                return this.data.pamars.pageIndex == Math.ceil(this.data.total/this.data.pamars.pageSize)
            }
        }
    },
    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        string: {
            type: String,
        },
        option: {
            type: Object,
            default: () => {
                return {
                    key: 'id',
                    label: 'name',
                    value: 'value'
                }
            }
        },
        data: {
            type: Object,
            default: () => {
                return {
                    pamars: {
                        pageIndex: 1,
                        pageSize: 10,
                    },
                    list: [],
                    loading: false,
                    total: 0,
                }
            } 
        }
    },
    watch: {
        value(val) {
            this.modelValue = val
        }
    },
    methods: {
        selectLoadMoreFn(val) {
            this.$emit('selectLoadMoreFn', val)
        },
        remoteMethodFn(val) {
            this.remoteValue = val
            this.$emit('remoteMethodFn', val)
        },
        changeValueFn(val) {
            if(!val) {
                this.remoteValue = ''
                this.$emit('remoteMethodFn', '')
            }
            this.$emit('input',val)
        },
        visibleChangeFn(val) {
            if(!val){
                this.$emit('remoteMethodFn', '')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.flex-row-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-nodata {
  padding: 10px;
  font-size: 12px;
  color: #909399;
}
</style>