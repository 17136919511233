<!-- 充值 -->
<template>
  <div>
    <el-dialog title="" :close-on-click-modal="false" center :visible.sync="visible" width="622px" @open="handleOpen" :before-close="handleClose" class="common-dialog-class">
      <div class="dialog-member-title">
        <div class="dialog-member-name">
          点券账户余额<span>{{$store.state.orgBalance}} 点券</span>
        </div>
        <div class="dialog-close" @click="handleClose">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="">
        <div class="tips1">请选择点券充值金额</div>
        <div class="tips2">点券用于支付使用签证工具的服务费，充值后无法提现或退款</div>
      </div>
      <div class="dialog-contenter">
        <div class="card-box">
          <div class="card-item" :class="{'active': payActive.id == item.id }" v-for="item in ticketsList" @click="clickTickets(item)">
            <div class="card-item-name">{{item.rechargeAmount}}</div>
            <div class="card-item-ticket">点券</div>
            <div v-if="payActive.id == item.id" class="active-check">
              <i class="el-icon-check" />
            </div>
          </div>
        </div>
        <div class="recharge-box">
          <div class="recharge-left">
            <div class="recharge-left-top">应付金额<span>1人民币=1点券</span></div>
            <div class="recharge-left-content"><span class="money-icon">￥</span>{{payActive.price || 0}}</div>
          </div>
          <div class="recharge-right">
            <div class="recharge-type">
              
              <div class="recharge-right-item" @click="setPayActive(7)" :class="{ 'recharge-right-item-active': payType == 7}">
                <img :src="require('@/assets/images/common/wx1.png')" alt="">
                <span>微信支付</span>
                <div v-if="payType == 7" class="recharge-right-item-check">
                  <i class="el-icon-check" />
                </div>
              </div>
              <div class="recharge-right-item" @click="setPayActive(8)" :class="{ 'recharge-right-item-active': payType == 8}">
                <img :src="require('@/assets/images/common/zf1.png')" alt="">
                <span>支付宝支付</span>
                <div v-if="payType == 8" class="recharge-right-item-check">
                  <i class="el-icon-check" />
                </div>
              </div>
            </div>
            <div class="qrcode-box" v-loading="qrLoading">
                <div class="tips" v-if="!payType">请选择支付方式</div>
                <canvas id="qrcode" width="90px" height="90px" v-else></canvas>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getorgpackages, createrechargeorder, getrechargeorder } from "@/api/orderCenter";
import UQRCode from 'uqrcodejs';
import { EventBus } from '@/utils/eventBus.js';
export default {
  data() {
    return {
      payType: 7, // 支付方式：微信7|支付宝8
      payActive: {},
      ticketsList: [],
      qrLoading: false,
      qrData: '在线免费生成二维码工具',//数据
      canvas: null,
      qrTime: '',
      qrInfo: {
        payParameters: ''
      },
      qrcodeObj: {}, // 二维码配置
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String | Number,
      default: '',
    },
  },
  methods: {
    handleOpen() {
      this.payType = 7;
      this.getInfo();
    },
    handleClose() {
      this.payActive = {}
      this.payType = 0
      if (this.qrTime) { // 判断清除
        clearInterval(this.qrTime)
        this.qrTime = null
      }
      this.$emit('close')
    },
    clickTickets(item) {
      this.payActive = item
      if(this.payType) {
        this.submitRecharge()
      }
    },
    setPayActive(val) {
      if (!this.payActive || !this.payActive.id) {
        return this.$message.error("请选择点券充值金额")
      }
      this.payType = val
      this.submitRecharge()
    },
    getInfo() {
      getorgpackages().then(res => {
        if (res.code == 1) {
          this.ticketsList = res.data;
          if(this.ticketsList.length) {
            this.clickTickets( this.ticketsList[0])
          }
          
        }
      })
    },
    submitRecharge() {

      this.qrLoading = true;

      createrechargeorder({
        amount: this.payActive.price,
        "packageId": this.payActive.id, //充值套餐id
        "unionPayType": this.payType //支付方式 7微信 8支付宝
      }).then(res => {
        console.log(res);

        this.qrInfo = res.data;
        this.sureClick(this.qrInfo.payParameters)

        console.log(res);

        if (this.qrTime) { // 判断清除
          clearInterval(this.qrTime)
          this.qrTime = null
        }

        this.qrLoading = false;


        this.qrTime = setInterval(() => {
          setTimeout(() => {
            this.getrechargeorderFn(res.data.orderId)
          }, 0)
        }, 1000)

      })
    },
    getrechargeorderFn(id) {

      getrechargeorder({
        orderId: id,
      }).then(res => {
        if (res.data.status == 1) {

          if (this.qrTime) { // 判断清除
            clearInterval(this.qrTime)
            this.qrTime = null
          }
          this.$message({
            message: '充值成功',
            type: 'success'
          });
          this.$emit('success')
          this.handleClose()
          EventBus.$emit('refreshOrgBalance');
          EventBus.$emit('refreshOrgTotal');
        }
      })
    },

    sureClick(imgStc) {
      // if (this.qrData.length <= 0) {
      //     return false
      // }
      // 获取uQRCode实例
      var qr = new UQRCode();
      // 设置二维码内容
      qr.data = imgStc;
      // 设置二维码大小，必须与canvas设置的宽高一致
      qr.size = 90;
      qr.margin = 5;
      // 调用制作二维码方法
      qr.make();
      // 获取canvas元素
      this.canvas = document.getElementById("qrcode");
      // 获取canvas上下文
      var canvasContext = this.canvas.getContext("2d");
      // 设置uQRCode实例的canvas上下文
      qr.canvasContext = canvasContext;
      // 调用绘制方法将二维码图案绘制到canvas上
      qr.drawCanvas();
    },
    downLoadImage() {
      var a = document.createElement("a");
      a.href = this.canvas.toDataURL();
    //   a.download = '六月初工具站-二维码生成';
      a.click();
    },
  },
  destroyed() {
    if (this.qrTime) { // 判断清除
      clearInterval(this.qrTime)
      this.qrTime = null
    }
  }
}
</script>

<style lang="scss" scoped>
$themeColor: #3888e9;
::v-deep .common-dialog-class {
  background: none !important;
  border-radius: 12px;

  .el-dialog {
    background: none;
  }

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    // width: 761px;

    // height: 285px;
    background: linear-gradient(180deg, #e7ecfb 0%, #f6f8fe 24%, #ffffff 100%);
    border-radius: 12px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.common-dialog-foot-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .pageck-btn {
    width: 122px;
    margin: 0 16px;
  }
}
.common-dialog-class {
  .dialog-member-title {
    align-items: inherit;
    margin-top: 0;
  }
  .dialog-member-name {
    font-weight: 500;
    width: 499px;
    height: 53px;
    background: #ffffff;
    color: #333333;
    border-radius: 8px 8px 8px 8px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    margin-bottom: 24px;
    span {
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }
  }
  .tips1 {
    color: #333333;
    font-size: 20px;
  }
  .tips2 {
    color: #999999;
    font-size: 14px;
    margin: 8px 0 20px;
  }
  .dialog-contenter {
    margin-top: 12px;
    .themeColor {
      color: $themeColor;
    }
    .card-desc {
      display: inline-flex;
      .recharge {
        margin-left: 25px;
        color: $themeColor;
      }
    }
    .card-box {
      margin-top: 20px;
      display: flex;
      .card-item + .card-item {
        margin-left: 20px;
      }
      .card-item {
        width: 125px;
        height: 92px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #e0e0e0;
        transition: transform 0.3s;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        color: #333333;

        .card-item-name {
          font-size: 20px;
        }
        .card-item-ticket {
          font-size: 14px;
        }
      }
      .active {
        border: 1px solid $themeColor;
        overflow: hidden;
        .active-check {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 22px;
          height: 22px;
          background-color: $themeColor;

          display: flex;
          .el-icon-check {
            margin: auto;
            font-size: 16px;
            transform: rotate(0deg);
            color: #fff;
          }
        }
      }
    }
  }
  .recharge-box {
    margin-top: 40px;
    padding: 16px 9px;
    color: #ffffff;
    background: linear-gradient(90deg, #0c0b0a 0%, #4c4c4c 100%);
    border-radius: 8px 8px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .recharge-left {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      height: 80px;
      .recharge-left-top {
        display: inline-flex;
        align-items: flex-end;
        span {
          margin-left: 16px;
          font-size: 10px;
        }
      }
      .recharge-left-content {
        color: #ffe6ca;
        font-size: 30px;
        span {
          font-size: 10px;
        }
      }
    }
    .recharge-right {
      display: flex;
      .recharge-type {
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .recharge-right-item + .recharge-right-item {
          margin-top: 5px;
        }
        .recharge-right-item {
          display: inline-flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
          width: 115px;
          height: 30px;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #696969;

          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }

          span {
            font-size: 14px;
          }
        }
        .recharge-right-item-active {
          position: relative;
          border: 1px solid #ffe6ca;
          .recharge-right-item-check {
            background: #ffe6ca;
            position: absolute;
            bottom: 0;
            right: 0;
            width: 14px;
            height: 14px;

            display: flex;
            .el-icon-check {
              margin: auto;
              font-size: 10px;
              transform: rotate(0deg);
              color: #fff;
            }
          }
        }
      }

      .qrcode-box {
        width: 90px;
        height: 90px;
        background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        border: 1px solid #707070;
        .tips {
            color: #d5d5d5;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            font-size: 12px;
        }
      }
    }
  }
}
</style>