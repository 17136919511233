import request from '@/utils/request';

// export function getListData(params) {
//   console.log('params',params);
//   return request('/community/home-api/v1/get-business-list', {
//     method: 'get',
//     params: {...params}//get请求使用params,直接拼接在请求url上
//   })
// }

export const applicationaccount = (params) => {
  return request('/app_api/v1/auth/applicationaccount', {
    method: 'post',
    data: {...params}//post请求使用post
  })
}


export const login = (params) => {
  return request('/app_api/v1/auth/login', {
    method: 'post',
    data: {...params}//post请求使用post
  })
}

export function sendsms(params) {
  return request('/app_api/v1/auth/sendsms', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}


// 登录接口
export function merchatlogin(params) {
  return request('/app_api/v1/auth/merchatlogin', {
    method: 'post',
    data: {...params}//get请求使用params,直接拼接在请求url上
  })
}

// 获取微信小程序分享二维码
export function wechatshareqrcode(params) {
  return request('/app_api/v1/appinfo/wechatshareqrcode', {
    method: 'post',
    data: {...params}//get请求使用params,直接拼接在请求url上
  })
}

// 查询当前平台相关信息
export function getorginfo(params) {
  return request('/org_api/v1/org/getorginfo', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
} 

// 查询用户是否绑定完成
export function querybingaccountstatus(params) {
  return request('/app_api/v1/auth/querybingaccountstatus', {
    method: 'post',
    data: {...params}//get请求使用params,直接拼接在请求url上
  })
}


