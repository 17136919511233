<template>
  <!-- 商户管理列表 -->
  <div class="merchantPage">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="商户管理" name="first">
        <MerchantPage v-if="activeName == 'first'"></MerchantPage>
      </el-tab-pane>
      <el-tab-pane label="商户提现" name="second">
        <MerchantWithdrawalPage v-if="activeName == 'second'"></MerchantWithdrawalPage>
      </el-tab-pane>
      <el-tab-pane label="代理商管理" name="third">
        <AgentPage v-if="activeName == 'third'"></AgentPage>
      </el-tab-pane>
      <el-tab-pane label="代理商提现" name="fourth">
        <AgentWithdrawalPage v-if="activeName == 'fourth'"></AgentWithdrawalPage>
      </el-tab-pane>
      <el-tab-pane label="客服管理" name="fover">
        <ServicePage v-if="activeName == 'fover'"></ServicePage>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MerchantPage from "./components/Merchant.vue";
import MerchantWithdrawalPage from "./components/MerchantWithdrawal.vue";
import AgentPage from "./components/Agent.vue";
import AgentWithdrawalPage from "./components/AgentWithdrawal.vue";
import ServicePage from "./components/Service.vue";

export default {
  components: { MerchantPage, MerchantWithdrawalPage, AgentPage, AgentWithdrawalPage, ServicePage },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    handleClick(tab, event) {
        this.activeName = tab.name
        console.log(tab.name, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.merchantPage {
  ::v-deep {
    .el-tabs {
      height: 100%;
      .el-tabs__content {
        height: calc(100% - 40px);
      }
    }
    .el-tab-pane {
      height: 100%;

      > div {
        height: calc(100% - 32px);
      }
    }
  }
}
</style>