import request from '@/utils/request';

export function getcountrys(params) {
    return request('/app_api/v1/country/getcountrys', {
      method: 'get',
      params: {...params}//get请求使用params,直接拼接在请求url上
    })
}

export function gethotvisas(params) {
  return request('/app_api/v1/visa/gethotvisas', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}
  
export function getrecommendcountrys(params) {
  return request('/app_api/v1/country/getrecommendcountrys', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}

export function getuserinfo(params) {
  return request('/app_api/v1/user/getuserinfo', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}

export function getcountryandvisatype(params) {
  return request('/app_api/v1/country/getcountryandvisatype', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}


// 查询相关协议
export function getagreementbykey(params) {
  return request('/app_api/v1/appinfo/getagreementbykey', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}

// 获取可用优惠券数量
export function getcouponcout(params) {
  return request('/app_api/v1/user/getcouponcout', {
    method: 'get',
    params: {...params}//get请求使用params,直接拼接在请求url上
  })
}

// 获取当前登录账号权限信息  (机构端)
export function getorgauthinfo(params) {
  return request('/org_api/v1/org/getorgauthinfo', {
    method: 'get',
    params: { ...params }
  })
}


//字典类数据
export function getbykey(params) {
  return request('/org_api/v1/org/getbykey', {
    method: 'get',
    params: { ...params }
  })
}

