import request from '@/utils/request';
//机构登录接口，找回密码
export const orglogin = (params) => {
  return request('/org_api/v1/auth/orglogin', {
    method: 'post',
    data: { ...params }
  })
}
//机构发送短信
export const sendsms = (params) => {
  return request('/org_api/v1/auth/sendsms', {
    method: 'get',
    params: { ...params }
  })
}