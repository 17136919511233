<template>
  <div class="page order-detail-child order-detail-zdh">
    <div class="order-wrap"">
      <div class="page-path">
        <span @click="toPath(2)">订单中心</span> > <span>资料表详情</span>
      </div>

      <div class="page-order-detaul-bom">
        <div class="page-order-detail-left">
          <div class="page-order-item-info">
            <div class="page-order-item-t-box">
              <div class="page-order-item-t">
                <span>订单号：</span>
                <p id="copy">{{ orderDetail.order.orderNo }}</p>
                <el-button
                  type="text"
                  style="font-size: 16px; padding: 0; padding-left: 5px"
                  class="el-icon-document-copy"
                  v-clipboard:copy="orderDetail.order.orderNo"
                  v-clipboard:success="copySuccess"
                  v-clipboard:error="copyError"
                >
                </el-button>
                <div
                  class="page-order-status-name"
                  @click="cancelOrder"
                  v-show="[0, 1, 2].includes(orderDetail.order.status)"
                >
                  取消订单
                </div>
              </div>
              <div class="page-order-item-t">
                <span>产品名称：</span>
                <p>{{ orderDetail.order.visaTitle }}</p>
              </div>
              <div class="page-order-item-t">
                <span>国家-类型：</span>
                <p v-if="orderDetail.visaDetail && orderDetail.visaDetail.visa">
                  {{ orderDetail.visaDetail.visa.countryName }}-{{
                    orderDetail.visaDetail.visa.typeName
                  }}
                </p>
              </div>
              <div class="page-order-item-t">
                <span>预计出行人数：</span>
                <p>{{ orderDetail.order.num }}</p>
              </div>
              <div class="page-order-item-t">
                <span>预计出行日期：</span>
                <p>
                  {{
                    orderDetail.order.departureTime &&
                    orderDetail.order.departureTime.split(" ")[0]
                  }}
                </p>
              </div>
              <div class="page-order-item-t" @click="showMore = !showMore">
                <span>下单时间：</span>
                <p>
                  {{
                    orderDetail.order.createdTime &&
                    orderDetail.order.createdTime.split(" ")[0]
                  }}
                </p>
              </div>
              <div class="page-order-item-t" v-show="showMore">
                <span>创建人：</span>
                <p>{{ orderDetail.order.userName }}</p>
              </div>
              <div class="page-order-item-t" v-show="showMore">
                <span>备注：</span>
                <p>{{ orderDetail.order.visaTitle }}</p>
              </div>
            </div>
            <div class="page-order-up" @click="showMore = !showMore">
              <span v-if="!showMore"
                >展开<i class="el-icon-arrow-down"></i
              ></span>
              <span v-else>收起<i class="el-icon-arrow-up"></i></span>
            </div>
          </div>

          <div class="page-order-item-table">
            <div class="page-order-detail-btn">
              <el-button
                class="default"
                style="margin-right: 10px"
                @click="addUser"
                :disabled="!editFlag"
                >添加申请人</el-button
              >
              <span class="beadd"
                >已添加<b>{{ userList.length }}</b
                >人</span
              >
              <span class="batch" v-show="orderInfo.batchCode">
                <span>Betch Number</span> <br />
                <span>{{ orderInfo.batchCode }}</span>
              </span>
            </div>
            <div class="page-order-table-box" v-loading="applicationLoading">
              <el-table
                :data="userList"
                ref="tableOrder"
                :header-cell-style="{
                  backgroundColor: 'none',
                  color: '#333',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  fontSize: '10px',
                }"
                height="250"
                @row-click="setColl"
                :cell-style="{ 'text-align': 'center' }"
                :row-class-name="rowClassFun"
                highlight-current-row
                v-if="userList.length"
              >
                <el-table-column prop="userName" label="创建人">
                </el-table-column>
                <el-table-column prop="applicantName" label="姓名">
                </el-table-column>
                <el-table-column prop="statusName" label="申请状态">
                  <template slot-scope="scope">
                    <div class="flex-app">
                      <div :style="setTopClass(scope.row.status)">
                        {{ scope.row.statusName }}
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div v-else style="text-align: center;margin-top: 100px;">
                <img
                  src="@/assets/images/orderCenter/empty1.png"
                  alt="暂无数据"
                  style="width: 137px; height: 115px; margin-top: 50px"
                />
                <p
                  style="
                    color: #cccccc;
                    margin-top: 16px;
                    font-size: 12px;
                  "
                >
                  客户列表为空
                </p>
              </div>
              <div class="table-pagination" v-show="userList.length">
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="pageIndex"
                  :pager-count="5"
                  small
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
              <div class="flex-justify-center submit">
                <el-button
                  @click="handleSubmitBefore"
                  v-show="orderInfo.status === 0 || orderInfo.status === -1"
                  class="top-item-btn"
                  >{{
                    orderInfo.status === -1 ? "重新" : ""
                  }}提交到大使馆</el-button
                >
                <el-button
                  disabled
                  v-show="orderInfo.status === 2"
                  class="top-item-btn"
                  >处理中</el-button
                >
                <el-button
                  @click="openPdf(orderInfo.payUrl)"
                  v-show="orderInfo.status === 4"
                  class="top-item-btn"
                  >立即付费</el-button
                >
                <el-button
                  @click="handleOrderPaid"
                  v-show="orderInfo.status === 4"
                  class="top-item-btn nobg-btn"
                  >我已付款</el-button
                >
                <el-button
                  disabled
                  v-show="orderInfo.status === 6"
                  class="top-item-btn"
                  >查询支付中</el-button
                >
                <el-button
                  disabled
                  v-show="orderInfo.status === 8"
                  class="top-item-btn"
                  >等待出签中</el-button
                >
                <el-button
                  @click="openPdf(orderInfo.visaPage)"
                  v-show="orderInfo.status === 10"
                  class="top-item-btn"
                  >下载签证页</el-button
                >
              </div>
              <div v-show="orderInfo.status === 4" class="submit-email">
                付款完成后，点击“我已付款”查询支付状态
              </div>
              <div
                v-show="orderInfo.status === -3"
                class="submit-email"
                style="color: red"
              >
                官网为未支付，如实际确已支付，请隔几分钟再查或联系客服，勿重复付款!!!
              </div>
              <div v-show="orderInfo.email" class="submit-email">
                提交账号:{{ orderInfo.email }}
              </div>
            </div>
          </div>
        </div>

        <div class="page-order-detail-bottom">
          <div class="page-order-detail-bottom-content" ref="contentBox">
            <div
              v-show="orderInfo.batchCode"
              style="
                height: 50px;
                line-height: 50px;
                background: #fff;
                padding-left: 12px;
              "
            >
              参考号：{{ orderInfo.batchCode }}
            </div>
            <div class="page-order-detail-bottom-title">
              免责声明:平台作为连接马来西亚移民局官网提交签证的便捷工具，仅提供提交签证申请及下载签证页的功能，签证申请提交
              后，超过3天状态未变更，请及时联系客服处理，平台不对任何签证结果负责。
            </div>
            <!-- 护照/个人信息 -->
            <div class="detail-item-box" v-if="userList.length">
              <div
                class="detail-item-title"
                @click="showPassport = !showPassport"
              >
                护照/个人信息
                <i
                  :class="[
                    showPassport ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                  ]"
                ></i>
              </div>
              <div
                class="detail-item-content"
                v-show="showPassport"
                v-loading="rightInfoLoading"
              >
                <div class="detail-item-content-top">
                  <div class="top-item">
                    <span class="required">护照照片</span>
                    <span
                      style="color: #358eea; margin-left: 20px; cursor: pointer"
                      @click="openSpecification(1)"
                      >规范说明</span
                    >
                    <div class="img-box">
                      <div
                        v-if="passPortInfo.url"
                        style="width: 100%; height: 100%"
                      >
                        <VueCropper
                          ref="preview"
                          :img="passPortInfo.url"
                          outputType="png"
                          :canMove="true"
                          :autoCrop="false"
                          :infoTrue="true"
                          mode="contain"
                        >
                        </VueCropper>
                      </div>
                      <div class="btn-box" v-if="passPortInfo.url">
                        <el-button
                          type="success"
                          icon="el-icon-zoom-in"
                          size="mini"
                          @click="changeScale(1, 'preview')"
                        ></el-button>
                        <el-button
                          type="success"
                          icon="el-icon-zoom-out"
                          size="mini"
                          @click="changeScale(-1, 'preview')"
                        ></el-button>
                      </div>
                    </div>
                    <div class="img-btn">
                      <el-upload
                        action=""
                        :http-request="upPassport"
                        class="upload-style"
                        :show-file-list="false"
                      >
                        <el-button class="top-item-btn" v-show="!editFlag"
                          >上传护照照片</el-button
                        >
                      </el-upload>
                      <el-button
                        class="top-item-btn nobg-btn"
                        @click="editPassPort"
                        v-show="!editFlag"
                        >编辑</el-button
                      >
                    </div>
                  </div>
                  <div class="top-item">
                    <el-form
                      :model="ruleForm"
                      :rules="rules"
                      ref="ruleFormTop"
                      label-width="110px"
                      label-position="top"
                      class="right-form"
                    >
                      <el-row :gutter="24">
                        <el-col :span="12">
                          <el-form-item
                            label="护照号"
                            prop="customer_PassportNumber"
                          >
                            <el-input
                              v-model="ruleForm.customer_PassportNumber"
                              placeholder="请填写护照号码"
                              :disabled="editFlag"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="姓(中文)"
                            prop="customer_SurName"
                          >
                            <el-input
                              v-model="ruleForm.customer_SurName"
                              placeholder="请填写姓(中文)"
                              :disabled="editFlag"
                              @input="changeSurName"
                            ></el-input>
                            <i class="icon el-icon-arrow-right"></i>
                          </el-form-item>
                          <el-form-item label="名(中文)" prop="customer_Name">
                            <el-input
                              v-model="ruleForm.customer_Name"
                              placeholder="请填写名(中文)"
                              :disabled="editFlag"
                              @input="changeName"
                            ></el-input>
                            <i class="icon el-icon-arrow-right"></i>
                          </el-form-item>
                          <el-form-item label="性别" prop="customer_Gender">
                            <div class="demo-ruleForm-radio">
                              <el-radio
                                v-model="ruleForm.customer_Gender"
                                label="M"
                                :disabled="editFlag"
                                >男</el-radio
                              >
                              <el-radio
                                v-model="ruleForm.customer_Gender"
                                label="F"
                                :disabled="editFlag"
                                >女</el-radio
                              >
                            </div>
                          </el-form-item>
                          <el-form-item
                            label="签发地国家"
                            prop="customer_PassportCountry"
                          >
                            <el-select
                              clearable
                              v-model="ruleForm.customer_PassportCountry"
                              style="width: 174px"
                              :disabled="editFlag"
                              placeholder="签发国家"
                              filterable
                            >
                              <el-option
                                v-for="item in countryOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                :disabled="editFlag"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item
                            label="签发日期"
                            prop="customer_PassportIssue"
                          >
                            <el-date-picker
                              v-model="ruleForm.customer_PassportIssue"
                              type="date"
                              placeholder="选择签发日期"
                              :disabled="editFlag"
                              value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                          </el-form-item>
                          <el-form-item
                            label="入境类型"
                            prop="travelMalaysia_VisaType"
                          >
                            <el-select
                              clearable
                              v-model="ruleForm.travelMalaysia_VisaType"
                              style="width: 174px"
                              :disabled="editFlag"
                              placeholder="入境类型"
                              filterable
                              @change="visaTypeChage"
                            >
                              <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <el-form-item
                            label="上一个护照号"
                            prop="customer_OldPassportNumber"
                          >
                            <el-tooltip
                              class="passsort-tips"
                              effect="dark"
                              content="如果您没有上一个护照号码，请填写您当前的护照"
                              placement="top"
                            >
                              <i class="el-icon-warning"></i>
                            </el-tooltip>
                            <el-input
                              v-model="ruleForm.customer_OldPassportNumber"
                              placeholder="请填写上一个护照号码"
                              :disabled="editFlag"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="姓(拼音)"
                            prop="customer_SurnameEn"
                          >
                            <el-input
                              v-model="ruleForm.customer_SurnameEn"
                              placeholder="请填写姓(拼音)"
                              :disabled="editFlag"
                            ></el-input>
                          </el-form-item>
                          <el-form-item label="名(拼音)" prop="customer_NameEn">
                            <el-input
                              v-model="ruleForm.customer_NameEn"
                              placeholder="请填写名(拼音)"
                              :disabled="editFlag"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="出生日期"
                            prop="customer_BirthDay"
                          >
                            <el-date-picker
                              v-model="ruleForm.customer_BirthDay"
                              type="date"
                              placeholder="请选择出生日期"
                              :disabled="editFlag"
                              value-format="yyyy-MM-dd"
                              @change="BirthDayChange"
                            >
                            </el-date-picker>
                          </el-form-item>
                          <el-form-item label="手机号" prop="customer_Phone">
                            <el-input
                              v-model="ruleForm.customer_Phone"
                              placeholder="请填写手机号"
                              :disabled="editFlag"
                            ></el-input>
                          </el-form-item>
                          <el-form-item
                            label="有效期至"
                            prop="customer_PassportValidity"
                          >
                            <el-date-picker
                              v-model="ruleForm.customer_PassportValidity"
                              type="date"
                              placeholder="选择日期有效期"
                              :disabled="editFlag"
                              value-format="yyyy-MM-dd"
                            >
                            </el-date-picker>
                          </el-form-item>
                          <el-form-item
                            label="停留目的"
                            prop="travelMalaysia_Purpose"
                          >
                            <el-select
                              clearable
                              v-model="ruleForm.travelMalaysia_Purpose"
                              style="width: 174px"
                              :disabled="editFlag"
                              placeholder="停留目的"
                              filterable
                            >
                              <el-option
                                v-for="item in travelOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-form>
                  </div>
                </div>
                <div class="detail-item-content-bt">
                  <div class="bt-item">
                    <div class="bt-item-top">
                      <span class="required">个人照片</span>
                      <img
                        :src="require('@/assets/images/orderCenter/person.png')"
                        alt=""
                        v-if="!headPicInfo.url"
                      />
                      <img :src="headPicInfo.url" alt="" v-else />
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <span style="color: red">须白底且6个月近照</span>
                      </div>
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upHeadPicFn"
                          class="upload-style"
                          :show-file-list="false"
                          accept=".jpg,.png,.jpeg"
                        >
                          <el-button class="top-item-btn" v-show="!editFlag"
                            >上传个人照片</el-button
                          >
                        </el-upload>
                        <el-button
                          class="top-item-btn nobg-btn"
                          @click="openHeadEdit"
                          v-show="!editFlag"
                          >编辑</el-button
                        >
                      </div>
                    </div>
                  </div>
                  <div class="bt-item">
                    <div class="bt-item-top">
                      <span class="required">机票行程单</span>
                      <img
                        :src="
                          require('@/assets/images/orderCenter/document.png')
                        "
                        alt=""
                        v-if="!ticketItinerary.url"
                      />
                      <img
                        :src="require('@/assets/images/common/pdf.png')"
                        alt=""
                        style="cursor: pointer"
                        @click="openPdf(ticketItinerary.url)"
                        v-else
                      />
                      <p
                        v-if="ticketItinerary.url"
                        @click="openPdf(ticketItinerary.url)"
                        style="
                          cursor: pointer;
                          text-align: center;
                          color: #0094e8;
                        "
                      >
                        查看行程单
                      </p>
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upImgFn"
                          class="upload-style"
                          :show-file-list="false"
                          :data="{ docId: 23 }"
                        >
                          <el-button class="top-item-btn" v-show="!editFlag"
                            >上传文件</el-button
                          >
                        </el-upload>
                        <el-button
                          class="top-item-btn"
                          @click="randomTicket"
                          v-show="!editFlag"
                          :loading="ticketLoading"
                          >一键生成</el-button
                        >
                      </div>
                      <div class="bt-item-bottom-bt">
                        <el-button
                          class="top-item-btn nobg-btn"
                          @click="copyOtherItinerary('航班')"
                          v-show="!editFlag"
                          >复制他人的机票行程单</el-button
                        >
                      </div>
                    </div>
                  </div>

                  <div class="bt-item">
                    <div class="bt-item-top">
                      <span
                        class="required"
                        style="display: inline-block; width: 84px"
                        >带旧护照号的备注页</span
                      >
                      <el-tooltip
                        class="tips"
                        effect="dark"
                        content="没有该备注页，请上传护照照片"
                        placement="top"
                      >
                        <i class="el-icon-warning"></i>
                      </el-tooltip>
                      <img
                        :src="
                          require('@/assets/images/orderCenter/document.png')
                        "
                        alt=""
                        style="margin-top: 10px"
                        v-if="!oldPassPortInfo.url"
                      />
                      <img
                        :src="oldPassPortInfo.url"
                        alt=""
                        style="margin-top: 10px"
                        v-else
                      />
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <span
                          style="color: #0094e8; cursor: pointer"
                          @click="openSpecification(2)"
                          >规范说明</span
                        >
                      </div>
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upImgFn"
                          class="upload-style"
                          style="margin-right: 0"
                          :show-file-list="false"
                          :data="{ docId: 22 }"
                          accept=".jpg,.png,.jpeg"
                        >
                          <el-button
                            class="top-item-btn"
                            style="padding: 0 20px"
                            v-show="!editFlag"
                            >上传文件</el-button
                          >
                        </el-upload>
                      </div>
                    </div>
                  </div>
                  <div class="bt-item">
                    <div class="bt-item-top">
                      <span>其他文件</span>
                      <img
                        :src="
                          require('@/assets/images/orderCenter/document.png')
                        "
                        alt=""
                        v-if="!otherDocInfo.url"
                      />
                      <img
                        :src="require('@/assets/images/common/pdf.png')"
                        alt=""
                        style="cursor: pointer"
                        @click="openPdf(otherDocInfo.url)"
                        v-else
                      />
                      <p
                        v-if="otherDocInfo.url"
                        @click="openPdf(otherDocInfo.url)"
                        style="
                          cursor: pointer;
                          text-align: center;
                          color: #0094e8;
                        "
                      >
                        预览PDF文件
                      </p>
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upImgFn"
                          class="upload-style"
                          style="margin-right: 0"
                          :show-file-list="false"
                          :data="{ docId: 27 }"
                        >
                          <el-button
                            class="top-item-btn"
                            style="padding: 0 20px"
                            v-show="!editFlag"
                            >上传文件</el-button
                          >
                        </el-upload>
                      </div>
                    </div>
                  </div>

                  <div
                    class="bt-item"
                    v-if="!is18 && ruleForm.customer_BirthDay"
                  >
                    <div class="bt-item-top">
                      <span class="required">未成年人所需文件</span>
                      <img
                        :src="
                          require('@/assets/images/orderCenter/document.png')
                        "
                        alt=""
                        v-if="!minorityInfo.url"
                      />
                      <img
                        :src="require('@/assets/images/common/pdf.png')"
                        alt=""
                        style="cursor: pointer"
                        @click="openPdf(minorityInfo.url)"
                        v-else
                      />
                      <p
                        v-if="minorityInfo.url"
                        @click="openPdf(minorityInfo.url)"
                        style="
                          cursor: pointer;
                          text-align: center;
                          color: #0094e8;
                        "
                      >
                        预览PDF文件
                      </p>
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upImgFn"
                          class="upload-style"
                          style="margin-right: 0"
                          :show-file-list="false"
                          :data="{ docId: 21 }"
                        >
                          <el-button
                            class="top-item-btn"
                            style="padding: 0 20px"
                            v-show="!editFlag"
                            >上传文件</el-button
                          >
                        </el-upload>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="detail-item-content-bt" v-show="showInvitation">
                  <div class="bt-item">
                    <div class="bt-item-top">
                      <span class="required">邀请函</span>
                      <img
                        :src="
                          require('@/assets/images/orderCenter/document.png')
                        "
                        alt=""
                        v-if="!invitationInfo.url"
                      />
                      <img
                        :src="require('@/assets/images/common/pdf.png')"
                        alt=""
                        style="cursor: pointer"
                        @click="openPdf(invitationInfo.url)"
                        v-else
                      />
                      <p
                        v-if="invitationInfo.url"
                        @click="openPdf(invitationInfo.url)"
                        style="
                          cursor: pointer;
                          text-align: center;
                          color: #0094e8;
                        "
                      >
                        查看邀请函
                      </p>
                    </div>
                    <div class="bt-item-bottom">
                      <div class="bt-item-bottom-bt">
                        <el-upload
                          action=""
                          :http-request="upImgFn"
                          class="upload-style"
                          style="margin-right: 0"
                          :show-file-list="false"
                          :data="{ docId: 723 }"
                        >
                          <el-button
                            class="top-item-btn"
                            style="padding: 0 20px"
                            v-show="!editFlag"
                            >上传文件</el-button
                          >
                        </el-upload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 酒店信息 -->
            <div class="detail-item-box" v-if="userList.length">
              <div class="detail-item-title" @click="showHotel = !showHotel">
                酒店信息
                <i
                  :class="[
                    showHotel ? 'el-icon-arrow-down' : 'el-icon-arrow-up',
                  ]"
                ></i>
              </div>
              <div class="detail-item-content" v-show="showHotel">
                <div
                  class="detail-item-content-top"
                  style="height: 80px; padding-left: 10px"
                >
                  <div>
                    <el-button
                      class="top-item-btn nobg-btn"
                      @click="copyOtherItinerary('酒店')"
                      v-show="!editFlag"
                      >复制他人的酒店行程单</el-button
                    >
                    <el-button
                      class="top-item-btn nobg-btn"
                      @click="randomHotel"
                      v-show="!editFlag"
                      :loading="hotelLoading"
                      >一键随机</el-button
                    >
                  </div>
                </div>
                <div class="detail-item-content-hotel-bt">
                  <div class="hotel-bt-left">
                    <span class="required">酒店预订单(PDF)</span>
                    <img
                      :src="require('@/assets/images/orderCenter/document.png')"
                      alt=""
                      v-if="!hotelInfo.url"
                    />
                    <img
                      :src="require('@/assets/images/common/pdf.png')"
                      alt=""
                      style="cursor: pointer"
                      @click="openPdf(hotelInfo.url)"
                      v-else
                    />
                    <p
                      v-if="hotelInfo.url"
                      @click="openPdf(hotelInfo.url)"
                      class="priview-text"
                    >
                      预览PDF文件
                    </p>
                    <div class="bt-item-bottom-bt">
                      <el-upload
                        action=""
                        :http-request="upImgFn"
                        class="upload-style"
                        style="margin-right: 0"
                        :show-file-list="false"
                        :data="{ docId: 24 }"
                      >
                        <el-button
                          class="top-item-btn"
                          style="padding: 0 20px"
                          v-show="!editFlag"
                          >上传文件</el-button
                        >
                      </el-upload>
                      <el-checkbox
                        v-model="noNeedHotel"
                        style="margin-left: 10px"
                        >不上传</el-checkbox
                      >
                    </div>
                  </div>
                  <div class="hotel-bt-right">
                    <el-form
                      :model="ruleForm"
                      :rules="rules"
                      ref="ruleFormBottom"
                      label-width="110px"
                      label-position="top"
                      class="right-form"
                    >
                      <el-form-item
                        label="城市(英文)"
                        prop="travelMalaysia_HotelCity"
                        style="width: 47%; display: inline-block"
                      >
                        <el-input
                          v-model="ruleForm.travelMalaysia_HotelCity"
                          placeholder="请填写城市名称"
                          :disabled="editFlag"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="邮编"
                        prop="travelMalaysia_HotelZipCode"
                        style="
                          width: 47%;
                          display: inline-block;
                          margin-left: 33px;
                        "
                      >
                        <el-input
                          v-model="ruleForm.travelMalaysia_HotelZipCode"
                          placeholder="请填写城市邮编"
                          :disabled="editFlag"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="酒店或楼宇名称(英文)"
                        prop="travelMalaysia_HotelName"
                        style="position: relative"
                      >
                        <div class="hotel-web">
                          <span style="margin-left: 20px"
                            >可前往 <em @click="goOtherWeb(1)">Booking</em>或<em
                              @click="goOtherWeb(2)"
                              >携程</em
                            >查酒店地址</span
                          >
                          <em
                            style="margin-left: 159px"
                            @click="recentlyHotelDialog = true"
                            >最近使用</em
                          >
                        </div>
                        <el-input
                          v-model="ruleForm.travelMalaysia_HotelName"
                          placeholder="请填写酒店名称"
                          :disabled="editFlag"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="区域和街道名称(英文)"
                        prop="travelMalaysia_HotelAddress"
                      >
                        <el-input
                          v-model="ruleForm.travelMalaysia_HotelAddress"
                          :disabled="editFlag"
                          placeholder="选填，请填写区域和街道名称，例如: Bukit Bintang Road"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
            </div>
            <img v-if="!userList.length"
              src="@/assets/images/orderCenter/empty2.png"
              alt="暂无数据"
              style="
                width: 162px;
                height: 147px;
                position: absolute;
                top: calc(50% - 81px);
                left: calc(50% - 73px);
              "
            />
          </div>
          <div class="page-rder-detail-bottom-btn" v-if="userList.length">
            <div>
              <span :style="setTopClass(curUserItem.status)">{{
                curUserItem.statusName
              }}</span>
            </div>
            <div>
              <span
                style="
                  font-size: 16px;
                  color: #0094e8;
                  margin-right: 32px;
                  cursor: pointer;
                "
                @click="reload"
                v-show="orderInfo.status == 0 || orderInfo.status == -1"
                >刷新</span
              >
              <el-button
                class="default"
                @click="handleSave"
                v-show="
                  !editFlag && (orderInfo.status == 0 || orderInfo.status == -1)
                "
                >保存</el-button
              >
              <el-button
                class="text2"
                style="margin-right: 6px"
                @click="delUser"
                v-show="!editFlag && orderInfo.status == 0"
                >删除</el-button
              >
              <span
                style="
                  font-size: 16px;
                  color: #0094e8;
                  margin-right: 32px;
                  cursor: pointer;
                "
                @click="reloadOrderStatus"
                v-show="orderInfo.status == 2"
                >刷新申请状态</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 规范说明 -->
    <el-dialog
      title="规范说明"
      :close-on-click-modal="false"
      center
      :visible.sync="specificationShow"
      width="800px"
      class="common-dialog-class"
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name">规范说明</span>
        <div class="dialog-close" @click="specificationShow = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-form-wrap">
        <p style="color: #ff9d3d; margin-bottom: 20px">
          {{ specificationText }}
        </p>
        <div class="flex-center" style="justify-content: space-between">
          <div class="img1" style="width: 250px">
            <img :src="specificationRightUrl" alt="" />
            <span style="color: #1c9c1c">正确示范</span>
          </div>
          <div class="img2" style="width: 450px">
            <img :src="specificationWrongUrl" alt="" />
            <span style="color: #e41313">错误示范</span>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 护照编辑 -->
    <el-dialog
      class="common-dialog-class"
      width="500px"
      title="护照编辑"
      :visible.sync="passPortDialog"
      append-to-body
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name">护照编辑</span>
        <div class="dialog-close" @click="passPortDialog = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-form-wrap">
        <div style="text-align: center; width: 460px; height: 300px">
          <VueCropper
            ref="cropper"
            :img="passPortInfo.url"
            :outputSize="1"
            outputType="png"
            :info="false"
            mode="contain"
            :canScale="true"
            :autoCrop="true"
            :fixedBox="false"
            :full="false"
            :canMove="true"
            :canMoveBox="true"
            :original="false"
            :centerBox="false"
            :infoTrue="true"
          ></VueCropper>
        </div>
      </div>
      <div class="cropperBtn_box" style="margin-top: 10px">
        <el-button
          type="success"
          icon="el-icon-zoom-in"
          size="mini"
          @click="changeScale(1, 'cropper')"
        ></el-button>
        <el-button
          type="success"
          icon="el-icon-zoom-out"
          size="mini"
          @click="changeScale(-1, 'cropper')"
        ></el-button>
        <el-button
          type="success"
          icon="iconfont icon-fanzhuan"
          size="mini"
          @click="changeLeft('cropper')"
        ></el-button>
        <el-button
          type="success"
          icon="iconfont icon-fanzhuan"
          class="right"
          size="mini"
          @click="changeRight('cropper')"
        ></el-button>
      </div>
      <div
        class="common-dialog-foot-class"
        style="margin-top: 15px; justify-content: end"
      >
        <el-button @click="useOriginal('cropper')" class="top-item-btn nobg-btn"
          >使用原图</el-button
        >
        <el-button @click="updatePassPort('cropper')" class="top-item-btn">
          确认修改
        </el-button>
      </div>
    </el-dialog>
    <!-- 头像编辑 -->
    <el-dialog
      class="common-dialog-class"
      width="630px"
      title="头像编辑"
      :visible.sync="headPicDialog"
      append-to-body
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name">头像编辑</span>
        <div class="dialog-close" @click="headPicDialog = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div
        class="dialog-form-wrap"
        style="display: flex; justify-content: space-between"
      >
        <div>
          <div style="text-align: center; width: 400px; height: 300px">
            <VueCropper
              v-loading="headPic_Loading"
              ref="headPic"
              :img="headPicUrl"
              :outputSize="1"
              outputType="png"
              :info="false"
              :canScale="true"
              :autoCrop="true"
              :fixedBox="false"
              :autoCropWidth="210"
              :autoCropHeight="260"
              :fixed="false"
              :full="false"
              :canMove="true"
              :canMoveBox="false"
              :original="false"
              :centerBox="false"
              :infoTrue="false"
              @realTime="handlePreview"
            ></VueCropper>
          </div>
          <div class="cropperBtn_box" style="margin-top: 10px">
            <el-button
              type="success"
              icon="el-icon-zoom-in"
              size="mini"
              @click="changeScale(1, 'headPic')"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-zoom-out"
              size="mini"
              @click="changeScale(-1, 'headPic')"
            ></el-button>
            <el-button
              type="success"
              icon="iconfont icon-fanzhuan"
              size="mini"
              @click="changeLeft('headPic')"
            ></el-button>
            <el-button
              type="success"
              icon="iconfont icon-fanzhuan"
              class="right"
              size="mini"
              @click="changeRight('headPic')"
            ></el-button>
          </div>
        </div>
        <div class="dialog-right-info">
          <img :src="previewImg" />
          <p>1、照片尺寸(50mm*35mm)</p>
          <p>2、无边框</p>
          <p>3、清晰彩照,白底照片</p>
          <p>4、头部居中</p>
          <p>5、6个月近照</p>
          <p>6、全脸免冠，不得佩戴眼镜</p>
        </div>
      </div>
      <div
        class="common-dialog-foot-class"
        style="margin-top: 15px; justify-content: end"
      >
        <el-button @click="useOriginal('headPic')" class="top-item-btn nobg-btn"
          >使用原图</el-button
        >
        <el-button @click="setWhiteBg" class="top-item-btn nobg-btn"
          >设置背景为白色</el-button
        >
        <el-button @click="updateHeadPic('headPic')" class="top-item-btn">
          确认修改
        </el-button>
      </div>
    </el-dialog>
    <!-- 最近使用的航班/酒店行程文件 -->
    <el-dialog
      title="规范说明"
      :close-on-click-modal="false"
      center
      :visible.sync="otherItineraryShow"
      width="800px"
      class="common-dialog-class"
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name"
          >最近使用的{{ otherType }}行程文件</span
        >
        <div class="dialog-close" @click="otherItineraryShow = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-form-wrap">
        <div class="other-box">
          <div
            class="other-item"
            v-for="(item, index) in otherItineraryList"
            :key="index"
          >
            <i class="el-icon-document"></i>
            <el-tooltip
              class="tips"
              effect="dark"
              :content="item.name"
              placement="top"
            >
              <div class="s-title" @click="openPdf(item.path)">
                {{ item.name }}
              </div>
            </el-tooltip>
            <el-button class="top-item-btn" @click="useOther(item)"
              >立即使用</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 酒店使用记录 -->
    <el-dialog
      title="酒店使用记录"
      :close-on-click-modal="false"
      center
      :visible.sync="recentlyHotelDialog"
      width="700px"
      class="common-dialog-class"
      @open="getRecentHotel"
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name"
          >酒店使用记录 <span style="color: #999">最近5条</span>
        </span>
        <div class="dialog-close" @click="recentlyHotelDialog = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-form-wrap">
        <template v-if="recentHotelList.length">
          <div
            :class="['hotel-item', { active: recentHotelIndex == index }]"
            v-for="(item, index) in recentHotelList"
            :key="index"
            @click="recentHotelIndex = index"
          >
            <p>城市:{{ item.hotelCity }}</p>
            <p>酒店名称:{{ item.hotelName }}</p>
            <p>邮编:{{ item.hotelZipCode }}</p>
            <el-tooltip
              class="tips"
              effect="dark"
              :content="item.hotelAddress"
              placement="top"
            >
              <p>地址: {{ item.hotelAddress }}</p>
            </el-tooltip>
          </div>
        </template>
        <p style="text-align: center" v-else>暂无数据</p>
      </div>
      <div
        class="common-dialog-foot-class"
        style="margin-top: 15px"
        v-if="recentHotelList.length"
      >
        <el-button @click="useRecentlyHotel" class="top-item-btn nobg-btn"
          >立即使用</el-button
        >
      </div>
    </el-dialog>
    <!-- 选择账号 -->
    <el-dialog
      title="选择账号"
      :close-on-click-modal="false"
      center
      :visible.sync="choseAccountDialog"
      width="700px"
      class="common-dialog-class"
      @open="getAccount"
    >
      <div class="dialog-member-title">
        <span class="dialog-member-name">选择账号</span>
        <div class="dialog-close" @click="choseAccountDialog = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="dialog-form-wrap">
        <el-table
          :data="accountList"
          ref="accountTable"
          :header-cell-style="{
            backgroundColor: 'none',
            color: '#333',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '10px',
          }"
          height="300"
          :cell-style="{ 'text-align': 'center' }"
          @open="getAccount"
        >
          <el-table-column prop="email" label="账号名称" />
          <el-table-column prop="statusName" label="状态" />
          <el-table-column prop="address" label="地址信息" />
          <el-table-column prop="reMarks" label="备注" />
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="choseAccount(scope.row)"
                >选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <submitApplication
      :visible="submitApplicationShow"
      :orderId="id"
      @confirm="handleSubmit"
      @close="submitApplicationShow = false"
    ></submitApplication>

    <addUserInfoTwo
      :config="newAddUserShow"
      :isAddOrder="false"
      :visaDocsInfo="orderDetail.visaDetail.visaDocs"
      :order="orderDetail.order"
      v-if="newAddUserShow"
      @addUserFn="addUserFn"
      @close="newAddUserShow = false"
    >
    </addUserInfoTwo>
  </div>
</template>

<script>
import {
  orderdetail,
  addorderapplicants,
  queryorderapplicants,
  cancelorder,
  postuploadpassport,
  savedocimg,
} from "@/api/order.js";
import {
  getpassengerinfo,
  savepassengerinfo,
  uploadimgtopdf,
  setwhitebg,
  randomplaneticket,
  getplanetickets,
  gethotels,
  randomhotel,
  delorderapplicant,
  gethoteladdresss,
  getorgemaillist,
  savemalaysia,
  agentsubmit,
  orderpaid,
  orderexinfo,
} from "@/api/orderCenter.js";
import addUserInfoTwo from "@/components/addUserInfoTwo.vue";
import uploadTwo from "@/components/uploadTwo.vue";
import alyoss from "@/utils/alyoss";
import rightPass from "@/assets/images/orderCenter/right-pass.png";
import wrongPass from "@/assets/images/orderCenter/wrong-pass.png";
import rightPassOld from "@/assets/images/orderCenter/right-pass-old.png";
import wrongPassOld from "@/assets/images/orderCenter/wrong-pass-old.png";
import { VueCropper } from "vue-cropper";
import submitApplication from "../electron/components/submitApplication.vue";
import ChineseHelper from "@/utils/ChineseHelper";
export default {
  data() {
    return {
      id: "",
      statusSelect: [],
      userList: [],
      showMore: false,
      newAddUserShow: false,
      pageIndex: 1,
      pageSize: 10,
      checkAll: true,
      total: 0,
      orderDetail: {
        order: {},
        orderApplicants: [],
        visaDetail: {
          basicInfo: [],
          offlineVisaDocs: [],
          visa: {},
          visaDocs: [],
        },
      },
      dateInfo: {
        date: "",
      },
      userId: "",
      submitIngStatus: 10, // 提交中状态
      applicantstatusTimer: null,
      applicationLoading: false,
      submitApplicationShow: false,
      showPassport: true,
      ruleForm: {},
      rules: {
        customer_PassportNumber: [
          { required: true, validator: this.PassportNumber, trigger: "blur" },
        ],
        customer_OldPassportNumber: [
          { required: true, validator: this.PassportNumber, trigger: "blur" },
        ],
        // customer_SurnameEn: [
        //   { validator: this.SurnameEn, trigger: 'change' }
        // ],
        customer_NameEn: [
          { required: true, validator: this.NameEn, trigger: "blur" },
        ],
        customer_BirthDay: [
          { required: true, message: "请选择出生日期", trigger: "change" },
          { validator: this.BirthDay, trigger: "change" },
        ],
        customer_PassportValidity: [
          {
            required: true,
            message: "请选择有效期",
            trigger: "change",
          },
          {
            validator: this.PassportValidity,
            trigger: "change",
          },
        ],
        customer_PassportIssue: [
          { required: true, message: "请选择签发日期", trigger: "change" },
        ],
        customer_PassportCountry: [
          { required: true, message: "请选择签发地国家", trigger: "change" },
        ],
        travelMalaysia_Purpose: [
          { required: true, message: "请选择停留目的", trigger: "change" },
        ],
        travelMalaysia_HotelCity: [
          { required: true, message: "请填写城市名", trigger: "blur" },
        ],
        travelMalaysia_HotelZipCode: [
          { required: true, message: "请填写城市邮编", trigger: "blur" },
        ],
        travelMalaysia_HotelName: [
          { required: true, message: "请填写酒店名", trigger: "blur" },
        ],
        customer_Phone: [
          { required: true, message: "请填写手机号", trigger: "blur" },
        ],
      },
      typeOptions: [
        { label: "单次入境", value: "1" },
        { label: "多次入境", value: "2" },
      ],
      travelOptions: [
        { label: "旅游", value: "20" },
        { label: "商务", value: "2" },
      ],
      countryOptions: [
        { value: "AND", label: "安道尔" },
        { value: "ARE", label: "阿拉伯联合酋长国" },
        { value: "AFG", label: "阿富汗" },
        { value: "ATG", label: "安提瓜和巴布达" },
        { value: "AIA", label: "安圭拉" },
        { value: "ALB", label: "阿尔巴尼亚" },
        { value: "ARM", label: "美国" },
        { value: "ANT", label: "荷属安的列斯群岛" },
        { value: "AGO", label: "安哥拉" },
        { value: "ARG", label: "阿根廷" },
        { value: "ASM", label: "美属萨摩亚" },
        { value: "AUT", label: "奥地利" },
        { value: "AUS", label: "澳大利亚" },
        { value: "AZE", label: "阿塞拜疆" },
        { value: "BGD", label: "孟加拉" },
        { value: "BEL", label: "比利时" },
        { value: "BHR", label: "巴林" },
        { value: "BEN", label: "贝宁" },
        { value: "BRA", label: "巴西" },
        { value: "CAN", label: "加拿大" },
        { value: "COG", label: "刚果" },
        { value: "CHE", label: "瑞士" },
        { value: "CMR", label: "喀麦隆" },
        { value: "CHN", label: "中国" },
        { value: "CZE", label: "捷克" },
        { value: "DEU", label: "德国" },
        { value: "DNK", label: "丹麦" },
        { value: "DZA", label: "阿尔及利亚" },
        { value: "EGY", label: "埃及" },
        { value: "ESP", label: "西班牙" },
        { value: "ETH", label: "埃塞俄比亚" },
        { value: "FIN", label: "芬兰" },
        { value: "FRA", label: "法国" },
        { value: "GEO", label: "格鲁吉亚" },
        { value: "GHA", label: "加纳" },
        { value: "GIN", label: "几内亚" },
        { value: "GNQ", label: "赤道几内亚" },
        { value: "GRC", label: "希腊" },
        { value: "HRV", label: "克罗地亚" },
        { value: "HUN", label: "匈牙利" },
        { value: "IDN", label: "印度尼西亚" },
        { value: "IRL", label: "爱尔兰" },
        { value: "IRN", label: "伊朗" },
        { value: "ISL", label: "冰岛" },
        { value: "ITA", label: "意大利" },
        { value: "JPN", label: "日本" },
        { value: "KGZ", label: "吉尔吉斯斯坦" },
        { value: "KHM", label: "柬埔寨" },
        { value: "KOR", label: "韩国" },
        { value: "KWT", label: "科威特" },
        { value: "KAZ", label: "哈萨克斯坦" },
        { value: "LAO", label: "老挝" },
        { value: "LKA", label: "斯里兰卡" },
        { value: "LVA", label: "拉脱维亚" },
        { value: "MMR", label: "缅甸" },
        { value: "MNG", label: "蒙古" },
        { value: "MWI", label: "马拉维" },
        { value: "MEX", label: "墨西哥" },
        { value: "MYS", label: "马来西亚" },
        { value: "NER", label: "尼日尔" },
        { value: "NLD", label: "荷兰" },
        { value: "NOR", label: "挪威" },
        { value: "NPL", label: "尼泊尔" },
        { value: "NZL", label: "新西兰" },
        { value: "OMN", label: "阿曼" },
        { value: "PHL", label: "菲律宾" },
        { value: "PAK", label: "巴基斯坦" },
        { value: "POL", label: "波兰" },
        { value: "PRT", label: "葡萄牙" },
        { value: "SAU", label: "沙特阿拉伯" },
        { value: "SWE", label: "瑞典" },
        { value: "SGP", label: "新加坡" },
        { value: "SVN", label: "斯洛文尼亚" },
        { value: "SEN", label: "塞内加尔" },
        { value: "TCD", label: "乍得" },
        { value: "THA", label: "泰国" },
        { value: "TJK", label: "塔吉克斯坦" },
        { value: "TUR", label: "土耳其" },
        { value: "UZB", label: "乌兹别克斯坦" },
        { value: "ZAF", label: "南非" },
      ],
      showHotel: true,
      noNeedHotel: false,
      hotelForm: {},
      specificationShow: false,
      specificationText: "",
      specificationRightUrl: "",
      specificationWrongUrl: "",
      rightPass: rightPass,
      wrongPass: wrongPass,
      rightPassOld: rightPassOld,
      wrongPassOld: wrongPassOld,
      secretKey: "",
      passPortInfo: {
        //顶部护照
        url: "",
      },
      headPicInfo: {},
      passPortDialog: false, //编辑护照弹窗
      is18: false, // 是否大于18岁
      headPicUrl: "",
      headPic_Loading: false,
      headPicDialog: false,
      previewImg: "",
      oldPassPortInfo: {}, //旧护照备注页
      ticketItinerary: {}, //机票行程单
      otherItineraryList: [], //他人行程单
      otherType: "",
      otherItineraryShow: false,
      otherDocInfo: {}, //其他文件
      hotelInfo: {}, //酒店行程单
      minorityInfo: {}, //未成年文件
      invitationInfo: {}, //邀请函
      curUserItem: {}, //当前选中的申请人
      editFlag: false,
      ticketLoading: false, //机票一键随机
      hotelLoading: false, //酒店一键随机
      recentlyHotelDialog: false,
      recentHotelList: [],
      recentHotelIndex: 0,
      rightInfoLoading: false, //右侧数据刷新加载中
      choseAccountDialog: false,
      accountList: [],
      orgVisaEmailId: "",
      // 待提交使馆 = 0,  显示提交到大使馆
      // 提交失败 = -1,   显示重新提交到大使馆
      // 支付失败 = -3,   显示支付失败提示信息
      // 提交使馆中 = 2,  提交后展示处理中
      // 待支付费用 = 4,  展示立即付款  与 我已付款按钮
      // 查询支付中 = 6,  显示  查询支付中
      // 等待出签中 = 8,  显示  等待出签中
      // 已出签 = 10,		  下载签证页
      orderInfo: {
        //订单信息汇总
        status: "",
        email: "",
      },
      zoom: 1,
    };
  },
  components: {
    addUserInfoTwo,
    uploadTwo,
    VueCropper,
    submitApplication,
  },
  created() {
    const devicePixelRatio = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    if (devicePixelRatio !== 1) {
      // 老ie可以提示
      if (!!window.ActiveXObject || "ActiveXObject" in window) {
        alert("浏览器版本过低，请更换！");
      } else {
        this.zoom = document.body.offsetWidth / 1920;
      }
    }
    document.body.style.setProperty("--zoom", this.zoom);
  },
  mounted() {
    this.id = this.$route.query.id;
    this.userId = this.$route.query.userId;
    this.orderdetailFn();
    this.queryorderapplicantsFn();
    this.pollingOrderApplicantStatus(); //获取初始订单状态
    document.getElementsByClassName("order-wrap")[0].style.zoom = this.zoom;
  },
  computed: {
    setStatus() {
      return function (status) {
        if (status == -4 || status == -2 || status == -3 || status == 2) {
          return "sty-red";
        } else if (status == 0 || status == 1 || status == 3) {
          return "sty-blue";
        } else {
          return "sty-yello";
        }
      };
    },

    setTopClass() {
      return function (identityType) {
        if ([0, 2, 14, 6, 11, 18].includes(identityType)) {
          return "color:#eebb17"; //黄色
        } else if ([8, 10, 12, 16].includes(identityType)) {
          return "color:#1c9c1c"; //绿色
        } else {
          return "color:#e41313"; //红色
        }
      };
    },

    showInvitation() {
      // 入境类型多次 || 入境类型单次->停留目的：商务   需要显示邀请函
      return (
        this.ruleForm.travelMalaysia_VisaType == 2 ||
        this.ruleForm.travelMalaysia_Purpose == 2
      );
    },
  },
  methods: {
    PassportNumber(rule, value, callback) {
      if (value == "") {
        callback(new Error("请输入护照号码"));
      } else {
        if (
          this.ruleForm.customer_PassportCountryName == "中国" &&
          !/^.{9}$/.test(value)
        ) {
          callback(new Error("护照号码格式出错"));
        } else {
          callback();
        }
      }
    },
    SurnameEn(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入拼音姓"));
      } else if (!/^[A-Za-z]+$/.test(value)) {
        callback(new Error("请输入拼音"));
      } else {
        callback();
      }
    },
    NameEn(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入拼音名"));
      } else {
        if (!/^[A-Za-z]+$/.test(value)) {
          callback(new Error("请输入拼音"));
        } else {
          callback();
        }
      }
    },
    BirthDay(rule, value, callback) {
      if (value == "") {
        callback(new Error("请输入出生日期"));
      } else {
        if (this.ruleForm.customer_PassportIssue) {
          let [a, b, c] = value.split("-");
          let [A, B, C] = this.ruleForm.customer_PassportIssue.split("/");
          if (A > a || (A == a && B > b) || (A == a && B == b && C > c)) {
            callback();
          } else {
            callback(new Error("请输入正确的出生日期"));
          }
        } else {
          callback();
        }
      }
    },
    BirthDayChange(value) {
      // 根据出生日期判断身份证与出生证显示与否
      let is = this.$moment().subtract(18, "years").calendar();
      if (value) {
        let separator = value.indexOf("/") > -1 ? "/" : "-";
        let [a, b, c] = value.split(separator);
        let [A, B, C] = is.split("/");
        if (A > a || (A == a && B > b) || (A == a && B == b && C > c)) {
          this.is18 = true;
        } else {
          this.is18 = false;
        }
      } else {
        this.is18 = false;
      }
    },
    PassportValidity(rule, value, callback) {
      // 有效期验证
      if (value == "") {
        callback(new Error("请选择有效期"));
      } else {
        let isNow = this.$moment().format("YYYY[/]MM[/]DD");
        let [a, b, c] = value.split("/");
        let [A, B, C] = isNow.split("/");
        if (A < a || (A == a && B < b) || (A == a && B == b && C < c)) {
          callback();
        } else {
          callback(new Error("该护照已过期"));
        }
      }
    },
    //取消订单
    cancelOrder() {
      this.$confirm(`是否要取消该订单`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelorder({ id: this.orderDetail.order.id }).then((res) => {
            this.$message.success("取消成功！");
            this.orderdetailFn();
          });
        })
        .catch((res) => {});
    },
    //booking  or  ctrip
    goOtherWeb(num) {
      let url =
        num === 1
          ? "https://www.booking.com/pipl_consent.zh-cn.html?target_page=https%3A%2F%2Fwww.booking.com%2Findex.zh-cn.html%3Flabel%3Dgen173rf-1BCAEoggI46AdIM1gDaDGIAQGYASu4ARfIAQzYAQHoAQGIAgGiAgxiLnZpc2FhaS5jb22oAgO4AtyS7KoGwAIB0gIkZWY4MTk4Y2UtNTc3MS00ODhmLTg3MDYtYjA3ZGNjMjdhYzhh2AIF4AIB%26sid%3Db52d286eb7540b08427140ee27c489eb%26keep_landing%3D1%26sb_price_type%3Dtotal%26&aid=304142"
          : "https://hotels.ctrip.com/international/";
      window.open(url, "_blank");
    },
    //规范说明弹窗
    openSpecification(num) {
      this.specificationText =
        num === 1
          ? "护照照片: 需完整的护照第1页和第2页，且方向必须按以下示例的方向"
          : "写了旧护照号的备注页: 需确保页面完整显示，且方向必须按以下示例的方向";
      this.specificationRightUrl =
        num === 1 ? this.rightPass : this.rightPassOld;
      this.specificationWrongUrl =
        num === 1 ? this.wrongPass : this.wrongPassOld;
      this.specificationShow = true;
    },
    //获取右边详细信息
    getpassengerinfoFn(changeBaseData = false) {
      getpassengerinfo({
        orderApplicantId: this.userId,
        secretKey: this.secretKey,
      }).then((res) => {
        if (changeBaseData) {
          this.ruleForm = JSON.parse(JSON.stringify(res.data.baseData));
        }
        if (this.ruleForm.customer_BirthDay) {
          this.BirthDayChange(this.ruleForm.customer_BirthDay.slice(0, 10));
        }
        this.editFlag =
          this.ruleForm.status != 0 &&
          this.ruleForm.status != 3 &&
          this.ruleForm.status != 4;
        this.ruleForm.customer_PassportCountry =
          this.ruleForm.customer_PassportCountry || "CHN";
        this.ruleForm.customer_OldPassportNumber =
          this.ruleForm.customer_OldPassportNumber ||
          this.ruleForm.customer_PassportNumber;
        this.ruleForm.travelMalaysia_VisaType =
          this.ruleForm.travelMalaysia_VisaType || "1"; //入境类型为空时默认为单次: 1
        this.ruleForm.travelMalaysia_Purpose =
          this.ruleForm.travelMalaysia_Purpose || "20"; //入停留目的为空默认为旅游：20
        if (this.ruleForm.travelMalaysia_VisaType == 2) {
          this.travelOptions = [
            { label: "邮轮", value: "9" },
            { label: "商务", value: "10" },
            { label: "医疗", value: "52" },
            { label: "婚礼", value: "11" },
          ];
        }
        this.passPortInfo = {};
        this.headPicInfo = {};
        this.oldPassPortInfo = {};
        this.ticketItinerary = {};
        this.otherDocInfo = {};
        this.hotelInfo = {};
        this.minorityInfo = {};
        this.invitationInfo = {};
        if (res.data.docs && res.data.docs.length) {
          if (res.data.docs.filter((v) => v.docId == 2).length) {
            //护照首页:2
            this.passPortInfo = res.data.docs.filter((v) => v.docId == 2)[0];
          }
          if (res.data.docs.filter((v) => v.docId == 10).length) {
            //个人照片 ：10
            this.headPicInfo = res.data.docs.filter((v) => v.docId == 10)[0];
          }
          if (res.data.docs.filter((v) => v.docId == 22).length) {
            //旧护照备注页:22
            this.oldPassPortInfo = res.data.docs.filter(
              (v) => v.docId == 22
            )[0];
          }
          if (res.data.docs.filter((v) => v.docId == 23).length) {
            //机票行程单:23
            this.ticketItinerary = res.data.docs.filter(
              (v) => v.docId == 23
            )[0];
          }
          if (res.data.docs.filter((v) => v.docId == 27).length) {
            //其他文件:27
            this.otherDocInfo = res.data.docs.filter((v) => v.docId == 27)[0];
          }
          if (res.data.docs.filter((v) => v.docId == 24).length) {
            //酒店行程单:24
            this.hotelInfo = res.data.docs.filter((v) => v.docId == 24)[0];
          }
          if (res.data.docs.filter((v) => v.docId == 21).length) {
            //未成年:21
            this.minorityInfo = res.data.docs.filter((v) => v.docId == 21)[0];
          }
          if (res.data.docs.filter((v) => v.docId == 723).length) {
            //邀请函:723
            this.invitationInfo = res.data.docs.filter(
              (v) => v.docId == 723
            )[0];
          }
        }
        this.rightInfoLoading = false;
      });
    },
    //获取左边申请人表格数据
    queryorderapplicantsFn(isTableLoading) {
      let vm = this;
      if (isTableLoading) {
        this.applicationLoading = true;
      }
      queryorderapplicants({
        OrderId: this.id,
        "Page.Size": this.pageSize,
        "Page.Index": this.pageIndex,
      })
        .then((res) => {
          this.total = res.data.total;
          this.userList = res.data.rows;
          if (this.userId) {
            vm.userList.forEach((item) => {
              if (item.id == this.userId) {
                this.$nextTick(() => {
                  this.$refs.tableOrder &&
                    this.$refs.tableOrder.setCurrentRow(item);
                });
                this.secretKey = item.secretKey;
                this.curUserItem = item;
              }
            });
          } else {
            vm.userId = vm.userList[0].id;
            this.secretKey = vm.userList[0].secretKey;
            this.$nextTick(() => {
              this.$refs.tableOrder &&
                this.$refs.tableOrder.setCurrentRow(this.userList[0]);
              this.curUserItem = this.userList[0];
            });
          }
          this.getpassengerinfoFn(true);
          this.applicationLoading = false;
        })
        .catch(() => {
          this.applicationLoading = false;
        });
    },
    setColl(row) {
      this.userId = row.id;
      this.secretKey = row.secretKey;
      this.curUserItem = row;
      this.getpassengerinfoFn(true);
    },
    editPassPort() {
      if (!this.passPortInfo.url) {
        return this.$message.warning("请先上传护照！");
      }
      this.passPortDialog = true;
      setTimeout(() => {
        this.$refs.cropper.refresh();
      }, 200);
    },
    changeScale(num, ref) {
      // 剪裁图放大缩小
      num = num || 1;
      this.$refs[ref].changeScale(num);
    },
    changeLeft(ref) {
      // 剪裁图左旋转90度
      this.$refs[ref].rotateLeft();
    },
    changeRight(ref) {
      // 剪裁图右旋转90度
      this.$refs[ref].rotateRight();
    },
    useOriginal(ref) {
      //使用原图
      this.$refs[ref].refresh();
    },
    updatePassPort(ref) {
      // 护照完成编辑
      this.$refs[ref].getCropBlob((blob) => {
        let file = new File([blob], ".png", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.upPassport({ file });
        this.passPortDialog = false;
      });
    },
    //识别护照信息
    async upPassport(file) {
      this.rightInfoLoading = true;
      let alyossData = await alyoss(file.file);
      postuploadpassport({
        orderApplicantId: this.userId,
        docId: 2,
        url: alyossData,
      }).then((res) => {
        if (res.code == 1) {
          this.$message.success("识别成功！");
          this.BirthDayChange(res.data.customer_BirthDay);
          this.rightInfoLoading = false;
          this.getpassengerinfoFn(true);
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    async upImgFn(file, url) {
      //docId 个人照片 ：10 ;旧护照备注页:22 ; 护照首页:2    jpg/png
      //其他资料 ：27; 出生证(未成年) : 21; 机票行程单:23 ; 酒店预订单 :24; 邀请函:723  若非PDF 通过接口转换为pdf
      if (file.data.docId === 10 || file.data.docId === 22) {
        if (
          file.file &&
          file.file.name.indexOf("jpg") < 0 &&
          file.file.name.indexOf("png") < 0
        ) {
          this.$message.warning(
            `${
              file.data.docId === 10 ? "个人照片" : "旧护照备注页"
            }请上传jpg或者png格式图片！`
          );
          return;
        }
      }
      let alyossData = url || (await alyoss(file.file));
      let reqData = {
        orderApplicantId: this.userId,
        docId: file.data.docId,
        url: alyossData,
      };

      if (file.data.docId === 10 || file.data.docId === 22) {
        savedocimg(reqData).then((res) => {
          this.getpassengerinfoFn();
          this.$message.success("上传成功！");
        });
      } else {
        uploadimgtopdf(reqData).then((res) => {
          this.getpassengerinfoFn();
          this.$message.success("上传成功！");
          this.ticketLoading = false;
          this.hotelLoading = false;
        });
      }
    },
    openHeadEdit() {
      this.headPicUrl = this.headPicInfo.url;
      console.log("头像：" + this.headPicUrl);
      this.headPicDialog = true;
    },
    async upHeadPicFn(file) {
      let headUrl = await alyoss(file.file);
      this.headPicUrl = headUrl;
      this.headPicDialog = true;
    },
    setWhiteBg() {
      this.headPic_Loading = true;
      setwhitebg({ url: this.headPicUrl }).then((res) => {
        this.headPicUrl = res.data;
        this.headPic_Loading = false;
      });
    },
    //头像编辑
    updateHeadPic() {
      this.$refs.headPic.getCropBlob((blob) => {
        let file = new File([blob], ".png", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.upImgFn({ data: { docId: 10 }, file });
        this.headPicDialog = false;
      });
    },
    //头像实时预览
    handlePreview(data) {
      this.$refs.headPic.getCropBlob((data) => {
        this.blobToDataURI(data, (res) => {
          this.previewImg = res;
        });
      });
    },
    blobToDataURI(blob, callback) {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function (e) {
        callback(e.target.result);
      };
    },
    //预览PDF
    openPdf(url) {
      window.open(url);
    },
    //一键生成机票行程单
    randomTicket() {
      this.ticketLoading = true;
      randomplaneticket({ orderApplicantId: this.userId, templeType: 4 }).then(
        (res) => {
          this.upImgFn({ data: { docId: 23 } }, res.data);
        }
      );
    },
    //复制他人机票 or  酒店 行程单
    copyOtherItinerary(type) {
      this.otherType = type;
      if (type == "航班") {
        getplanetickets({
          orderApplicantId: this.userId,
          systemVisaId: this.orderDetail.visaDetail.visa.systemVisaId,
        }).then((res) => {
          this.otherItineraryList = res.data.map((v) => {
            let arr = v.path.split("."),
              arr1 = arr[arr.length - 2].split("/");
            return {
              name: arr1[arr1.length - 1] + "." + arr[arr.length - 1],
              ...v,
            };
          });
          this.otherItineraryShow = true;
        });
      } else {
        gethotels({
          orderApplicantId: this.userId,
          systemVisaId: this.orderDetail.visaDetail.visa.systemVisaId,
        }).then((res) => {
          this.otherItineraryList = res.data.map((v) => {
            return { name: v.hotelCity + " " + v.hotelName, ...v };
          });
          this.otherItineraryShow = true;
        });
      }
    },
    //一键生成酒店行程单
    randomHotel() {
      this.hotelLoading = true;
      randomhotel({ orderApplicantId: this.userId, templeType: 4 }).then(
        (res) => {
          this.ruleForm.travelMalaysia_HotelCity = res.data.hotelCity;
          this.ruleForm.travelMalaysia_HotelName = res.data.hotelName;
          this.ruleForm.travelMalaysia_HotelAddress = res.data.hotelAddress;
          this.ruleForm.travelMalaysia_HotelZipCode = res.data.hotelZipCode;
          this.upImgFn({ data: { docId: 24 } }, res.data.path);
        }
      );
    },
    //立即使用他人行程单
    useOther(item) {
      if (this.otherType == "航班") {
        this.upImgFn({ data: { docId: 23 } }, item.path);
      } else {
        this.ruleForm.travelMalaysia_HotelCity = item.hotelCity;
        this.ruleForm.travelMalaysia_HotelName = item.hotelName;
        this.ruleForm.travelMalaysia_HotelAddress = item.hotelAddress;
        this.ruleForm.travelMalaysia_HotelZipCode = item.hotelZipCode;
        this.upImgFn({ data: { docId: 24 } }, item.path);
      }
      this.otherItineraryShow = false;
    },
    //刷新申请人状态
    reload() {
      this.rightInfoLoading = true;
      this.$refs.contentBox.scrollTop = 0;
      // this.userId = this.curUserItem.id
      // this.getpassengerinfoFn(true)
      this.queryorderapplicantsFn();
    },
    //刷新订单状态
    reloadOrderStatus() {
      this.pollingOrderApplicantStatus();
    },
    //保存
    handleSave() {
      let isDone = true,
        msg = "";
      if (!Object.keys(this.passPortInfo).length) {
        msg = "护照照片";
      }
      if (!Object.keys(this.headPicInfo).length) {
        msg = "个人照片";
      }
      if (!Object.keys(this.ticketItinerary).length) {
        msg = "机票行程单";
      }
      if (!this.is18 && !Object.keys(this.minorityInfo).length) {
        msg = "未成年人所需文件";
      }
      if (!this.noNeedHotel && !Object.keys(this.hotelInfo).length) {
        msg = "酒店预订单";
      }
      if (!Object.keys(this.invitationInfo).length) {
        msg = "邀请函";
      }
      if (msg) {
        isDone = false;
      }
      this.$refs.ruleFormTop.validate((valid) => {
        if (!valid) {
          isDone = false;
        }
      });
      this.$refs.ruleFormBottom.validate((valid1) => {
        if (!valid1) {
          isDone = false;
        }
      });
      if (!isDone) {
        this.$message.warning(msg + "信息不完善！");
        return;
      } else {
        savepassengerinfo({ secretKey: this.secretKey, ...this.ruleForm }).then(
          (res) => {
            savemalaysia({ orderApplicantId: this.userId }).then((res) => {
              if (res.code == 1) {
                this.$message.success("保存成功");
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
          }
        );
      }
    },
    //删除申请人
    delUser() {
      this.$confirm(`是否要要删除该申请人`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delorderapplicant({
            Id: this.userId,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.userId = "";
              setTimeout(() => {
                this.queryorderapplicantsFn();
              }, 200);
            }
          });
        })
        .catch((res) => {});
    },
    //获取最近使用的5条酒店地址
    getRecentHotel() {
      if (this.recentHotelList.length) return;
      gethoteladdresss({
        systemVisaId: this.orderDetail.visaDetail.visa.systemVisaId,
        orderapplicantId: this.userId,
      }).then((res) => {
        this.recentHotelList = res.data && res.data.slice(0, 5);
      });
    },
    useRecentlyHotel() {
      let recentHotel = this.recentHotelList[this.recentHotelIndex];
      this.ruleForm.travelMalaysia_HotelCity = recentHotel.hotelCity;
      this.ruleForm.travelMalaysia_HotelName = recentHotel.hotelName;
      this.ruleForm.travelMalaysia_HotelAddress = recentHotel.hotelAddress;
      this.ruleForm.travelMalaysia_HotelZipCode = recentHotel.hotelZipCode;
      this.recentHotelIndex = 0;
      this.recentlyHotelDialog = false;
    },
    //获取可使用的账号
    getAccount() {
      getorgemaillist({ countryId: this.orderDetail.order.countryId }).then(
        (res) => {
          this.accountList = res.data;
        }
      );
    },
    //选择账号
    choseAccount(row) {
      this.$confirm(`已经审核了所有人的签证信息`, "确认", {
        confirmButtonText: "是的立即提交",
        cancelButtonText: "否，再看下",
        type: "warning",
      })
        .then(() => {
          this.submitApplicationShow = true;
          this.orgVisaEmailId = row.id;
        })
        .catch((res) => {});
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.queryorderapplicantsFn();
    },
    handleCurrentChangeAccount(e) {
      this.pageIndexAccount = e;
      this.getAccount();
    },
    //到大使馆
    handleSubmitBefore() {
      if (this.orderInfo.status === 0) {
        //首次提交
        this.choseAccountDialog = true;
      } else {
        this.handleSubmit();
      }
    },
    //提交到大使馆
    handleSubmit(payType) {
      //1：普通支付 2：打包支付
      agentsubmit({
        orderId: this.orderDetail.order.id,
        payType,
        orgVisaEmailId: this.orderInfo.status === 0 ? this.orgVisaEmailId : 0, //重新提交 固定传0
        userId: this.userId,
        isResubmit: this.orderInfo.status === -1, //重新提交
      }).then((res) => {
        this.choseAccountDialog = false;
        this.clickApplicantStatus();
      });
    },
    //修改入境类型
    visaTypeChage(val) {
      if (val == 1) {
        this.travelOptions = [
          { label: "旅游", value: "20" },
          { label: "商务", value: "2" },
        ];
      } else {
        this.travelOptions = [
          { label: "邮轮", value: "9" },
          { label: "商务", value: "10" },
          { label: "医疗", value: "52" },
          { label: "婚礼", value: "11" },
        ];
      }
    },
    // 提交中状态轮询查询订单状态
    clickApplicantStatus() {
      let vm = this;
      if (this.applicantstatusTimer) {
        clearInterval(this.applicantstatusTimer);
        this.applicantstatusTimer = null;
      }
      vm.applicantstatusTimer = setInterval(() => {
        vm.pollingOrderApplicantStatus();
      }, 1000);
    },
    //轮询订单状态
    pollingOrderApplicantStatus() {
      // 待提交使馆 = 0,  显示提交到大使馆
      // 提交失败 = -1,   显示重新提交到大使馆
      // 提交使馆中 = 2,  提交后展示处理中
      // 待支付费用 = 4,  展示立即付款  与 我已付款按钮
      // 查询支付中 = 6,  显示  查询支付中
      // 等待出签中 = 8,  显示  等待出签中
      // 已出签 = 10,		  下载签证页
      orderexinfo({ orderId: this.id }).then((res) => {
        //订单状态为 已出签，暂停轮询
        if (res.data.status == 10) {
          if (this.applicantstatusTimer) {
            clearInterval(this.applicantstatusTimer);
            this.applicantstatusTimer = null;
          }
        }
        this.orderInfo = res.data;
        this.queryorderapplicantsFn();
      });
    },
    copySuccess() {
      this.$message({
        message: "复制成功！",
        type: "success",
      });
    },
    copyError() {
      this.$message({
        message: "复制失败，请手动复制！",
        type: "error",
      });
    },
    orderdetailFn() {
      let vm = this;
      orderdetail({
        id: vm.id,
      }).then((res) => {
        vm.orderDetail = res.data;
        vm.dateInfo.date =
          vm.orderDetail.order &&
          vm.orderDetail.order.departureTime &&
          vm.orderDetail.order.departureTime.split(" ")[0];
        if (vm.orderDetail.order && vm.orderDetail.order.embassyDate) {
          vm.orderDetail.order.embassyDate = JSON.parse(
            vm.orderDetail.order.embassyDate
          );
          if (vm.orderDetail.order.embassyDate.label == "近30天内均无法前往") {
            vm.orderDetail.order.embassyDate.str =
              vm.orderDetail.order.embassyDate.value[0];
          } else if (
            vm.orderDetail.order.embassyDate.label == "指定最佳赴馆时间前往"
          ) {
            vm.orderDetail.order.embassyDate.str =
              vm.orderDetail.order.embassyDate.value.join("、");
          } else {
            vm.orderDetail.order.embassyDate.str =
              vm.orderDetail.order.embassyDate.label;
          }
        }
      });
    },
    toPath(num) {
      if (num == 2) {
        this.$router.push({ name: "orderCenter" });
      }
    },
    addUserFn(e = []) {
      if (this.applicationLoading) {
        return;
      }
      let oldArr = [];
      // 修复bug只是新增一个申请人，前端传递了所有申请人数据，导致重复
      // this.orderDetail.orderApplicants.forEach(item => {
      //     oldArr.push({
      //         applicantName: item.applicantName,
      //         identityTypeId: item.identityType,
      //         id: item.id
      //     })
      // })

      let arr = [...e, ...oldArr];
      this.applicationLoading = true;
      addorderapplicants({
        orderId: this.id,
        DepartureTime: this.dateInfo.date,
        applicants: arr,
      })
        .then((res) => {
          if (res.code == 1) {
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.orderdetailFn();
            setTimeout(() => {
              this.queryorderapplicantsFn(true);
            }, 1800);
            this.newAddUserShow = false;
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
            this.applicationLoading = false;
          }
        })
        .catch(() => {
          this.applicationLoading = false;
        });
    },
    addUser() {
      this.newAddUserShow = true;
    },
    rowClassFun(item) {
      if (item.row.status == this.submitIngStatus) {
        return "submitting_calss";
      } else {
        return "";
      }
    },
    handleOrderPaid() {
      orderpaid({ orderId: this.id }).then((res) => {
        this.orderdetailFn();
        this.queryorderapplicantsFn();
      });
    },
    //中文姓 自动转 大写英文
    changeSurName(val) {
      this.ruleForm.customer_SurnameEn =
        ChineseHelper.ConvertPinyin(val).toUpperCase();
    },
    changeName(val) {
      this.ruleForm.customer_NameEn =
        ChineseHelper.ConvertPinyin(val).toUpperCase();
    },
  },
  destroyed() {
    if (this.applicantstatusTimer) {
      clearInterval(this.applicantstatusTimer);
      this.applicantstatusTimer = null;
    }
  },
  beforeDestroy() {
    if (this.applicantstatusTimer) {
      clearInterval(this.applicantstatusTimer);
      this.applicantstatusTimer = null;
    }
  },
};
</script>
<style lang="scss">
.order-detail-zdh {
  .yellow-cls {
    input {
      color: #eebb17;
    }
  }

  .green-cls {
    input {
      color: #1c9c1c;
    }
  }

  .red-cls {
    input {
      color: #e41313;
    }
  }
}

.order-detail-child {
  iframe {
    .countryContainer {
      background: #fff !important;
    }
  }

  .dialog-wrap-dowm-nom {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
      background: none;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      // width: 761px;

      // height: 285px;
      background: linear-gradient(
        180deg,
        #e7ecfb 0%,
        #f6f8fe 24%,
        #ffffff 100%
      );
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .dialog-order-detail-wrap {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
      background: none;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      width: 419px;

      // height: 285px;
      background: linear-gradient(
        180deg,
        #e7ecfb 0%,
        #f6f8fe 24%,
        #ffffff 100%
      );
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  .select-input {
    input {
      width: 89px;
      height: 28px;
      line-height: 28px;
      padding: 0 5px;
      font-size: 12px;
      background: #f5f8fd;
    }
  }

  .page-order-table-box {
    .el-table__body-wrapper {
      font-size: 12px;
    }

    .el-table .submitting_calss {
      opacity: 1;

      .el-icon-loading {
        color: #409eff;
      }
    }
  }

  .el-select__caret {
    display: block;
    line-height: 28px !important;
  }

  .current-row {
    td {
      background-color: rgba(222, 239, 255, 0.726) !important;
    }
  }

  .form-item-btn {
    text-align: right;
  }

  .dialog-nom-list {
    margin-top: 10px;

    .el-table {
      background: none;

      .el-table__row {
        background: none;

        .el-table__cell {
          padding: 6px 0;
        }
      }

      .el-table__header-wrapper {
        height: 36px;
        border-radius: 4px;

        .cell {
          line-height: 36px;
          height: 36px;
          margin-top: -10px;
        }
      }
    }
  }

  .demo-ruleForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .demo-ruleForm-radio {
      text-align: left;
    }

    .el-form-item {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 6px;
      width: 323px;

      &.form-item-btn {
        width: 100%;
      }

      .el-date-editor {
        width: 100%;
      }

      &:nth-child(1) {
        margin-right: 130px;
      }

      .el-form-item__label {
        width: 50px !important;
        white-space: nowrap;
        height: 30px;
      }

      .el-form-item__content {
        margin-left: 0px !important;
      }
    }

    .el-form-item__content {
      text-align: right;

      .el-button {
        margin-top: 20px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .order-detail-child {
  background: #f7f8fa;

  ::-webkit-scrollbar {
    width: 1px;
    /*设置滚动条宽度*/
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #eee;
    /*设置滚动条背景颜色*/
  }

  ::-webkit-scrollbar-thumb {
    background: #ccc;
    /*设置滚动条滑块颜色*/
    border-radius: 5px;
    /*设置滚动条滑块圆角*/
  }
}

.packge-btn-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .pageck-btn {
    width: 145px;
    margin: 0 16px;
  }
}

.dialog-nom-list {
  display: flex;
  justify-content: space-between;

  .dialog-nom-list-left {
    width: 390px;
  }

  .dialog-nom-list-right {
    .dialog-nom-list-right-wrap {
      width: 295px;

      .dialog-nom-list-right-title {
        box-sizing: border-box;
        background: #fff;
        border-radius: 4px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;

        p {
          font-size: 12px;

          span {
            color: #0094e8;
          }
        }
      }

      .dialog-nom-list-right-yello {
        background: #fdf6ec;
        //  height: 28px;
        padding: 6px 12px;
        color: #f9c26f;
        box-sizing: border-box;
        font-size: 12px;
        margin-top: 8px;
      }

      .dialog-nom-select-up {
        margin-top: 15px;
      }
    }
  }
}

.top-item-btn {
  height: 35px;
  background: #358eea;
  color: #fff;
  border-radius: 4px;
  padding: 0 20px;
  line-height: 35px;
  cursor: pointer;

  &:hover {
    background: #127de9;
    color: #fff;
  }
}

.nobg-btn {
  border: 1px solid #358eea;
  background: #fff;
  color: #358eea;
}

.page-order-detaul-bom {
  width: 1200px;
  display: flex;
  height: calc((100vh / var(--zoom)) - 130px);

  .page-order-detail-left {
    width: 380px;
    height: 100%;
    display: flex;
    flex-direction: column;
    // padding-bottom: 30px;

    .page-order-item-info {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      background: #fff;
      padding-bottom: 60px;
      position: relative;

      .page-order-up {
        text-align: center;
        color: #0094e8;
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 380px;
        text-align: center;
        border-top: 1px solid #eeeeee;
        padding-top: 15px;
        padding-bottom: 15px;
        z-index: 2;
        background: #fff;
        cursor: pointer;
      }

      .page-order-item-t-box {
        overflow-y: auto;
      }

      .page-order-item-t {
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .page-order-status-name {
          padding: 4px 14px;
          color: #0094e8;
          border-radius: 4px;
          margin-left: 28px;
          cursor: pointer;
        }

        span {
          width: 120px;
        }
      }
    }

    .page-order-item-table {
      width: 380px;
      background: #fff;
      // height: calc(100% - 350px);
      padding-bottom: 16px;

      .page-order-detail-btn {
        padding: 20px;
        padding-bottom: 0px;

        .beadd {
          color: #cccccc;
          margin-left: 15px;

          b {
            color: #0094e8;
          }
        }

        .batch {
          color: #cccccc;
          display: inline-block;
          width: 100px;
          margin-left: 20px;
          vertical-align: middle;
        }
      }

      .page-order-table-box {
        padding: 0 20px;

        .submit {
          margin-top: 10px;

          ::v-deep .el-button {
            border-radius: 6px;
          }
        }

        .submit-email {
          color: #999;
          font-size: 13px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

  .page-order-detail-bottom {
    width: 820px;
    // height: 1107px;
    // background: #FFF;
    margin-left: 20px;

    .page-order-detail-bottom-title {
      width: 100%;
      height: 64px;
      background: #fff8ee;
      font-size: 14px;
      color: #ff9d3d;
      padding: 12px;
      box-sizing: border-box;
    }

    .page-order-detail-bottom-content {
      position: relative;
      width: 100%;

      height: calc(100% - 85px);
      overflow-y: auto;

      .detail-item-box {
        width: 100%;
        color: #333;

        .detail-item-title {
          height: 58px;
          background: #eef9ff;
          color: #358eea;
          font-size: 20px;
          cursor: pointer;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .detail-item-content {
          background: #fff;
          width: 99.8%;
          padding-bottom: 5px;

          .detail-item-content-top {
            height: 550px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .top-item {
              width: 50%;
              height: 100%;
              padding: 10px;
              box-sizing: border-box;

              .img-box {
                width: 360px;
                height: 300px;
                background: #f5f5f5;
                border-radius: 4px;
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .btn-box {
                  position: absolute;
                  right: 10px;
                  bottom: 10px;

                  ::v-deep .el-button--mini {
                    background: #b6b6b6;
                    padding: 4px;
                    border: none;
                  }
                }

                ::v-deep .vue-cropper {
                  background-image: none;
                }
              }

              .img-btn {
                margin-top: 20px;
              }
            }
          }

          .detail-item-content-bt {
            margin-top: 15px;
            width: 100%;
            height: 280px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 10px;
            box-sizing: border-box;

            .bt-item {
              width: 145px;
              height: 100%;

              .bt-item-top {
                width: 100%;
                height: 174px;
                background: #ffffff;
                box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.09);
                padding: 10px;
                box-sizing: border-box;
                position: relative;

                img {
                  width: 80px;
                  height: 90px;
                  margin: 25px 0 0 18px;
                }

                .tips {
                  position: absolute;
                  right: 5px;
                  top: 13px;
                  color: #0094e8;
                }
              }

              .bt-item-bottom {
                margin-top: 15px;

                .bt-item-bottom-bt {
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .top-item-btn {
                    padding: 0 8px;
                    font-size: 12px;
                  }
                }
              }
            }
          }

          .detail-item-content-hotel-bt {
            width: 100%;
            height: 240px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            box-sizing: border-box;

            .hotel-bt-left {
              width: 25%;
              height: 100%;
              position: relative;

              img {
                width: 100px;
                height: 130px;
                margin: 25px 0;
                display: block;
              }

              .priview-text {
                position: absolute;
                left: 10px;
                bottom: 25px;
                cursor: pointer;
                color: #0094e8;
              }
            }

            .hotel-bt-right {
              width: 75%;
              height: 100%;

              .hotel-bt-righ-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 28px;

                .right-item-detail {
                  width: 100%;

                  ::v-deep .el-input {
                    margin-top: 5px;

                    .el-input__inner {
                      height: 30px;
                      line-height: 30px;
                    }
                  }
                }
              }
            }
          }

          .required {
            position: relative;

            &::before {
              position: absolute;
              content: "*";
              top: 0;
              right: -8px;
              color: red;
            }
          }
        }
      }
    }

    .page-rder-detail-bottom-btn {
      box-shadow: 0px -3px 12px 1px rgba(102, 102, 102, 0.1);
      flex: 1;
      height: 61px;
      line-height: 61px;
      text-align: right;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      background: #fff;
    }

    ::v-deep .right-form {
      .el-form-item__label {
        padding: 0;
        line-height: 25px;
      }

      .el-form-item__content,
      .el-input__icon {
        line-height: 30px;
      }

      .el-input__inner {
        height: 30px;
        line-height: 30px;
      }

      .el-date-editor.el-input {
        width: 165px;
      }

      .passsort-tips {
        position: absolute;
        right: 5px;
        top: -20px;
        color: #0094e8;
        font-size: 16px;
      }

      .icon {
        color: #0094e8;
        position: absolute;
        right: -18px;
        top: 10px;
      }

      .hotel-web {
        position: absolute;
        left: 130px;
        top: -26px;

        em {
          cursor: pointer;
          color: #0094e8;
        }
      }
    }
  }
}

.table-pagination {
  text-align: center;
  margin-top: 16px;
}

.order-wrap {
  width: 1200px;
  margin: 0 auto;

  .order-content {
    width: 1200px;
    // height: 831px;
    background: #fff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  }

  .page-path {
    margin: 16px 0;
    font-size: 12px;
    // color: #CCCCCC;
    color: #a19f9f;

    span {
      cursor: pointer;
    }
  }

  .page-card-top {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom: 3px solid #f5f5f5;

    .page-card-top-left {
      display: flex;
      align-items: center;
    }

    .page-top-back {
      font-size: 16px;
      color: #3888e9;
      cursor: pointer;

      i {
        color: #3888e9;
        font-weight: bold;
      }
    }

    .page-top-name {
      margin-left: 32px;
      font-size: 18px;
      font-weight: bold;
    }

    .page-top-identity {
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #666666;
    }
  }

  .lan {
    color: #3888e9;
    cursor: pointer;
  }

  .page-card-conetent {
    height: 668px;
    width: 100%;
    flex: 1;

    iframe {
      flex: 1;
      width: 1200px;
      height: 668px;
    }
  }

  .flex-app {
    display: flex;
    flex-direction: column;
  }

  .page-card-bom {
    height: 97px;
    border-top: 3px solid #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;

    .page-card-bom-btn {
      margin: auto;
      width: 138px;
      height: 45px;
    }
  }

  .page-card {
    width: 1200px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);

    .page-require-box {
      width: 100%;

      .page-title {
        height: 42px;
        padding-left: 24px;
        font-size: 16px;
        color: #3888e9;
        display: flex;
        align-items: center;
        background: #f2f8ff;
      }

      .page-item {
        padding: 20px 24px;
        background: #fff;
        border-bottom: 5px solid #f7f8fa;

        .page-item-avatar {
          .page-item-name {
            display: block;
            margin-bottom: 12px;
          }

          .page-item-flex {
            display: flex;
            //  align-items: center;

            .page-item-no-passport {
              display: flex;
              align-items: center;
              margin-left: 77px;
              // margin-top: 38px;
            }

            .page-input {
              width: 734px;
              margin-left: 26px;

              .page-item-title {
                width: 100%;
                height: 38px;
                background: #f2f8ff;
                color: #3888e9;
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .page-item-waning {
                color: #ff5967;

                .el-icon-warning-outline {
                  margin-right: 8px;
                }
              }

              .page-item-wrap {
                background: #f7f8fa;
                min-height: 400px;
                width: 100%;
                padding: 12px 28px;
                box-sizing: border-box;

                .page-item-top {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 14px;

                  .page-edit {
                    color: #5f9fed;
                    cursor: pointer;
                  }
                }

                .demo-list {
                  display: flex;
                  flex-wrap: wrap;
                  margin-top: 16px;

                  li {
                    width: 330px;
                    display: flex;
                    margin-bottom: 24px;

                    span {
                      width: 110px;
                      color: #666;
                      font-size: 14px;
                    }

                    p {
                      font-size: 16px;
                      font-weight: bold;
                    }
                  }
                }
              }
            }

            .page-item-legend {
              width: 222px;
              height: 151px;
              margin-left: 16px;
            }

            .page-item-desc {
              max-width: 336px;
              flex: flex;
              flex-direction: column;
              margin-left: 20px;

              span {
                font-size: 16px;
              }

              p {
                font-size: 14px;
                color: #999999;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}

.img1,
.img2 {
  padding-bottom: 40px;
  height: 350px;
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }
}

.cropperBtn_box {
  text-align: center;

  ::v-deep .el-button {
    background: #3888e9;
    padding: 6px;
    border: none;
  }

  ::v-deep .iconfont {
    font-size: 12px;
  }

  ::v-deep .right {
    .iconfont {
      display: inline-block;
      transform: scaleX(-1);
    }
  }
}

.upload-style {
  display: inline-block;
  margin-right: 10px;
}

.dialog-right-info {
  width: 155px;
  height: 300px;

  img {
    width: 100px;
    height: 120px;
  }

  p {
    font-size: 12px;
    line-height: 30px;
  }
}

.other-box {
  display: flex;
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: auto;

  .other-item {
    width: 160px;
    height: 205px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 20px 0 0;

    .el-icon-document {
      font-size: 80px;
      color: #3888e9;
    }

    .s-title {
      box-sizing: border-box;
      padding: 8px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
      color: #3888e9;
    }
  }
}

.hotel-item {
  border: 1px solid #999;
  margin-bottom: 5px;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.active {
  border-color: #358eea;

  p {
    color: #358eea;
  }
}
</style>
