<template>
  <div class="box-page">
    <div class="box-input">
      <div class="box-input-left">
        <el-select
          clearable
          v-model="formInfo.status"
          style="width: 145px"
          placeholder="请选择状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <!-- <el-select
          clearable
          v-model="formInfo.orgAgentId"
          style="width: 145px"
          placeholder="请选择代理商"
        >
          <el-option
            v-for="item in merchatList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
      </div>

      <div class="box-input-btn">
        <el-button type="primary" icon="el-icon-download" :loading="batchLoading"  @click="batchpaymentFn">批量发款 </el-button>
        <el-button class="default" @click="searchFn">搜索</el-button>
        <el-button class="box-input-btn-cz" @click="resetFn">重置</el-button>
      </div>
    </div>

    <div class="box-table">
      <el-table
        :data="tableList"
        :header-cell-style="{
          backgroundColor: '#F2F4F7',
          color: '#333',
          fontWeight: 'bold',
          textAlign: 'center',
          fontSize: '12px',
        }"
        ref="refTable"
        style="width: 100%"
        :height="$store.state.product.screen ? '820px' : '470px'"
        v-loading="formInfo.loading"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column type="selection" width="55" :selectable="selectableFn"> </el-table-column>
        <el-table-column prop="agentName" label="商户名称"> </el-table-column>
        <el-table-column prop="agentPhone" label="商户联系"> </el-table-column>
        <el-table-column prop="account" min-width="280" label="提现账户"> </el-table-column>
        <el-table-column prop="amount" label="提现金额"> </el-table-column>
        <el-table-column prop="lastAmount" label="剩余金额"> </el-table-column>
        <el-table-column prop="remark" label="提现备注"> </el-table-column>
        </el-table-column>
        <el-table-column prop="status" label="发款状态">
          <template slot-scope="scope">
            <el-tag :type="computedStatusColor(scope.row.status)"> {{computedValue(statusList, scope.row.status)}} </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="paymentTime" label="发款时间" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="payRemark" label="发款备注" min-width="150" show-overflow-tooltip> </el-table-column>
        <el-table-column prop="createdTime" label="申请时间" show-overflow-tooltip> </el-table-column>

        <el-table-column label="操作" header-align="center" fixed="right">
          <template slot-scope="scope">
            <div class="edit-wrap">
              <div class="edit-item">
                <el-button type="text" @click="paymentFn(scope.row)" :disabled="!(scope.row.status == 0 || scope.row.status == 1)">发款</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-pagination">
      <el-pagination
        @current-change="paginationChange"
        :current-page="formInfo.pageIndex"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="formInfo.total"
      >
      </el-pagination>
    </div>

    
    <el-dialog
      title="发款备注"
      :visible.sync="payRemark.visible"
      width="40%"
      @close="payRemark.visible = false">
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 4}"
        placeholder="请输入内容"
        v-model="payRemark.content">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payRemark.visible = false">取 消</el-button>
        <el-button type="primary" :loading="batchLoading" :disabled="batchLoading" @click="payRemarkConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { agentwithdrawalquery, paymentstatusselect, agentbatchpayment } from '@/api/settings.js'
export default {
  data() {
    return { 
      formInfo: {
        status: '',
        orgAgentId: '',
        pageIndex: 0,
        total: 0,
        loading: false,
      },
      statusList: [],
      merchatList: [],
      tableList: [],
      tableLoading: [],
      batchLoading: false,
      
      payRemark: {
        visible: false,
        content: '',
        type: 0,
        list: []
      }
    }
  },
  
	computed: {
			computedValue() {
				return function(list, val) {
					if(list.length) {
            let findData = list.find(res => res.value == val)
						return findData&&findData.label
					} else {
						return '--'
					}
				}
			},
			computedStatusColor() {
				return function( val) {
					let txt = '';
					switch(val) {
            case -1:
              txt = ''
            break;
						case 0:
							txt = 'info'
						break;
						case 1:
							txt = 'warning'
						break;
						case 2:
							txt = 'success'
						break;
						default:
							txt = 'info'
						break;
					}
					return txt;
				}
			}
  },
  mounted() {
    this.getStatusFn();
    this.searchFn();
  },
  
  methods: {
    searchFn() {
      this.formInfo.pageIndex = 1
      this.getListFn()

    },
    resetFn() {
      this.formInfo = {
        status: '',
        orgAgentId: '',
        pageIndex: 0,
        total: 0,
        loading: false,
      }
      this.getListFn();
    },
    getListFn() {
      this.formInfo.loading = true;

      agentwithdrawalquery({
        orgAgentId: this.formInfo.orgAgentId,
        Status: this.formInfo.status,
        'Page.Size': 10,
        'Page.Index': this.formInfo.pageIndex,
      }).then(res => {
        console.log('agentwithdrawalquery', res)
        this.tableList = res.data.rows;
        this.formInfo.total = res.data.total
        this.formInfo.loading = false;
      })
    },
    paginationChange(val) {
      this.formInfo.pageIndex = val
      this.getListFn()
      this.$refs.refTable.bodyWrapper.scrollTop = 0
    },
    getStatusFn() {
        paymentstatusselect().then(res => {
        console.log('paymentstatusselect', res)
            this.statusList = res.data
        })
    },
    batchpaymentFn() {
      if(this.$refs.refTable.selection.length == 0) {
        return this.$message.error('请选择要操作的订单')
      }
      this.payRemark.visible = true;
      this.payRemark.content = '';
      this.payRemark.type = 0;
      this.payRemark.list = this.$refs.refTable.selection;
      
    },
    paymentFn(row) {
      this.payRemark.visible = true;
      this.payRemark.content = '';
      this.payRemark.type = 1;
      this.payRemark.list = [row];
    },
    payRemarkConfirm() {
      // this.exportExcel(this.payRemark.list)
      let pamars = this.payRemark.list.map(res => {
        return {
          id: res.id,
          paymentRemark: this.payRemark.content
        }
      })
      this.batchLoading = true
      agentbatchpayment(pamars).then(res => {
      this.batchLoading = false
        if (res.code == 1) {
          this.$message.success('操作成功')
          this.payRemark.visible = false
          this.searchFn()
          this.exportExcel(this.payRemark.list)
        }
      }).catch(() => {
        this.batchLoading = false
      })
    }, 
    
    exportExcel(dataList) {
      //列标题，逗号隔开，每一个逗号就是隔开一个单元格
      let str = `序号,商户名称,支付宝账号,姓名,金额,联系方式\n`
      //增加\t为了不让表格显示科学计数法或者其他格式
      for (let i = 0; i < dataList.length; i++) {
        str += `${i + 1 + '\t'},`
        str += `${dataList[i]['name'] + '\t'},`
        console.log(dataList[i]['remark'])
        if (dataList[i]['remark']) {
          let remarkList = dataList[i]['remark'].split('+')
          for (let index = 0; index < remarkList.length; index++) {
            str += `${remarkList[index].trim() + '\t'},`
            // 姓名后面金额
            if (index == 1) {
              str += `${dataList[i]['amount'] + '\t'},`
            }
          }
        }
        str += '\n'
      }
      //encodeURIComponent解决中文乱码
      let uri = 'data:text/xlsx;charset=utf-8,\ufeff' + encodeURIComponent(str)
      //通过创建a标签实现
      let link = document.createElement('a')
      link.href = uri
      //对下载的文件命名
      link.download = `${'代理商提现明细' + new Date().getTime() + '.xlsx'}`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    selectableFn(row) {
      if(row.status == 0  || row.status == 1) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-page {
  .box-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    ::v-deep .el-input__inner {
      background: #f2f4f7;
      border: none;
    }

    ::v-deep .el-select,
    .el-input {
      margin-right: 8px;
    }

    .box-input-btn {
      text-align: right;

      .box-input-btn-cz {
        background: #eaf3ff;
        color: #3888e9;
        border: none;
      }

      .box-input-btn-add {
        background: #d8f5e4;
        color: #54d8a5;
        border: none;
      }
    }
  }
  .table-pagination {
    text-align: center;
    margin-top: 16px;
  }
}
</style>
