import request from '@/utils/request';

export const login = (params) => {
  return request('/app_api/v1/auth/login', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


//    签证订单列表
export function query(params) {
  return request('/app_api/v1/visaorder/query', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}


//    查询当前用户保险订单记录
export function queryInsurance(params) {
  return request('/app_api/v1/insurance/query', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

//    订单状态状态
export function orderstatusselect(params) {
  return request('/app_api/v1/visaorder/orderstatusselect', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

//    订单申请人状态
export function orderapplcantstatusselect(params) {
  return request('/app_api/v1/visaorder/orderapplcantstatusselect', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

//    保险订单状态
export function insuranceorderstatusselect(params) {
  return request('/app_api/v1/insurance/insuranceorderstatusselect', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}


//    订单详情
export function orderdetail(params) {
  return request('/app_api/v1/visaorder/orderdetail', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 修改申请人信息接口
export const upadateorderapplicants = (params) => {
  return request('/app_api/v1/visaorder/upadateorderapplicants', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 添加申请人信息接口
export const addorderapplicants = (params) => {
  return request('/org_api/v1/orgfinance/addorderapplicant', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

//    查询申请人资料
export function applicantdocs(params) {
  return request('/app_api/v1/visaorder/applicantdocs', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 电子资料上传
export const applicantdataupload = (params) => {
  return request('/app_api/v1/visaorder/applicantdataupload', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 护照信息识别(保存信息)
export const postuploadpassport = (params) => {
  return request('/country_api/v1/publicapi/postuploadpassport', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


// 保存护照信息(保存信息)
export const savepassportinfo = (params) => {
  return request('/country_api/v1/publicapi/savepassportinfo', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


// 查询当前订单可选择签证中心
export const getvisacenters = (params) => {
  return request('/app_api/v1/visaorder/getvisacenters', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 修改赴馆日期信息接口
export const upadateorderembassydate = (params) => {
  return request('/app_api/v1/visaorder/upadateorderembassydate', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 确认下单接口
export const confirmorder = (params) => {
  return request('/app_api/v1/visaorder/confirmorder', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

export const balancepayment = (params) => {
  return request('/app_api/v1/visaorder/balancepayment', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


// 获取商铺余额
export const getbalance = (params) => {
  return request('/app_api/v1/user/getbalance', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 获取充值套餐
export const getrechargepackages = (params) => {
  return request('/app_api/v1/user/getrechargepackages', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 购买充值订单
export const createrechargeorder = (params) => {
  return request('/app_api/v1/user/createrechargeorder', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


// 上传图片-会删除之前上传的所有图片
export const savedocimg = (params) => {
  return request('/country_api/v1/publicapi/savedocimg', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 更新指定doc文件
export const updatedocimg = (params) => {
  return request('/country_api/v1/publicapi/updatedocimg', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 上传图片-直接新增图片
export const savedocimgnodel = (params) => {
  return request('/country_api/v1/publicapi/savedocimgnodel', {
    method: 'post',
    data: { ...params }//post请求使用post
  })

}

// 删除doc文件-删除指定文件
export const deletedocimgtwo = (params) => {
  return request('/country_api/v1/publicapi/deletedocimgtwo', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 当前商铺用户（下拉框用）
export const merchatuserlist = (params) => {
  return request('/app_api/v1/visaorder/merchatuserlist', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 取消订单接口
export const cancelorder = (params) => {
  return request('/app_api/v1/visaorder/cancelorder', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 查询充值订单
export const getrechargeorder = (params) => {
  return request('/app_api/v1/user/getrechargeorder', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 分页订单申请人记录
export const queryorderapplicants = (params) => {
  return request('/app_api/v1/visaorder/queryorderapplicants', {
    method: 'get',
    params: { ...params }//post请求使用post
  })
}

// 申请人状态修改
export const orderapplicantmodifystatus = (params) => {
  return request('/org_api/v1/orgfinance/orderapplicantmodifystatus', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 批量下载申请人资料
export const downloadapplicantsdata = (params) => {
  return request('/org_api/v1/orgfinance/downloadapplicantsdata', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 修改申请人身份类型
export const orderapplicantmodifyidentity = (params) => {
  return request('/org_api/v1/orgfinance/orderapplicantmodifyidentity', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 申请人备注
export const orderapplicantremarks = (params) => {
  return request('/org_api/v1/orgfinance/orderapplicantremarks', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}
// 申请人上传签证页
export const uploadvisapage = (params) => {
  return request('/org_api/v1/orgfinance/uploadvisapage', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}
// 订单退款
export const orderrefund = (params) => {
  return request('/org_api/v1/orgfinance/orderrefund', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}

// 批量提交到大使馆网站
export const batchsubmitapplicant = (params) => {
  return request('/org_api/v1/orgfinance/batchsubmitapplicant', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}
//    查询支付结果
export function paycallback(params) {
  return request('/org_api/v1/orgfinance/paycallback', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}
//  查询当前订单可用优惠券
export function getordercoupon(params) {
  return request('/app_api/v1/user/getordercoupon', {
    method: 'get',
    params: { ...params }
  })
}


//  申请人状态查询
export function applicantstatus(params) {
  return request('/org_api/v1/orgfinance/applicantstatus', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}
// 我的订单--下载资料包
export const downloadapplicantdata = (params) => {
  return request('/app_api/v1/visaorder/downloadapplicantdata', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}
//批量取消vfs
export const batchcancelvfsorder = (params) => {
  return request('/org_api/v1/orgfinance/batchcancelvfsorder', {
    method: 'post',
    data: { ...params }//post请求使用post
  })
}


// 导入excel
export function processtemplate(params) {
  return request('/app_api/v1/visaorder/processtemplate', {
    method: 'post',
    data: { ...params }
  })
}