<!--  -->
<template>
    <div class="package-page">
        <div class="box-input">
            <div class="box-input-left">
                <el-input style="width: 671px; margin-right: 12px" v-model="formInfo.title" placeholder="请输入标题"></el-input>
            </div>
            <div class="box-input-btn">
                <el-button class="default" @click="searchFn">搜索</el-button>
                <el-button class="box-input-btn-cz" @click="reset">重置</el-button>
                <el-button class="box-input-btn-cz" @click="addInfo">添加资讯</el-button>
            </div>
        </div>
        <div class="box-table">
            <el-table :data="tableList" :header-cell-style="{
                backgroundColor: '#F2F4F7',
                color: '#333',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '12px',
            }" style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'"
                v-loading="formInfo.loading" :cell-style="{ 'text-align': 'center' }" ref="refTable">
                <el-table-column prop="img" label="封面图">
                    <template slot-scope="scope">
                        <el-image style="width: 80px; height: 60px" :src="scope.row.img"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题"> </el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="scope">
                        <div class="tag-sty" :class="[setTag(scope.row.type).bg]">
                            {{ setTag(scope.row.type).text }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
                <el-table-column prop="sort" label="排序"> </el-table-column>
                <el-table-column prop="enable" label="是否发布">
                    <template slot-scope="scope">
                        <div>
                            <el-switch v-model="scope.row.enable" active-color="#3888E9" inactive-color="#CCCCCC"
                                @change="switchChange(scope.row)">
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" header-align="center">
                    <template slot-scope="scope">
                        <div class="edit-wrap">
                            <div class="edit-item">
                                <span class="lan" @click="editInfo(scope.row)">编辑</span>
                                <span class="lan" @click="binding(scope.row)">绑定应用</span>
                                <span class="del" @click="delInfo(scope.row.id)">删除</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="table-pagination">
            <el-pagination @current-change="memberCurrentChange" :current-page="formInfo.pageIndex" :page-size="10"
                layout="total, prev, pager, next, jumper" :total="formInfo.total">
            </el-pagination>
        </div>
        <el-dialog :visible.sync="dialogTableVisible" width="40%" class="common-dialog-class">
            <div class="dialog-member-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">绑定应用</span>
                    <div class="dialog-close" @click="dialogTableVisible = false">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="dialog-form-wrap">
                    <div>
                        <el-table :data="dialogData" :header-cell-style="{
                            backgroundColor: '#F2F4F7',
                            color: '#333',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: '12px',
                        }" style="width: 100%" height="600" :cell-style="{ 'text-align': 'center' }">
                            <el-table-column property="appName" label="应用名称"></el-table-column>
                            <el-table-column property="appTypeName" label="类型"></el-table-column>
                            <el-table-column property="bind" label="是否绑定" width="100">
                                <template slot-scope="scope">
                                    <div>
                                        <el-switch v-model="scope.row.bind" active-color="#3888E9" inactive-color="#CCCCCC"
                                            @change="bindApp(scope.row)">
                                        </el-switch>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { queryappnews, newstypeselect, addorupdateappnews, delappnews, queryappsnewsbindapplist, bindappsnews } from "@/api/settings";
export default {
    name: "information",
    data() {
        //这里存放数据
        return {
            formInfo: {
                title: "",
                pageIndex: 1,
                total: 0,
                loading: false,
            },
            tableList: [],
            dialogTableVisible: false,
            dialogData: [],
            typeselectArr: [],
            bindAppDataId: 0,
        };
    },
    //监听属性 类似于data概念
    computed: {
        setTag() {
            return function (type) {
                let bg = "";
                let text = "";
                type == 0 ? bg = "tag-xs" : type == 1 ? bg = "tag-cz" : bg = "tag-color";
                this.typeselectArr.forEach((item) => {
                    if (item.value == type) {
                        text = item.label;
                    }
                });
                return { bg, text };
            };
        }
    },
    //方法集合
    methods: {
        searchFn() {
            this.formInfo.pageIndex = 1
            this.queryFn()
        },
        queryFn() {
            queryappnews({ title: this.formInfo.title, "Page.Index": this.formInfo.pageIndex, "Page.Size": 10 }).then(res => {
                if (res.code == 1) {
                    this.tableList = res.data.rows;
                    this.formInfo.total = res.data.total;
                }
            })
        },
        getSelect() {
            newstypeselect().then((res) => {
                if (res.code == 1) {
                    this.typeselectArr = res.data;
                }
            })
        },
        reset() {
            this.formInfo = {
                title: "",
                pageIndex: 1,
                total: 0,
                loading: false,
            };
            this.queryFn();
        },
        addInfo() {
            this.$emit("editInfo");
        },
        switchChange(item) {
            this.$confirm(`是否${item.enable ? "发布" : "关闭"}此资讯`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                addorupdateappnews({ ...item }).then(() => {
                    this.$message({
                        message: `${item.enable ? "发布成功" : "关闭成功"}`,
                        type: 'success'
                    });
                }).catch(() => {
                    item.enable = !item.enable;
                })
            }).catch((err) => {
                item.enable = !item.enable;
            })
        },
        editInfo(item) {
            this.$emit("editInfo", item);
        },
        binding(item) {
            queryappsnewsbindapplist({ appNewsId: item.id }).then((res) => {
                if (res.code == 1) {
                    this.bindAppDataId = item.id;
                    this.dialogData = res.data;
                    this.dialogTableVisible = true;
                }
            })
        },
        bindApp(item) {
            this.$confirm(`是否${item.bind ? "绑定" : "解绑"}此应用`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                bindappsnews({ appNewsId: this.bindAppDataId, appId: item.appId, bind: item.bind }).then(() => {
                    this.$message({
                        message: `${item.bind ? "绑定成功" : "解绑成功"}`,
                        type: 'success'
                    });
                })
            }).catch(() => {
                item.bind = !item.bind;
            })
        },
        delInfo(id) {
            this.$confirm(`是否删除此条资讯`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                delappnews({ appNewsId: id }).then(() => {
                    this.$message({
                        message: `删除成功`,
                        type: 'success'
                    });
                    this.queryFn();
                })
            })
        },
        memberCurrentChange(e) {
            this.formInfo.pageIndex = e;
            this.queryFn()
            this.$refs.refTable.bodyWrapper.scrollTop = 0;
        }
    },
    created() {
        this.getSelect();
        this.queryFn();
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.dialogData = [
            { name: '签证助手小程序', type: 0, enable: false },
            { name: '签证助手', type: 1, enable: true },
            { name: '快马签证-SASS', type: 2, enable: false }
        ]
    },
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
};
</script>
<style lang="scss" scoped>
.package {
    padding: 16px;
    // width: 973px;
    width: calc(100% - 30px);

    .box-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;

        .box-input-btn {
            text-align: right;

            .box-input-btn-cz {
                background: #eaf3ff;
                color: #3888e9;
                border: none;
            }

            .box-input-btn-add {
                background: #d8f5e4;
                color: #54d8a5;
                border: none;
            }
        }
    }
}

.tag-sty {
    width: 84px;
    height: 25px;
    line-height: 25px;
    color: #fff;
    font-size: 12px;
    border-radius: 5px;
    margin: 0 auto;
}

.tag-color {
    background: #ececec;
    // background: red;
    color: #999999;
}

.tag-cz {
    background: linear-gradient(128deg, #A3C5FF 0%, #337FFF 100%);
}

.tag-xs {
    background: linear-gradient(49deg, #ffc9a5 0%, #ff8033 100%);
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.edit-wrap {
    .edit-item {
        span {
            cursor: pointer;
            display: inline-block;
            margin: 6px;

            &.lan {
                color: #3888e9;
            }

            &.copy {
                color: #9ea09e;
            }

            &.del {
                color: #e41313;
            }
        }
    }
}
</style>
