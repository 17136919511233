<template>
  <div>
    <header class="header">
      <div class="header-content media-page-width">
        <div class="header-logo" @click="toHome">
          <img
            class="logo-img"
            :src="require('@/assets/images/organization/logo.png')"
          />
        </div>

        <ul class="header-list" v-if="!isOrg">
          <li
            v-for="(item, index) in list.slice(0, showNum)"
            :key="index"
            class="header-item"
            @mouseenter="showChildren(index, item)"
            @mouseleave="outChildren(index, item)"
          >
            <router-link :class="num == index ? 'color' : ''" :to="item.path">
              <span>{{ item.name }}</span>
              <i class="el-icon-caret-bottom" v-if="showIcon(item.path)"></i>
              <div class="is-color" v-if="num == index"></div>
            </router-link>
            <!-- 关于我们 - 二级 -->
            <div
              v-if="item.children && hoverNum == 1 && item.isShow"
              class="children-list"
            >
              <div class="children-left">
                <div
                  v-for="(item, index) in countrys.data.continentCountry"
                  @click="continueActive = item.value"
                  :class="[
                    continueActive == item.value ? 'continue-active' : '',
                  ]"
                  :key="index"
                  class="children-left-item"
                >
                  {{ item.label }}
                </div>
              </div>
              <div class="children-right">
                <div
                  class="children-right-item"
                  @click="pathRoute(item)"
                  v-for="(item, index) in countrys.data.continentCountry[
                    continueActive
                  ].datas"
                  :key="index"
                  :style="{
                    backgroundImage: `url(${item.imgUrl})`,
                    fontSize: item.zhName.length >= 7 ? '0.75rem' : '1rem',
                  }"
                >
                  {{ item.zhName }}
                </div>
              </div>
            </div>

            <!--  -->
            <!-- 产品 - 个人中心 -->
            <div class="user-info" v-if="hoverNum == 5 && item.isShow">
              <div class="user-info-item">
                <div class="user-info-left">
                  <el-avatar
                    icon="el-icon-user-solid"
                    size="medium"
                    :src="userInfo.avatar"
                  ></el-avatar>
                  <span style="margin-left: 8px">{{
                    userInfo.nickName
                      ? userInfo.nickName
                      : `用户${userInfo.userId}`
                  }}</span>
                </div>
                <span class="user-info-right" @click="outUser">退出 </span>
              </div>
              <div
                class="user-info-item"
                style="cursor: pointer"
                @click="openPersonal(0)"
              >
                <span class="user-info-item-left">账户余额</span>
                <div class="user-info-item-right">
                  <span>￥{{ $store.state.balance }}</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div
                class="user-info-item"
                style="cursor: pointer"
                @click="openPersonal(1)"
              >
                <span class="user-info-item-left">优惠券</span>
                <div class="user-info-item-right">
                  <span>{{ usercoupon }}张</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div class="user-info-btn" @click="isVisible = true">
                余额充值
              </div>
            </div>
          </li>
          <div class="header-right-icon" v-if="isLogin" @click="openTz">
            <img :src="require('../../assets/images/header/tx.png')" />
            <span :class="{ 'header-right-t': hasNoticeUnRead }"></span>
          </div>
          <div class="header-icon" v-if="!isLogin">
            <div
              class="header-btn"
              @click="
                config.dialogVisible = true;
                register = false;
              "
            >
              登录
            </div>
            <div
              class="header-btn header-btn-bg"
              @click="
                config.dialogVisible = true;
                register = true;
              "
            >
              免费注册
            </div>
          </div>
        </ul>
        <ul class="header-list org" v-if="isOrg">
          <li v-for="(item, index) in orgList" :key="index" class="header-item">
            <router-link :class="num == index ? 'color' : ''" :to="item.path">
              <span>{{ item.name}}</span>
              <i class="el-icon-caret-bottom" v-if="showIcon(item.path)"></i>
              <div class="is-color" v-if="num == index"></div>
            </router-link>
          </li>
        </ul>
        <div class="right-box" v-if="isOrg && orgAuthInfo">
          <div class="right-box-user">
            <img :src="orgAuthInfo.avatar" alt="" />
            <span class="org-name">{{ orgAuthInfo.orgName }}</span>
            <i class="el-icon-arrow-down"></i>
            <div class="user-info org-info">
              <div class="user-info-item">
                <div class="user-info-left">
                  <el-avatar
                    icon="el-icon-user-solid"
                    size="medium"
                    :src="orgAuthInfo.avatar"
                  ></el-avatar>
                  <span style="margin-left: 8px">{{
                    orgAuthInfo.orgName
                  }}</span>
                </div>
                <span class="user-info-right" @click="outUser">退出</span>
              </div>
              <div
                class="user-info-item"
                style="cursor: pointer"
                @click="openFinance"
              >
                <span class="user-info-item-left">点券余额</span>
                <div class="user-info-item-right">
                  <span>￥{{ orgBalance }}</span>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
              <div
                class="user-info-btn"
                @click="showRecharge = true"
                style="text-align: center"
              >
                点券充值
              </div>
            </div>
          </div>
          <div class="header-right-icon" v-if="isLogin" @click="openTz">
            <img :src="require('../../assets/images/header/tx.png')" />
            <span :class="{ 'header-right-t': hasNoticeUnRead }"></span>
          </div>
        </div>
      </div>
    </header>
    <recharge
      :config="isVisible"
      v-if="isVisible"
      @close="isVisible = false"
      @initData="
        isVisible = false;
        getbalanceFn();
      "
    ></recharge>
    <login
      :config="config.dialogVisible"
      v-if="config.dialogVisible"
      :isRegister="register"
      @close="config.dialogVisible = false"
      @refresh="handleRefresh"
    ></login>
    <ticketsRecharge
      :visible="showRecharge"
      @close="showRecharge = false"
    ></ticketsRecharge>
  </div>
</template>

<script>
import login from "@/components/login.vue";
import {
  getcountrys,
  getuserinfo,
  getcouponcout,
  getorgauthinfo,
} from "@/api/common.js";
import { getbalance, queryusercoupon } from "@/api/personal.js";
import { getorgbalance } from "@/api/finance.js";
import { hasNoticeUnRead } from "@/api/system.js";
import recharge from "@/components/recharge.vue";
import { getSessionStorage, setSessionStorage, getToken } from "@/utils/auth";
import { EventBus } from "@/utils/eventBus.js";
import ticketsRecharge from "@/views/orderCenter/electron/components/recharge.vue";

export default {
  name: "Header",
  inject: ["pageReload"],
  components: {
    login,
    // login: () => import('@/components/login.vue'),
    recharge,
    ticketsRecharge,
  },
  data() {
    return {
      config: {
        dialogVisible: false,
      },
      list: [
        { name: "首页", path: "/" },
        {
          name: "签证办理",
          path: "/product",
          isShow: true,
          children: [
            {
              name: "签证办理-筛选",
              desc: "校务管理",
              path: "/product/detail",
              icon: "iconfont icon-Icon-zhaoshengyingxiao",
            },
          ],
        },

        { name: "保险商城", path: "/caseb" },
        // { name: "签证知识", path: "/case" },
        {
          name: "关于我们",
          path: "/about",
          isShow: false,
          children: [
            // { name: "公司简介", path: "/about/introduction" },
            // { name: "新闻动态", path: "/about/journalism" },
            // {name: "产品动态", path: "/about/productTrends"},
          ],
        },
        {
          name: "我的订单",
          path: "/order",
          isShow: false,
        },
        {
          name: "个人中心",
          path: "/personal",
          isShow: true,
        },
        // {
        //     name: "平台设置", path: "/settings",
        //     isShow: false,
        // },
        // {
        //     name: "订单中心", path: "/orderCenter",
        //     isShow: false,
        // },
      ],
      xfList: [
        { title: "电话咨询", icon1: "icon-icon-dianhuazixun", isv: false },
        { title: "申请使用", icon1: "icon-Icon-shenqingshiyong", isv: false },
        { title: "扫码关注", icon1: "icon-Icon-saomaguanzhu", isv: false },
        { title: "返回顶部", icon1: "icon-zhiding", isv: false },
      ],
      continueActive: 0,
      hoverNum: 0,
      num: 0,
      children: -1,
      children2: -1, //产品选择
      scrollTop: 0,
      timer: null,
      countrys: {},
      userInfo: {},
      isLogin: false,
      balance: "",
      showNum: 99,
      usercoupon: 0,
      register: true,
      isVisible: false,
      hasNoticeUnRead: false,
      // childrenOpen: false, //关于我们二级
      orgAuthInfo: {}, //机构登录权限信息
      isOrg: false,
      orgList: [],
      orgBalance: 0,
      showRecharge: false,
    };
  },
  // item.path == '/product' | item.path == '/personal'
  computed: {
    // item.path == '/product' | item.path == '/personal'
    showIcon() {
      return function (path) {
        if (path == "/product") {
          return true;
        } else if (path == "/personal") {
          return true;
        } else {
          return false;
        }
      };
    },
  },
  created() {
    let token = getToken();
    EventBus.$on("refreshOrgBalance", () => {
      this.getOrgBalance();
    });
    this.isOrg = token ? true : false;
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    let vm = this;
    if (
      (!this.isOrg && this.$store.state.user.token) ||
      (this.isOrg && this.$store.state.user.orgToken)
    ) {
      vm.isLogin = true;
      vm.getbalanceFn();
      // vm.queryusercouponFn();
      vm.getcouponcoutFn();
      //机构端登录查询菜单权限
      if (this.isOrg) {
        vm.getOrgAuthInfo();
        vm.getOrgBalance();
      } else {
        vm.getuserinfoFn();
      }
      vm.getNoticeStatus();
    } else {
      vm.isLogin = false;
      vm.showNum = 4;
    }
    vm.getcountrysFn();
    vm.$forceUpdate();
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val, oldVal) {
        this.orgAuthInfo = getSessionStorage("orgAuthInfo");
        if (this.orgAuthInfo && this.orgAuthInfo.isOrg) {
          this.isOrg = true;
        }
        switch (val.name) {
          case "home":
            this.num = 0;
            break;
          case "product":
          case "malaysia":
            this.num = 1;
            break;
          case "caseb":
            this.num = 2;
            break;
          case "casebDetail":
            this.num = 2;
            break;
          case "about":
            this.num = 3;
            break;
          case "about-introduction":
            this.num = 3;
            this.children = 0;
            break;
          case "about-journalism":
            this.num = 4;
            this.children = 1;
            break;
          // case "about-productTrends":
          //     this.num = 3;
          //     this.children = 2;
          //     break
          case "product-crm":
            this.num = 1;
            this.children2 = 0;
            break;
          case "product-match":
            this.num = 1;
            this.children2 = 1;
            break;
          case "productDetail":
            this.num = 1;
            break;
          case "productDetailId":
            this.num = 1;
            break;
          case "order":
          case "orderDetail":
          case "electron":
          case "orderInfoDetails":
          case "information":
            this.num = 4;
            break;
          // case 'orderDetail':
          //     this.num = 4;
          //     break;
          case "settings":
            //this.num = this.isOrg ? 0 : 6;
            this.num = this.isOrg ? 0 : 0;
            break;
          case "orderCenter":
            this.num = this.isOrg ? 1 : 7;
            break;
          case "informations":
            this.num = this.isOrg ? 1 : 7;
            break;
          case "orderCenterElectron":
            this.num = this.isOrg ? 1 : 7;
            break;
          case "personal":
            this.num = 5;
            break;
          default:
            this.num = 99;
            break;
        }
      },
    },

    isLogin(val) {
      if (val) {
        this.showNum = 99;
      } else {
        this.showNum = 5;
      }
    },
  },
  methods: {
    showChildren(index, item) {
      this.list.forEach((elem) => {
        elem.isShow = false;
      });

      this.hoverNum = index;
      item.isShow = true;
    },
    outChildren(index, item) {
      item.isShow = false;
    },
    getcouponcoutFn() {
      getcouponcout({}).then((res) => {
        this.usercoupon = res.data;
      });
    },
    getbalanceFn() {
      getbalance({}).then((res) => {
        this.balance = res.data;
        this.$store.commit("SET_BALANCE", res.data);
      });
    },
    getuserinfoFn() {
      getuserinfo({}).then((res) => {
        console.log(res);
        this.$store.commit("SET_USERINFO", res.data);
        this.userInfo = res.data;
        if (this.userInfo.userType != 3) {
          this.list = this.list.filter((item) => {
            if (item.name == "订单中心" || item.name == "平台设置") {
              return false;
            } else {
              return true;
            }
          });
        }
      });
    },
    //获取机构端点券余额
    getOrgBalance() {
      getorgbalance().then((res) => {
        if (res.code == 1) {
          this.orgBalance = res.data;
          this.$store.commit("SET_ORGBALANCE", res.data);
        }
      });
    },
    //获取机构端的菜单信息
    getOrgAuthInfo() {
      getorgauthinfo().then((res) => {
        this.orgList = res.data.menus;
        this.$set(res.data, "isOrg", true);
        setSessionStorage("orgAuthInfo", res.data);
        this.orgAuthInfo = res.data;
      });
    },

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 15);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;

        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    toHome() {
      this.$router.push("/settings");
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      that.scrollTop = scrollTop;
    },
    toLogin() {
      window.open("https://b.sport-syy.com/", "_blank");
    },
    handleRefresh() {
      this.config.dialogVisible = false;
    },
    queryusercouponFn() {
      let vm = this;
      queryusercoupon({}).then((res) => {
        this.usercoupon = res.data.usableCount;
      });
    },
    getcountrysFn() {
      getcountrys({}).then((res) => {
        this.countrys = res;
      });
    },
    pathRoute(item) {
      this.$router.push({
        name: "productDetail",
        params: { id: item.id + "_" + item.zhName },
      });
    },

    openTz() {
      this.$router.push({ name: "system" });
    },

    openPersonal(e) {
      this.$router.push({ name: "personal", query: { active: e } });
    },

    outUser() {
      this.$confirm("是否要退出", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.$store.dispatch("user/logout").then((res) => {
            if (this.isOrg) {
              this.$router.push({ name: "orgLogin" });
            } else {
              this.pageReload();
            }
          });
        })
        .catch((err) => {});
    },

    getNoticeStatus() {
      let _this = this;
      hasNoticeUnRead({}).then((res) => {
        if (res.data === true) {
          _this.hasNoticeUnRead = true;
        } else {
          _this.hasNoticeUnRead = false;
        }
      });
    },
    //跳转到平台设置-->财务管理
    openFinance() {
      EventBus.$emit("checkFinance");
    },
  },
};
</script>

<style lang="scss" scoped>
.header-login {
  .el-button {
    font-size: 14px;
  }
}

.user-info {
  position: absolute;
  background: #fff;
  width: 278px;
  padding: 16px;
  left: -100px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 5px;

  .user-info-btn {
    width: 244px;
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    background: #f8912c;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
  }

  .user-info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 0;

    .user-info-item-left {
      font-size: 16px;
      font-weight: bold;
    }

    .user-info-item-right {
      color: #f8912c;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .user-info-left {
    display: flex;
    align-items: center;
  }

  .user-info-right {
    width: 60px;
    height: 20px;
    line-height: 20px;
    border-radius: 15px;
    border: 1px solid #e4e4e4;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    color: #999999;

    &:hover {
      background: #0064ff;
      border: 1px solid #0064ff;
      color: #fff;
    }
  }
}

.children-list {
  // margin-top: 80px;
  width: 586px;
  // height: 262px;
  box-shadow: 0px 8px 32px 1px rgba(0, 0, 0, 0.06);
  background: #ffffff;
  border-radius: 0px 0px 4px 4px;
  z-index: 999999999999;
  display: flex;
  position: absolute;
  top: 76px;
  margin-left: -120px;

  .children-left {
    width: 98px;
    flex-direction: column;
    background: #333333;
    cursor: pointer;

    .children-left-item {
      height: 44px;
      line-height: 44px;
      color: #fff;
      font-size: 14px;

      &.continue-active {
        background: #1890ff;
        color: #fff;
      }
    }
  }

  .children-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex: 1;
    padding: 23px 20px;
    box-sizing: border-box;

    .children-right-item {
      width: 98px;
      height: 65px;
      line-height: 65px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.45);
      margin-bottom: 10px;
      margin-right: 14px;
      cursor: pointer;
      // font-size: 14px;
      color: #fff;
      background-size: 100% 100%;
      background-position: center;
      text-align: center;
      white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
      font-size: clamp(10px, 0.9rem, 14px);

      &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in;
      }
    }
  }

  .children-item {
    height: 50px;
    line-height: 50px;

    a {
      height: 50px;
      line-height: 50px;
    }

    &:hover {
      a {
        color: #f68634 !important;
      }
    }

    &.children-active {
      background: #f68634;

      a {
        color: #ffffff !important;
      }
    }
  }
}

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 76px;
  // box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.03);
  // border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  background: #ffffff;

  .header-content {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header-logo {
    cursor: pointer;

    .logo-img {
      width: 57px;
      height: 42px;
    }
  }

  .header-list {
    display: flex;
    align-items: center;

    .header-right-icon {
      width: 19px;
      height: 19px;
      position: relative;

      .header-right-t {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #e41313;
      }
    }

    .header-icon {
      // margin-left: 335px;
      display: flex;
      text-align: right;

      &.header-icon-user {
        margin-left: 395px;
        display: flex;
        align-items: center;

        .header-icon-label {
          cursor: pointer;
          white-space: nowrap;
        }

        span {
          font-size: 16px;
          color: #0064ff;
        }

        .el-icon-arrow {
          margin-left: 5px;
        }
      }

      .header-btn {
        width: 90px;
        height: 35px;
        border: 1px solid #0064ff;
        text-align: center;
        line-height: 35px;
        color: #0064ff;
        border-radius: 5px;
        cursor: pointer;

        &.header-btn-bg {
          background: #0064ff;
          color: #fff;
          margin-left: 15px;
        }
      }

      span {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .header-item {
      // width: 132px;
      height: 76px;
      text-align: center;
      // cursor: pointer;
      line-height: 76px;
      position: relative;
      margin-right: 38px;
      text-align: center;

      a {
        width: 100%;
        height: 100%;
        display: block;
        color: #333333;
        position: relative;
        font-size: 16px;
        word-break: keep-all;
        // .is-color {
        //     width: 18px;
        //     height: 3px;
        //     background: #0064FF;
        //     border-radius: 2px;
        //     position: absolute;
        //     bottom: 20px;
        //     left: 0px;
        // }

        &:hover {
          color: #3888e9;
        }

        &.color {
          color: #3888e9;

          &::after {
            content: "";
            position: absolute;
            width: 50px;
            height: 5px;
            background: #3888e9;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 8px;
          }
        }

        .el-icon-caret-bottom {
          position: absolute;
          right: -20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .org {
   // width: 800px;
    width: calc(100% - 400px);
  }
}

.right-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 199px;
  height: 76px;

  .right-box-user {
    display: flex;
    align-items: center;
    margin-right: 30px;
    position: relative;
    height: 76px;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }

    .org-name {
      cursor: pointer;
      margin: 0 10px;
      white-space: nowrap;
    }

    &:hover .org-info {
      display: block;
    }
  }
}

.org-info {
  left: -70px;
  top: 75px;
  display: none;
}
</style>
