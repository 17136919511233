import request from '@/utils/request';

export const exchangecoupon = (params) => {
  return request('/app_api/v1/user/exchangecoupon', {
    method: 'post',
    data: { ...params },//post请求使用post

  })
}


//    机构端查询订单列表
export function queryorderorg(params) {
  return request('/org_api/v1/orgfinance/queryorderorg', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}


//    商户列表
export function merchatlist(params) {
  return request('/org_api/v1/orgfinance/merchatlist', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

//    指定订单下的申请人
export function queryorderapplicantorg(params) {
  return request('/org_api/v1/orgfinance/queryorderapplicantorg', {
    method: 'get',
    params: { ...params }//get请求使用params,直接拼接在请求url上
  })
}

// 查询订单自动化提交价格与配置
export function getsystemvisa(params) {
  return request('/org_api/v1/orgfinance/getsystemvisa', {
    method: 'get',
    params: { ...params }
  })
}

// 获取点券充值套餐
export function getorgpackages(params) {
  return request('/org_api/v1/orgfinance/getorgpackages', {
    method: 'get',
    params: { ...params }
  })
}

// 购买充值订单
export function createrechargeorder(params) {
  return request('/org_api/v1/orgfinance/createrechargeorder', {
    method: 'post',
    data: { ...params }
  })
}

// 查询当前机构点券余额
export function getorgbalance(params) {
  return request('/org_api/v1/orgfinance/getorgbalance', {
    method: 'get',
    params: { ...params }
  })
}


// 查询点券支付订单
export function getrechargeorder(params) {
  return request('/org_api/v1/orgfinance/getrechargeorder', {
    method: 'get',
    params: { ...params }
  })
}
// 修改订单备注
export function orderremarks(params) {
  return request('/org_api/v1/orgfinance/orderremarks', {
    method: 'post',
    data: { ...params }
  })
}

// 获取个人信息以及额外信息--马来西亚
export function getpassengerinfo(params) {
  return request('/country_api/v1/malaysia/getpassengerinfo', {
    method: 'get',
    params: { ...params }
  })
}

// 保存信息及拓展信息
export function savepassengerinfo(params) {
  return request('/country_api/v1/malaysia/savepassengerinfo', {
    method: 'post',
    data: { ...params }
  })
}
//图片转换Pdf并上传
export function uploadimgtopdf(params) {
  return request('/country_api/v1/publicapi/uploadimgtopdf', {
    method: 'post',
    data: { ...params }
  })
}
//  将头像的背景色改成白色
export function setwhitebg(params) {
  return request('/country_api/v1/publicapi/uploadheadfileurltowhitebg', {
    method: 'get',
    params: { ...params }
  })
}

//随机生成机票行程单
export function randomplaneticket(params) {
  return request('/org_api/v1/orgfinance/randomplaneticket', {
    method: 'post',
    data: { ...params }
  })
}
//随机生成酒店行程单
export function randomhotel(params) {
  return request('/org_api/v1/orgfinance/randomhotel', {
    method: 'post',
    data: { ...params }
  })
}
// 复制他人机票行程单
export function getplanetickets(params) {
  return request('/org_api/v1/orgfinance/getplanetickets', {
    method: 'get',
    params: { ...params }
  })
}
// 复制他人酒店行程单
export function gethotels(params) {
  return request('/org_api/v1/orgfinance/gethotels', {
    method: 'get',
    params: { ...params }
  })
}

// 删除申请人
export function delorderapplicant(params) {
  return request('/app_api/v1/visaorder/delorderapplicant', {
    method: 'get',
    params: { ...params }
  })
}
// 查询最近使用酒店地址
export function gethoteladdresss(params) {
  return request('/org_api/v1/orgfinance/gethoteladdresss', {
    method: 'get',
    params: { ...params }
  })
}
// 获取可使用自定义邮箱
export function getorgemaillist(params) {
  return request('/org_api/v1/orgfinance/getorgemaillist', {
    method: 'get',
    params: { ...params }
  })
}

// 保存马来西亚信息
export function savemalaysia(params) {
  return request('/org_api/v1/orgfinance/savemalaysia', {
    method: 'get',
    params: { ...params }
  })
}

// 整个订单代理提交 提交到大使馆
export function agentsubmit(params) {
  return request('/org_api/v1/orgfinance/agentsubmit', {
    method: 'post',
    data: { ...params }
  })
}
// 订单所有申请人状态查询
export function orderapplicantstatus(params) {
  return request('/org_api/v1/orgfinance/orderapplicantstatus', {
    method: 'get',
    params: { ...params }
  })
}

// 代理订单扩展信息查询
export function orderexinfo(params) {
  return request('/org_api/v1/orgfinance/orderexinfo', {
    method: 'get',
    params: { ...params }
  })
}
// 代理订单已付款
export function orderpaid(params) {
  return request('/org_api/v1/orgfinance/orderpaid', {
    method: 'get',
    params: { ...params }
  })
}