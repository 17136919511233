<template>
    <div class="service">
        <div class="box-input">
            <div class="box-input-left">

                <el-input v-model="formInfo.area" style="width: 194px;margin-right: 12px;" placeholder="请输入服务区域"></el-input>
                <el-input v-model="formInfo.name" style="width: 194px;margin-right: 12px;"
                    placeholder="请输入申请人姓名"></el-input>
                <el-input v-model="formInfo.phone" style="width: 194px;margin-right: 12px;"
                    placeholder="请输入联系电话"></el-input>
            </div>

            <div class="box-input-btn">
                <el-button class="default" @click="querycustomerserviceFn">搜索</el-button>
                <el-button class="box-input-btn-cz" @click="resetFn">重置</el-button>
                <el-button class="box-input-btn-cz" @click="addService">添加</el-button>
            </div>

        </div>

        <div class="box-table">
            <el-table :data="list"
                :header-cell-style="{ backgroundColor: '#F2F4F7', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '12px' }"
                style="width: 100%" :height="$store.state.product.screen ? '820px' : '470px'" v-loading="formInfo.loading"
                :cell-style="{ 'text-align': 'center' }">


                <el-table-column prop="name" label="姓名">
                </el-table-column>
                <el-table-column prop="phone" label="联系方式">
                </el-table-column>
                <el-table-column prop="wechatImg" label="企业微信二维码">
                    <template slot-scope="scope">
                        <el-image style="width: 50px;height: 50px;" v-if="scope.row.wechatImg"
                            :src="scope.row.wechatImg"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="邮箱">
                </el-table-column>
                <el-table-column prop="area" label="服务区域">
                </el-table-column>
                <el-table-column prop="introduction" label="简介">
                </el-table-column>

                <el-table-column label="操作" header-align="center">
                    <template slot-scope="scope">
                        <div class="edit-wrap">
                            <div class="edit-item">
                                <span class="lan" @click="editPackage(scope.row)">编辑</span>
                                <span class="del" @click="delProduct(scope.row.id)">删除</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="table-pagination">

            <el-pagination @current-change="memberCurrentChange" :current-page="formInfo.pageIndex" :page-size="10"
                layout="total, prev, pager, next, jumper" :total="formInfo.total">
            </el-pagination>
        </div>
        <!-- 添加客服 -->
        <el-dialog :visible.sync="dialogService" width="445px" class="quan-dialog-service-class"
            :close-on-click-modal="false">
            <div class="dialog-quan-nei-wrap">
                <div class="dialog-member-title">
                    <span class="dialog-member-name">新增客服</span>
                    <div class="dialog-close" @click="resetForm('ruleForm')">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="dialog-form-wrap">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
                        class="packge-nei-ruleForm">
                        <el-form-item label="姓名:" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式:" prop="phone">
                            <el-input v-model="ruleForm.phone" type="Number" placeholder="请输入联系方式"></el-input>
                        </el-form-item>
                        <el-form-item label="企业二维码:" prop="wechatImg" class="wechat-img">
                            <upload v-model="ruleForm.wechatImg" @uploading="uploadHandle" accept="img" :auto-upload="true"
                                :image-url="ruleForm.wechatImg" :drag="'drag'" thisType="custom" 
                                @input="toImg($event, 'wechatImg')" :customSty="{ width: '200px', height: '200px' }">
                            </upload>
                        </el-form-item>
                        <el-form-item label="邮箱:" prop="email">
                            <el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="服务区域:" prop="area">
                            <el-input v-model="ruleForm.area" placeholder="请输入服务区域"></el-input>
                        </el-form-item>
                        <el-form-item label="简介:" prop="introduction">
                            <el-input v-model="ruleForm.introduction" placeholder="请输入简介"></el-input>
                        </el-form-item>
                        <el-form-item label="是否默认:" prop="isDefault">
                            <el-switch v-model="ruleForm.isDefault" active-color="#3888E9" inactive-color="#CCCCCC">
                            </el-switch>
                        </el-form-item>

                    </el-form>

                    <div class="packge-btn-add">

                        <el-button class="pageck-btn" @click="resetForm('ruleForm')">取消</el-button>
                        <el-button class="pageck-btn default" type="primary" @click="submitForm('ruleForm')">确认</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { querycustomerservice, addcustomerservice, updatecustomerservice, deletecustomerservice } from '@/api/settings.js'
import upload from '@/components/upload.vue'
export default {
    components: {
        upload
    },
    data() {
        var validatorAccount = (rule, value, callback) => {
            let reg = /^[1][1,2,3,4,5,7,8,9][0-9]{9}$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入手机号'));
            }


            if (!reg.test(value)) {
                return callback(new Error('手机号格式不正确'));
            } else {

                callback();
            }


        };

        var validatorEmail = (rule, value, callback) => {
            let reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;		//	手机

            if (!value) {
                // message: '请输入手机号',
                return callback(new Error('请输入邮箱地址'));
            }


            if (!reg.test(value)) {
                return callback(new Error('请输入正确的邮箱地址格式'));
            } else {

                callback();
            }


        };
        return {
            formInfo: {
                name: '',
                phone: '',
                area: '',
                total: 0,
                loading: false,
                pageIndex: 0,
            },
            ruleForm: {
                name: '',
                phone: '',
                wechatImg: '',
                email: '',
                area: '',
                introduction: '',
                isDefault: false,
                id: '',
            },
            rules: {
                name: [
                    { required: true, message: '请输入联系人', trigger: ['click', 'change', 'blur'] },
                ],
                area: [
                    { required: true, message: '请输入服务区域', trigger: ['click', 'change', 'blur'] },
                ],
                phone: [
                    { validator: validatorAccount, required: true, trigger: ['click', 'change', 'blur'] }
                ],
                email: [
                    { validator: validatorEmail, required: true, trigger: ['click', 'change', 'blur'] },
                ],
                introduction: [
                    { required: true, message: '请输入简介', trigger: ['click', 'change', 'blur'] },
                ],
                wechatImg: [
                    { required: true, message: '请上传企业二维码', trigger: ['click', 'change', 'blur'] },
                ],
            },
            dialogService: false,
            list: []
        }
    },
    mounted() {
        this.querycustomerserviceFn();
    },
    methods: {
        toImg(e, type) {
            this.ruleForm[type] = e
        },
        uploadHandle(val) {
            var vm = this;
            console.log(val);
            vm.uploading = val;
        },
        querycustomerserviceFn() {
            let vm = this;
            vm.formInfo.loading = true;
            querycustomerservice({
                Name: vm.formInfo.name,
                Phone: vm.formInfo.phone,
                Area: vm.formInfo.area,
                token: vm.$store.state.token,
                'Page.Size': 10,
                'Page.Index': vm.formInfo.pageIndex,
            }).then(res => {
                console.log(res);
                vm.formInfo.loading = false;
                vm.list = res.data.rows;
                vm.formInfo.total = res.data.total
            })
        },
        addService() {
            this.dialogService = true;

            this.ruleForm = {
                name: '',
                phone: '',
                wechatImg: '',
                email: '',
                area: '',
                introduction: '',
                isDefault: false,
                id: '',
            }
        },
        resetForm(formName) {
            this.dialogService = false;

            this.ruleForm = {
                name: '',
                phone: '',
                wechatImg: '',
                email: '',
                area: '',
                introduction: '',
                isDefault: false,
                id: '',
            }

            if (formName) {

                this.$refs[formName].resetFields();
            }
        },
        editPackage(item) {
            this.ruleForm = JSON.parse(JSON.stringify(item))
            this.dialogService = true;
        },

        resetFn() {
            this.formInfo = {
                Name: '',
                Phone: '',
                Area: '',
                total: 0,
                loading: false,
                pageIndex: 0,
            }
            this.querycustomerserviceFn();
        },
        delProduct(id) {

            this.$confirm(`是否要删除该客服`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deletecustomerservice({
                    id: id,

                }).then(res => {
                    console.log(res);

                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    this.querycustomerserviceFn();
                }).catch(res => {

                })
            }).catch(res => {

            })
        },
        memberCurrentChange(val) {
            this.formInfo.pageIndex = val;

            this.querycustomerserviceFn();
        },
        submitForm(formName) {
            let vm = this;

            this.$refs[formName].validate((valid) => {
                if (valid) {

                    if (vm.ruleForm.id) {
                        updatecustomerservice({
                            token: vm.$store.state.token,
                            ...vm.ruleForm
                        }).then(res => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            });

                            this.dialogService = false;
                            this.querycustomerserviceFn();
                        })
                    } else {
                        addcustomerservice({
                            token: vm.$store.state.token,
                            ...vm.ruleForm
                        }).then(res => {
                            this.$message({
                                message: '添加成功',
                                type: 'success'
                            });

                            this.dialogService = false;
                            this.querycustomerserviceFn();

                        })
                    }


                } else {


                }

            })

        }
    }
}
</script>
<style lang="scss" >
.quan-dialog-service-class {
    background: none !important;
    border-radius: 12px;

    .el-dialog {
        background: none;
    }

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        width: 445px;

        // height: 333px;
        background: linear-gradient(180deg, #E7ECFB 0%, #F6F8FE 24%, #FFFFFF 100%);
        border-radius: 12px;
        box-sizing: border-box;
        overflow: hidden;
    }


    .packge-nei-ruleForm {
        margin-top: 20px;
        box-sizing: border-box;


        .el-form-item {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 20px;
        }

        .packge-select {
            width: 135px;
            text-align: left;
        }

        .el-form-item__label {
            width: 50px !important;
            white-space: nowrap;
            height: 30px;
            text-align: left;
        }

        .el-form-item__content {
            // margin-left: 80px !important;
            // margin-right: 40px;
        }

        .el-form-item__content {
            margin: 0 auto;



            // .el-button {
            //     // margin-top: 20px;
            //     width: 105px;
            // }
        }
    }
}
</style>

<style lang="scss" scoped>
.service {

    .box-input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;

        .box-input-btn {
            text-align: right;

            .box-input-btn-cz {
                background: #EAF3FF;
                color: #3888E9;
                border: none;
            }

            .box-input-btn-add {
                background: #D8F5E4;
                color: #54D8A5;
                border: none;
            }
        }
    }

}

.packge-btn-add {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .pageck-btn {
        width: 145px;
        margin: 0 16px;
    }
}

.dialog-quan-nei-wrap {
    .dialog-member-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -10px;

        .dialog-member-name {
            font-size: 18px;
            font-weight: bold;

        }

        .dialog-close {
            width: 25px;
            height: 25px;
            background: #E0E0E0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .el-icon-close {
                color: #FFF;
                font-size: 16px;
            }
        }
    }
}

.table-pagination {
    text-align: center;
    margin-top: 16px;
}

.edit-wrap {

    .edit-item {

        // padding-left: 20px;
        span {
            cursor: pointer;
            display: inline-block;
            margin: 6px;

            &.lan {
                color: #3888E9;
            }

            &.copy {
                color: #9EA09E;
            }

            &.del {
                color: #E41313;
            }
        }
    }
}

.wechat-img {
    ::v-deep .el-form-item__content {
        width: 200px;
        height: 200px;
    }
}
</style>