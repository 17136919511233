import Cookies from 'js-cookie'

const NameKey = 'vue_kuaimavisa_'
const TokenKey = NameKey + 'token'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }


export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getCookies(key) {
  let value = Cookies.get(NameKey + key)
  if(typeof value == 'String') {
    return value
  } else {
    return JSON.parse(value)
  }  
}

export function setCookies(key, value) {
  if(typeof value == 'String') {
    Cookies.set(NameKey + key, value)
  } else {
    Cookies.set(NameKey + key, JSON.stringify(value))
  }
}

export function removeCookies(key) {
  return Cookies.remove(NameKey + key)
}

export function getLocalStorage(key) {
  let value = localStorage.getItem(NameKey + key)
  if(typeof value == 'String') {
    return value
  } else {
    return JSON.parse(value)
  }  
}

export function setLocalStorage(key, value) {
  if(typeof value == 'String') {
    localStorage.setItem(NameKey + key, value)
  } else {
    localStorage.setItem(NameKey + key, JSON.stringify(value))
  }
}

export function removeLocalStorage(key) {
  return localStorage.removeItem(NameKey + key)
}

export function setSessionStorage(key, value) {
  if(typeof value == 'String') {
    sessionStorage.setItem(NameKey + key, value)
  } else {
    sessionStorage.setItem(NameKey + key, JSON.stringify(value))
  }
}
export function getSessionStorage(key) {
  let value = sessionStorage.getItem(NameKey + key)
  if(typeof value == 'String') {
    return value
  } else {
    return JSON.parse(value)
  }  
}
export function removeSessionStorage(key) {
  return sessionStorage.removeItem(NameKey + key)
}