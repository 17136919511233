<template>
  <div v-show="curValue">
    <el-dialog title="批量下载" :close-on-click-modal="false" center :visible.sync="curValue" width="761px"
      class="dialog-wrap-dowm-nom">
      <div class="dialog-member-title">
        <span class="dialog-member-name">批量下载</span>
        <div class="dialog-close" @click="curValue = false">
          <i class="el-icon-close"></i>
        </div>
      </div>

      <div class="dialog-nom-list">
        <div class="dialog-nom-list-left">
          <el-table :data="downData"
            :header-cell-style="{ backgroundColor: 'none', color: '#333', fontWeight: 'bold', textAlign: 'center', fontSize: '14px' }"
            height="470px" style="width: 390px;" :cell-style="{ 'text-align': 'center' }" :row-key="getRowKeys"
            @selection-change="selectUser">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column prop="applicantName" label="姓名">
            </el-table-column>
            <el-table-column prop="statusName" label="申请状态">
            </el-table-column>
          </el-table>
          <div class="table-pagination">
            <el-pagination @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize"
              layout="total, prev, pager, next, jumper" :pager-count="5" small :total="total">
            </el-pagination>
          </div>
        </div>
        <div class="dialog-nom-list-right">
          <div class="dialog-nom-list-right-wrap">

            <div class="dialog-nom-list-right-title">
              <p>选择下载内容</p>
              <p>共选择<span>{{ userListAllTable.length }}本</span>签证</p>
            </div>
            <div class="dialog-nom-list-right-yello">
              <i class="el-icon-warning"></i>
              <span style="margin-left: 5px;">若选择的申请人无相关资料，下载内容为空</span>
            </div>
            <div class="dialog-nom-select-up">
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="checkedItems" @change="handleCheckedItemChange">
                <el-checkbox v-for="item in options" :label="item" :key="item">{{ item }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </div>

      <div class="packge-btn-add" style="margin-top: 15px;">
        <el-button class="pageck-btn" @click="curValue = false">取消</el-button>
        <el-button class="pageck-btn default" type="primary" @click="submitForm('ruleForm')">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryorderapplicants, downloadapplicantsdata } from '@/api/order.js'
export default {
  data() {
    return {
      curValue: false,
      downData: [],
      userListAllTable: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      isIndeterminate: false,
      checkAll: false,
      checkedItems: [],
      options: ['收据页', '签证文件', '拒签信'],
    }
  },
  props: {
    value: Boolean,
    orderId: {
      type: [String, Number],
    }
  },
  created() {
    if (this.value) {
      this.curValue = true;
    };
  },
  watch: {
    value(val) {
      this.curValue = val;
      if (val) {
        this.getQueryorderapplicants()
        this.checkedItems = []
        this.checkAll = false
      }
    },
    curValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    getRowKeys(val) {
      return val.id || ''
    },
    selectUser(e) {
      this.userListAllTable = e;
    },
    handleCurrentChange(e) {
      this.pageIndex = e;
      this.queryorderapplicantsFn();
    },
    handleCheckAllChange(val) {
      this.checkedItems = val ? this.options : [];
      this.isIndeterminate = false;
    },
    handleCheckedItemChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length;
    },
    getQueryorderapplicants() {
      queryorderapplicants({
        OrderId: this.orderId,
        'Page.Size': this.pageSize,
        'Page.Index': this.pageIndex,
      }).then(res => {
        this.downData = res.data.rows;
        this.total = res.data.total;
      })
    },
    submitForm() {
      if (this.checkedItems.length == 0) {
        this.$message({
          message: '未选择下载内容！',
          type: 'warning'
        });
        return;
      }
      if (this.userListAllTable.length == 0) {
        this.$message({
          message: '未选择申请人！',
          type: 'warning'
        });
        return;
      }

      let obj = {
        visaPage: false,
        rejectLetter: false,
        bill: false,
        orderApplicantIds: []
      }

      this.checkedItems.forEach(item => {
        if (item == '收据页') {
          obj.bill = true;
        }

        if (item == '签证文件') {
          obj.visaPage = true;
        }

        if (item == '拒签信') {
          obj.rejectLetter = true;
        }
      })


      this.userListAllTable.forEach(item => {
        obj.orderApplicantIds.push(item.id);
      })

      downloadapplicantsdata({
        ...obj
      }).then(res => {
        if (res.data == null || res.data == "") {
          this.$message({
            message: '无可下载资料',
            type: 'warning'
          });
        } else {
          this.curValue = false;
          window.open(res.data)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-nom-list {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .dialog-nom-list-left {
    width: 390px;
  }

  .dialog-nom-list-right {

    .dialog-nom-list-right-wrap {


      width: 295px;

      .dialog-nom-list-right-title {
        box-sizing: border-box;
        background: #FFF;
        border-radius: 4px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        justify-content: space-between;

        p {
          font-size: 12px;

          span {
            color: #0094E8;

          }
        }
      }

      .dialog-nom-list-right-yello {
        background: #FDF6EC;
        //  height: 28px;
        padding: 6px 12px;
        color: #F9C26F;
        box-sizing: border-box;
        font-size: 12px;
        margin-top: 8px;
      }

      .dialog-nom-select-up {
        margin-top: 15px;
      }
    }
  }

  ::v-deep .el-table {
    background: none;

    .el-table__row {
      background: none;

      .el-table__cell {
        padding: 6px 0;
      }
    }

    .el-table__header-wrapper {
      height: 36px;
      border-radius: 4px;

      .cell {
        line-height: 36px;
        height: 36px;
        margin-top: -10px;
      }
    }


  }
}

.packge-btn-add {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .pageck-btn {
    width: 145px;
    margin: 0 16px;
  }
}
</style>